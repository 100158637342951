import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import MESSAGES from 'constants/messages';
import { MODALS } from 'constants/modals';
import { GLOBALS } from 'constants/app';
import { COVERPAGE_ACTIVATION_TIMESTAMP } from 'constants/common';
import { MNL_INTELLIGENCE } from 'constants/intelligence';
import { fetchDowntimeInfoAPI, getBootstrapData } from 'modules/apis';
import * as userActions from 'modules/user/actions';
import * as brandingActions from 'modules/branding/actions';
import * as uiActions from 'modules/ui/actions';
import { buildBrandingSuccessActionPayload } from 'utils/branding';
import { roleHasPermission } from 'utils/roles';
import { fetchArchivedHandbooksSaga } from 'modules/handbooks/sagas';
import { getSponsorshipsSkus } from 'utils/skus';
import { hasStateInMenus } from 'utils/navigation';
import * as bootstrapActions from '../actions';

/**
 * Fetch the app bootstrap info
 *
 * @method fetchAppBootstrapSaga
 * @type   {Generator}
 * @param  {Object}              action The redux action
 */
export function* fetchAppBootstrapSaga(action) {
    try {
        const response = yield call(getBootstrapData);

        const user = get(response, 'data.applicationData.user', {});
        const company = get(response, 'data.applicationData.company', {});
        const locations = get(response, 'data.applicationData.locations', []);
        const permissions = get(response, 'data.applicationData.permissions', {});
        const partnerBranding = get(response, 'data.applicationData.partnerBranding', {});
        const contentTypes = get(response, 'data.applicationData.contentTypes', {});
        const partnerSettings = get(response, 'data.applicationData.partnerSetting', {});
        const menus = get(response, 'data.applicationData.menus', {});
        const states = get(response, 'data.applicationData.states', []);
        const informationBanner = get(response, 'data.applicationData.informationBanner', {});
        const isHybridSsoEnabled = get(response, 'data.applicationData.isHybridSsoEnabled', false);
        const isImpartnerEnabled = get(response, 'data.applicationData.isImpartnerEnabled', false);
        const isMultiYearSupportEnabled = get(
            response,
            'data.applicationData.isMultiYearSupportEnabled',
            false
        );
        const coverpageActivationTimestamp = get(
            response,
            'data.applicationData.coverpageActivationTimestamp',
            COVERPAGE_ACTIVATION_TIMESTAMP
        );

        const hasAccessToHandbookMoveTool = get(
            response,
            'data.applicationData.hasAccessToHandbookMoveTool',
            false
        );

        const partnerConfigurations = get(
            response,
            'data.applicationData.partnerConfigurations',
            null
        );
        const isAdministrationOptionEnabled = get(
            response,
            'data.applicationData.isAdministrationOptionEnabled',
            false
        );
        const companySkus = get(company, 'skus', []);
        const sponsorships = getSponsorshipsSkus(companySkus);

        const configuration = {
            configurationId: company.configurationId,
            skus: company.skus,
        };

        const isACAAdmin = hasStateInMenus(menus, 'aca-reporting');

        // Build branding as per the application
        const branding = buildBrandingSuccessActionPayload({
            data: {
                'partner-branding': { ...partnerBranding },
            },
        });

        yield put(userActions.fetchUser.success(user));
        yield put(userActions.fetchCompany.success(company));
        yield put(userActions.fetchConfiguration.success(configuration));
        yield put(userActions.fetchCompanyLocations.success(locations));
        yield put(userActions.fetchPermissions.success(permissions));
        yield put(brandingActions.fetchBranding.success(branding));
        yield put(userActions.fetchAllContentTypes.success(contentTypes));
        yield put(userActions.fetchPartnerSettings.success(partnerSettings));
        yield put(userActions.fetchInformationBanner.success(informationBanner));
        yield put(uiActions.fetchAppNav.success(menus));
        yield put(
            bootstrapActions.fetchAppBootstrap.success({
                ...GLOBALS.results,
                coverpageActivationTimestamp,
                states,
            })
        );
        yield put(userActions.updateSponsorshipList.success(sponsorships));
        yield put(uiActions.setFeatureFlag.success({ hasAccessToHandbookMoveTool }));
        yield put(uiActions.setFeatureFlag.success({ isHybridSsoEnabled }));
        yield put(uiActions.setFeatureFlag.success({ isAdministrationOptionEnabled }));
        yield put(uiActions.setFeatureFlag.success({ isAcaAdmin: isACAAdmin }));
        yield put(uiActions.setFeatureFlag.success({ isImpartnerEnabled }));
        yield put(uiActions.setFeatureFlag.success({ isMultiYearSupportEnabled }));

        const responseMhrHandbooks = yield call(fetchArchivedHandbooksSaga, {
            companyId: company.companyId,
        });

        yield put(
            userActions.updateArchivedHandbookStatus.success(responseMhrHandbooks.totalRecords > 0)
        );

        const termsAcceptedAt = get(user, 'termsAcceptedAt', null);
        const welcomeInsightStatus = get(
            user,
            'welcomeInsightStatus',
            MNL_INTELLIGENCE.WELCOME_INSIGHTS_NOT_DELIVERED_STATUS
        );
        const updateReminderStatus = get(company, 'updateReminderStatus', null);
        const isDelinquent = get(partnerSettings, 'isDelinquent', false);
        const message = get(partnerSettings, 'message', false);
        // Ref - https://trustmineral.atlassian.net/browse/GN-22465
        const isLocationEnabled = get(partnerSettings, 'isLocationEnabled', false);
        // 3 modals on successful login show up in below order: EULA, Deliquent, Company data popup
        if (!isLocationEnabled && updateReminderStatus === 1) {
            yield put(
                uiActions.openModal(MODALS.COMPANY_DETAILS_UPDATE_REMINDER, {
                    title: MESSAGES.MODAL.COMPANY_DETAILS_UPDATE_REMINDER.TITLE,
                    description: MESSAGES.MODAL.COMPANY_DETAILS_UPDATE_REMINDER.DESCRIPTION,
                })
            );
        }
        if (
            isDelinquent &&
            roleHasPermission({ permissions }, 'system.partners.show-delinquent-alert')
        ) {
            yield put(
                uiActions.openModal(MODALS.CONFIRM, {
                    title: MESSAGES.ALERT.COMPANY.DELINQUENT_FRIENDLY_REMINDER,
                    description: message,
                })
            );
        }

        yield put(
            userActions.updateEULAStatus({
                isTermsAccepted: Boolean(termsAcceptedAt),
            })
        );
        yield put(
            userActions.updateWelcomeInsightsStatus({
                welcomeInsightStatus,
            })
        );
        if (partnerConfigurations) {
            yield put(userActions.updatePartnerConfigurations.success(partnerConfigurations));
        }
    } catch (error) {
        yield put(bootstrapActions.fetchAppBootstrap.error(error));
    }
}

/**
 * Fetch downtime info
 *
 * @method fetchDowntimeInfoSaga
 * @type   {Generator}
 * @param  {Object}              action The redux action
 * @return {Object|Error}
 */
export function* fetchDowntimeInfoSaga(action) {
    try {
        const response = yield call(fetchDowntimeInfoAPI);
        const maintenance = get(response, 'data', {});
        yield put(bootstrapActions.fetchDowntimeInfo.success(maintenance));
        return maintenance;
    } catch (error) {
        yield put(bootstrapActions.fetchDowntimeInfo.error(error));
        return error;
    }
}
