import { createActions } from 'utils/actions';

/**
 * Redux actions for updating page status.
 *
 * @type {Object}
 */
export const updatePageStatus = createActions('admin', 'UPDATE_PAGE_STATUS');

/**
 * Redux actions for fetching a user.
 *
 * @type {Object}
 */
export const fetchUser = createActions('admin', 'FETCH_USER', { asPromise: true });

/**
 * Toggle the user status to active or inactive.
 *
 * @type {Object}
 */
export const changeUserStatus = createActions('admin', 'CHANGE_USER_STATUS', { asPromise: true });

/**
 * Toggle the user status to active or inactive.
 *
 * @type {Object}
 */
export const updateUser = createActions('admin', 'UPDATE_USER', { asPromise: true });

/**
 * Create a user for a company.
 *
 * @type {Object}
 */
export const createUser = createActions('admin', 'CREATE_USER', { asPromise: true });

/**
 * Fetch a preview of the user email history.
 *
 * @type {Object}
 */
export const fetchUserEmailHistoryPreview = createActions(
    'admin',
    'FETCH_USER_EMAIL_HISTORY_PREVIEW',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for delete configuration
 *
 * @type {Object}
 */
export const deleteConfiguration = createActions('admin', 'DELETE_CONFIGURATION', {
    asPromise: true,
});

/**
 * Redux actions for fetching a configuration.
 *
 * @type {Object}
 */
export const fetchConfig = createActions('admin', 'FETCH_CONFIGURATION', { asPromise: true });

/**
 * Redux actions for fetching configurations
 *
 * @type {Object}
 */
export const fetchConfigurations = createActions('admin', 'FETCH_CONFIGURATIONS', {
    asPromise: true,
});

/**
 * Redux actions for fetching configuration types
 *
 * @type {Object}
 */
export const fetchConfigurationTypes = createActions('admin', 'FETCH_CONFIGURATION_TYPES', {
    asPromise: true,
});

/**
 * Redux actions for updating a configuration.
 *
 * @type {Object}
 */
export const updateConfig = createActions('admin', 'UPDATE_CONFIGURATION', { asPromise: true });

/**
 * Redux actions for creating a configuration.
 *
 * @type {Object}
 */
export const createConfig = createActions('admin', 'CREATE_CONFIGURATION', { asPromise: true });

/**
 * Fetch configuration from a company.
 *
 * @type {Object}
 */
export const fetchCompanyConfiguration = createActions('admin', 'FETCH_COMPANY_CONFIGURATION', {
    asPromise: true,
});

/**
 * Fetch all the partner configurations.
 *
 * @type {Object}
 */
export const fetchCompanyConfigurations = createActions('admin', 'FETCH_COMPANY_CONFIGURATIONS', {
    asPromise: true,
});

/**
 * Fetch company of type partner.
 *
 * @type {Object}
 */
export const fetchPartner = createActions('admin', 'FETCH_PARTNER', {
    asPromise: true,
});

/**
 * Fetch company of type partner.
 *
 * @type {Object}
 */
export const fetchCompany = createActions('admin', 'FETCH_COMPANY', {
    asPromise: true,
});

/**
 * Create company for any partner.
 *
 * @type {Object}
 */
export const createCompany = createActions('admin', 'CREATE_COMPANY', {
    asPromise: true,
});

/**
 * Update company.
 *
 * @type {Object}
 */
export const updateCompany = createActions('admin', 'UPDATE_COMPANY', {
    asPromise: true,
});

/**
 * Activate/Deactivate company
 *
 * @type {Object}
 */
export const changeCompanyStatus = createActions('admin', 'ACTIVATE_DEACTIVATE_COMPANY', {
    asPromise: true,
});

/**
 * Import Users
 *
 * @type {Object}
 */
export const importUsers = createActions('admin', 'IMPORT_USERS', {
    asPromise: true,
});

/**
 * Import Clients
 *
 * @type {Object}
 */
export const importClients = createActions('admin', 'IMPORT_CLIENTS', {
    asPromise: true,
});

/**
 * Import Locations
 *
 * @type {Object}
 */
export const importLocations = createActions('admin', 'IMPORT_LOCATIONS', {
    asPromise: true,
});

/**
 * Fetch headers by company
 *
 * @type {Object}
 */
export const fetchCompanyHeaders = createActions('admin', 'FETCH_COMPANY_HEADERS', {
    asPromise: true,
});

/**
 * Fetch Naics codes.
 *
 * @type {Object}
 */
export const fetchNaicsCodes = createActions('admin', 'FETCH_NAICS_CODES', {
    asPromise: true,
});

/**
 * Validate zip code.
 *
 * @type {Object}
 */
export const fetchZipCodeValidate = createActions('admin', 'FETCH_ZIP_CODE_VALIDATE', {
    asPromise: true,
});

/**
 * Fetch industry types.
 *
 * @type {Object}
 */
export const fetchIndustryTypes = createActions('admin', 'FETCH_INDUSTRY_TYPES', {
    asPromise: true,
});

/**
 * Fetch company email configuration.
 *
 * @type {Object}
 */
export const fetchWelcomeEmailSettings = createActions('admin', 'FETCH_WELCOME_EMAIL_SETTINGS', {
    asPromise: true,
});

/**
 * Update company email configuration.
 *
 * @type {Object}
 */
export const updateWelcomeEmailSettings = createActions('admin', 'UPDATE_WELCOME_EMAIL_SETTINGS', {
    asPromise: true,
});

/**
 * Fetch all locations by company.
 *
 * @type {Object}
 */
export const fetchAllLocationsForCompany = createActions(
    'admin',
    'FETCH_ALL_LOCATIONS_FOR_COMPANY',
    {
        asPromise: true,
    }
);

/**
 * Fetch all locations by company.
 *
 * @type {Object}
 */
export const fetchEmployeeCountsByState = createActions('admin', 'FETCH_EMPLOYEE_COUNTS_BY_STATE', {
    asPromise: true,
});

/**
 * Fetch all clients by partner.
 *
 * @type {Object}
 */
export const fetchPartnerClients = createActions('admin', 'FETCH_PARTNER_CLIENTS', {
    asPromise: true,
});

/**
 * Fetch all configurations from partner.
 *
 * @type {Object}
 */
export const fetchAllConfigurationsForPartner = createActions(
    'admin',
    'FETCH_ALL_CONFIGURATIONS_FOR_PARTNER',
    {
        asPromise: true,
    }
);

/**
 * Fetch PrismHR Configuration from partner.
 *
 * @type {Object}
 */
export const fetchPrismHRConfiguration = createActions('admin', 'FETCH_PRISM_HR_CONFIGURATION', {
    asPromise: true,
});

/**
 * Update PrismHR Configuration from partner.
 *
 * @type {Object}
 */
export const updatePrismHRConfiguration = createActions('admin', 'UPDATE_PRISM_HR_CONFIGURATION', {
    asPromise: true,
});

/**
 * Delete PrismHR Configuration from partner.
 *
 * @type {Object}
 */
export const deletePrismHRConfiguration = createActions('admin', 'DELETE_PRISM_HR_CONFIGURATION', {
    asPromise: true,
});

/**
 * Fetch PrismHR Integration Data.
 *
 * @type {Object}
 */
export const fetchPrismHRIntegrationData = createActions(
    'admin',
    'FETCH_PRISM_HR_INTEGRATION_DATA',
    {
        asPromise: true,
    }
);

/**
 * Reset sync status of PrismHR Integration Data.
 *
 * @type {Object}
 */
export const resetPrismHRSyncStatus = createActions('admin', 'RESET_PRISM_HR_SYNC_STATUS', {
    asPromise: true,
});

/**
 * Recreate subscription of PrismHR.
 *
 * @type {Object}
 */
export const recreatePrismHRSubscription = createActions(
    'admin',
    'RECREATE_PRISM_HR_SUBSCRIPTION',
    {
        asPromise: true,
    }
);

/**
 * Fetch Csv template to import clients.
 *
 * @type {Object}
 */
export const fetchCsvPartnerTemplate = createActions('admin', 'FETCH_CSV_PARTNER_TEMPLATE', {
    asPromise: true,
});

/**
 * Bulk import clients.
 *
 * @type {Object}
 */
export const validateBulkUpdateCompanyConfigurations = createActions(
    'admin',
    'VALIDATE_BULK_UPDATE_COMPANY_CONFIGURATIONS',
    {
        asPromise: true,
    }
);

/**
 * Fetch clients
 *
 * @type {Object}
 */
export const fetchClients = createActions('admin', 'FETCH_CLIENTS', {
    asPromise: true,
});

/**
 * Fetch users
 *
 * @type {Object}
 */
export const fetchUsers = createActions('admin', 'FETCH_USERS', {
    asPromise: true,
});

/**
 * Fetch partner licensing
 *
 * @type {Object}
 */
export const fetchPartnerLicensing = createActions('admin', 'FETCH_PARTNER_LICENSING', {
    asPromise: true,
});
/**
 * Fetch Partner SKUs
 *
 * @type {Object}
 */
export const fetchMasterConfigSkusForPartner = createActions('admin', 'FETCH_PARTNER_SKUS', {
    asPromise: true,
});

/**
 * Update partner licensing
 *
 * @type {Object}
 */
export const updatePartnerLicensing = createActions('admin', 'UPDATE_PARTNER_LICENSING', {
    asPromise: true,
});

/**
 * Fetch partners
 *
 * @type {Object}
 */
export const fetchPartners = createActions('admin', 'FETCH_PARTNERS', {
    asPromise: true,
});

/**
 * Delete Location
 *
 * @type {Object}
 */
export const deleteLocation = createActions('admin', 'DELETE_LOCATION', {
    asPromise: true,
});

/**
 * Fetch Location
 *
 * @type {Object}
 */
export const fetchLocation = createActions('admin', 'FETCH_LOCATION', {
    asPromise: true,
});

/**
 * Save Location
 *
 * @type {Object}
 */
export const saveLocation = createActions('admin', 'SAVE_LOCATION', {
    asPromise: true,
});

/**
 * Fetch Locations Csv template of import Locations.
 *
 * @type {Object}
 */
export const fetchLocationsCsvPartnerTemplate = createActions(
    'admin',
    'FETCH_LOCATIONS_CSV_PARTNER_TEMPLATE',
    {
        asPromise: true,
    }
);

/**
 * Fetch Locations instructions template of import Locations.
 *
 * @type {Object}
 */
export const fetchLocationsInstructionsPartnerTemplate = createActions(
    'admin',
    'FETCH_LOCATIONS_INSTRUCTIONS_PARTNER_TEMPLATE',
    {
        asPromise: true,
    }
);

/**
 * Fetch imported contact list
 *
 * @type {Object}
 */
export const fetchImportedContactsList = createActions('admin', 'FETCH_IMPORTED_CONTACTS_LIST', {
    asPromise: true,
});

/**
 * Generate authentication code
 *
 * @type {Object}
 */
export const generateAuthenticationCode = createActions('admin', 'GENERATE_AUTHENTICATION_CODE', {
    asPromise: true,
});

/**
 * Fetch sso mapped field
 *
 * @type {Object}
 */
export const fetchSsoMappedField = createActions('admin', 'FETCH_SSO_MAPPED_FIELD', {
    asPromise: true,
});

/**
 * Fetch Partner sso configuration
 *
 * @type {Object}
 */
export const fetchSsoConfiguration = createActions('admin', 'FETCH_PARTNER_SSO_CONFIGURATION', {
    asPromise: true,
});

/**
 * Update Partner sso configuration
 *
 * @type {Object}
 */
export const updatePartnerSsoConfiguration = createActions(
    'admin',
    'UPDATE_PARTNER_SSO_CONFIGURATION',
    {
        asPromise: true,
    }
);

/**
 * Fetch sign in options configuration
 *
 * @type {Object}
 */
export const fetchSignInOptions = createActions('admin', 'SIGN_IN_OPTIONS', {
    asPromise: true,
});

/**
 * Fetch Partner sso api options configuration
 *
 * @type {Object}
 */
export const fetchSsoApiOptions = createActions('admin', 'SSO_API_OPTIONS_CONFIGURATION', {
    asPromise: true,
});

/**
 * Update Partner sso api options configuration
 *
 * @type {Object}
 */
export const updateSsoApiOptions = createActions('admin', 'UPDATE_SSO_API_OPTIONS_CONFIGURATION', {
    asPromise: true,
});

/**
 * Fetch audit trail events
 *
 * @type {Object}
 */
export const fetchAuditTrailEvents = createActions('admin', 'FETCH_AUDIT_TRAIL_EVENTS', {
    asPromise: true,
});

/**
 * Create role
 *
 * @type {Object}
 */
export const createRole = createActions('admin', 'CREATE_ROLE', {
    asPromise: true,
});

/**
 * Delete role
 *
 * @type {Object}
 */
export const deleteRole = createActions('admin', 'DELETE_ROLE', {
    asPromise: true,
});

/**
 * Fetch role
 *
 * @type {Object}
 */
export const fetchRole = createActions('admin', 'FETCH_ROLE', {
    asPromise: true,
});

/**
 * Fetch role for permissions
 *
 * @type {Object}
 */
export const fetchRoleSKUs = createActions('admin', 'FETCH_ROLE_FOR_PERMISSIONS', {
    asPromise: true,
});

/**
 * Fetch roles
 *
 * @type {Object}
 */
export const fetchRoles = createActions('admin', 'FETCH_ROLES', {
    asPromise: true,
});

/**
 * Fetch company types
 *
 * @type {Object}
 */
export const fetchCompanyTypes = createActions('admin', 'FETCH_COMPANY_TYPES', {
    asPromise: true,
});

/**
 * Fetch company statuses
 *
 * @type {Object}
 */
export const fetchCompanyStatuses = createActions('admin', 'FETCH_COMPANY_STATUSES', {
    asPromise: true,
});

/**
 * Deactive companies
 *
 * @type {Object}
 */
export const bulkDeactivateCompanies = createActions('admin', 'BULK_DEACTIVATE_COMPANIES', {
    asPromise: true,
});

/**
 * Update role
 *
 * @type {Object}
 */
export const updateRole = createActions('admin', 'UPDATE_ROLE', {
    asPromise: true,
});

/**
 * Download csv template to import users
 *
 * @type {Object}
 */
export const downloadCsvUserTemplate = createActions('admin', 'DOWNLOAD_CSV_USER_TEMPLATE', {
    asPromise: true,
});

/**
 * Create sku
 *
 * @type {Object}
 */
export const createSKU = createActions('admin', 'CREATE_SKU', {
    asPromise: true,
});

/**
 * Fetch learn packages
 *
 * @type {Object}
 */
export const fetchLearnPackages = createActions('admin', 'FETCH_LEARN_PACKAGES', {
    asPromise: true,
});

/**
 * Fetch sku
 *
 * @type {Object}
 */
export const fetchSKU = createActions('admin', 'FETCH_SKU', {
    asPromise: true,
});

/**
 * Fetch skus
 *
 * @type {Object}
 */
export const fetchSKUs = createActions('admin', 'FETCH_SKUS', {
    asPromise: true,
});

/**
 * Update sku
 *
 * @type {Object}
 */
export const updateSKU = createActions('admin', 'UPDATE_SKU', {
    asPromise: true,
});

/**
 * Send reset email
 *
 * @type {Object}
 */
export const sendResetEmail = createActions('admin', 'SEND_RESET_EMAIL', {
    asPromise: true,
});

/**
 * Reset password
 *
 * @type {Object}
 */
export const resetPassword = createActions('admin', 'RESET_PASSWORD', {
    asPromise: true,
});

/**
 * Reset password
 *
 * @type {Object}
 */
export const validateResetPasswordLink = createActions('admin', 'VALIDATE_RESET_PASSWORD_LINK', {
    asPromise: true,
});

/**
 * Fetch banner's list
 *
 * @type {Object}
 */
export const fetchBanners = createActions('admin', 'FETCH_BANNERS', {
    asPromise: true,
});

/**
 * Fetch newsletter categories
 *
 * @type {Object}
 */
export const fetchNewsletterCategories = createActions('admin', 'FETCH_NEWSLETTER_CATEGORIES', {
    asPromise: true,
});

/**
 * Fetch Roles for newsletter subscription
 *
 * @type {Object}
 */
export const fetchRolesForNewsletterSubscription = createActions(
    'admin',
    'FETCH_ROLES_FOR_NEWSLETTER_SUBSCRIPTION',
    {
        asPromise: true,
    }
);

/**
 * Update banner
 *
 * @type {Object}
 */
export const updateBanner = createActions('admin', 'UPDATE_BANNER', {
    asPromise: true,
});

/**
 * Create banner
 *
 * @type {Object}
 */
export const createBanner = createActions('admin', 'CREATE_BANNER', {
    asPromise: true,
});

/**
 * Delete banner
 *
 * @type {Object}
 */
export const deleteBanner = createActions('admin', 'DELETE_BANNER', {
    asPromise: true,
});

/**
 * Dismiss banner
 *
 * @type {Object}
 */
export const dismissBanner = createActions('admin', 'DISMISS_BANNER', {
    asPromise: true,
});

/**
 * Update user's newsletter subscription.
 *
 * @type {Object}
 */
export const updateUsersNewslettersSubscription = createActions(
    'admin',
    'UPDATE_USERS_NEWSLETTERS_SUBSCRIPTION',
    {
        asPromise: true,
    }
);

/**
 * Export user's csv.
 *
 * @type {Object}
 */
export const exportUsersCSV = createActions('admin', 'EXPORT_USERS_CSV', {
    asPromise: true,
});

/**
 * Redux actions for fetching welcome email preview.
 *
 * @type {Object}
 */
export const fetchWelcomeEmailPreview = createActions('admin', 'FETCH_WELCOME_EMAIL_PREVIEW', {
    asPromise: true,
});

/**
 * Redux actions for sending email to users.
 *
 * @type {Object}
 */
export const sendWelcomeEmail = createActions('admin', 'SEND_WELCOME_EMAIL', {
    asPromise: true,
});

/**
 * Redux actions for fetch custom fields
 *
 * @type {Object}
 */
export const fetchCustomFields = createActions('admin', 'FETCH_CUSTOM_FIELD', {
    asPromise: true,
});

/**
 * Redux actions for move company.
 *
 * @type {Object}
 */
export const moveCompany = createActions('admin', 'MOVE_COMPANY', {
    asPromise: true,
});

/**
 * Redux actions for fetch content details.
 *
 * @type {Object}
 */
export const fetchContentDetails = createActions('admin', 'FETCH_CONTENT_DETAILS', {
    asPromise: true,
});

/**
 * Redux actions for fetch contacts.
 *
 * @type {Object}
 */
export const fetchContactByTagId = createActions('admin', 'FETCH_CONTACT_BY_TAGID', {
    asPromise: true,
});

/**
 * Redux actions to fetch Affiliates.
 *
 * @type {Object}
 */
export const fetchAffiliates = createActions('admin', 'FETCH_AFFILIATES', {
    asPromise: true,
});

/**
 * Redux actions to fetch Affiliate.
 *
 * @type {Object}
 */
export const fetchAffiliate = createActions('admin', 'FETCH_AFFILIATE', {
    asPromise: true,
});

/**
 * Redux actions to update Affiliate.
 *
 * @type {Object}
 */
export const updateAffiliate = createActions('admin', 'UPDATE_AFFILIATE', {
    asPromise: true,
});

/**
 * Redux actions to activate Affiliate.
 *
 * @type {Object}
 */
export const activateAffiliate = createActions('admin', 'ACTIVATE_AFFILIATE', {
    asPromise: true,
});

/**
 * Redux actions to fetch dashboard widgets
 *
 * @type {Object}
 */
export const fetchDashboardWidgets = createActions('admin', 'FETCH_DASHBOARD_WIDGETS', {
    asPromise: true,
});

/**
 * Redux actions to deactivate Affiliate.
 *
 * @type {Object}
 */
export const deactivateAffiliate = createActions('admin', 'DEACTIVATE_AFFILIATE', {
    asPromise: true,
});

/**
 * Redux actions to fetch dashboard widgets
 *
 * @type {Object}
 */
export const updateDashboardWidgets = createActions('admin', 'UPDATE_DASHBOARD_WIDGETS', {
    asPromise: true,
});

/**
 * Redux actions to fetch welcome email intro
 *
 * @type {Object}
 */
export const fetchWelcomeEmailIntro = createActions('admin', 'FETCH_WELCOME_EMAIL_INTRO', {
    asPromise: true,
});

/**
 * Redux actions to update welcome email intro
 *
 * @type {Object}
 */
export const updateWelcomeEmail = createActions('admin', 'UPDATE_WELCOME_EMAIL', {
    asPromise: true,
});

/**
 * Redux actions to fetch partner settings.
 *
 * @type {Object}
 */
export const fetchPartnerSettings = createActions('admin', 'FETCH_PARTNER_SETTINGS', {
    asPromise: true,
});

/**
 * Redux actions to update partner self settings
 *
 * @type {Object}
 */
export const updatePartnerSelfSettings = createActions('admin', 'UPDATE_PARTNER_SELF_SETTINGS', {
    asPromise: true,
});

/**
 * Redux actions to fetch list of sent communications
 *
 * @type {Object}
 */
export const fetchPartnerSentCommunications = createActions(
    'admin',
    'FETCH_PARTNER_SENT_COMMUNICATIONS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions to fetch list of upcoming communications
 *
 * @type {Object}
 */
export const fetchPartnerUpcomingCommunications = createActions(
    'admin',
    'FETCH_PARTNER_UPCOMING_COMMUNICATIONS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions to update an upcoming communication
 *
 * @type {Object}
 */
export const updatePartnerUpcomingCommunication = createActions(
    'admin',
    'UPDATE_PARTNER_UPCOMING_COMMUNICATION',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for sending communication email to users.
 *
 * @type {Object}
 */
export const sendCommunicationEmail = createActions('admin', 'SEND_COMMUNICATION_EMAIL', {
    asPromise: true,
});

/**
 * Redux actions to fetch list of communication types.
 *
 * @type {Object}
 */
export const fetchCommunicationTypes = createActions('admin', 'FETCH_COMMUNICATION_TYPES', {
    asPromise: true,
});

/**
 * Redux actions to fetch roles by permission.
 *
 * @type {Object}
 */
export const fetchRolesByPermission = createActions('admin', 'FETCH_ROLES_BY_PERMISSION', {
    asPromise: true,
});

/**
 * Redux actions for fetching email preview.
 *
 * @type {Object}
 */
export const fetchEmailPreview = createActions('admin', 'FETCH_EMAIL_PREVIEW', {
    asPromise: true,
});

/**
 * Redux actions for fetching unpublished content preview.
 *
 * @type {Object}
 */
export const fetchUnpublishedContentPreview = createActions(
    'admin',
    'FETCH_UNPUBLISHED_CONTENT_PREVIEW',
    {
        asPromise: true,
    }
);

/**
 * Redux actions to fetch list of Onboarding And Notifications communications for a partner
 *
 * @type {Object}
 */
export const fetchPartnerOnboardingAndNotifications = createActions(
    'admin',
    'FETCH_PARTNER_ONBOARDING_NOTIFICATION',
    {
        asPromise: true,
    }
);

/**
 * Redux actions to fetch if a partner is migrated from THR.
 *
 * @type {Object}
 */
export const fetchPartnerMigratedFromTHR = createActions(
    'admin',
    'FETCH_PARTNER_MIGRATED_FROM_THR',
    {
        asPromise: true,
    }
);

/**
 * Update password
 *
 * @type {Object}
 */
export const updatePassword = createActions('admin', 'UPDATE_PASSWORD', {
    asPromise: true,
});

/**
 * Redux actions for fetch content details (preview).
 *
 * @type {Object}
 */
export const fetchPreviewContentDetails = createActions('admin', 'FETCH_PREVIEW_CONTENT_DETAILS', {
    asPromise: true,
});

/**
 * Redux actions to fetch information banner.
 *
 * @type {Object}
 */
export const fetchInformationBanner = createActions('admin', 'FETCH_INFORMATION_BANNER', {
    asPromise: true,
});

/**
 * Forgot username
 *
 * @type {Object}
 */
export const forgotUsername = createActions('admin', 'FORGOT_USERNAME', {
    asPromise: true,
});

/**
 * Fetch EHS Products
 *
 * @type {Object}
 */
export const fetchEHSProducts = createActions('admin', 'FETCH_EHS_PRODUCTS', {
    asPromise: true,
});

/**
 * Get Dashboard Info
 *
 * @type {Object}
 */
export const getDashboardInfo = createActions('admin', 'GET_DASHBOARD_INFO', {
    asPromise: true,
});

/**
 * Get list of users to merge
 * @type {Object}
 */
export const getMergeUserList = createActions('admin', 'GET_MERGE_USER_LIST', {
    asPromise: true,
});

/**
 * Get user to merge
 * @type {Object}
 */
export const getMergeUser = createActions('admin', 'GET_MERGE_USER', {
    asPromise: true,
});

/**
 * Check csv file to mass update users
 * @type {Object}
 */
export const checkCsvMassUpdateUsers = createActions('admin', 'CHECK_CSV_MASS_UPDATE_USERS', {
    asPromise: true,
});

/**
 * Check csv file to mass update users
 * @type {Object}
 */
export const checkCsvMergeMultipleUsers = createActions('admin', 'CHECK_CSV_MERGE_MULTIPLE_USERS', {
    asPromise: true,
});

/**
 * Download csv merge list user
 * @type {Object}
 */
export const downloadMergeCsv = createActions('admin', 'DOWNLOAD_MERGE_CSV', {
    asPromise: true,
});

/**
 * Run merge user proccess
 * @type {Object}
 */
export const runMergeMultipleUsers = createActions('admin', 'RUN_MERGE_MULTIPLE_USERS', {
    asPromise: true,
});

/**
 * Get merge users information
 * @type {Object}
 */
export const fetchMergeUsersInformation = createActions('admin', 'FETCH_MERGE_USERS_INFORMATION', {
    asPromise: true,
});

/**
 * Get mass update user list
 * @type {Object}
 */
export const getMassUpdateUserList = createActions('admin', 'GET_MASS_UPDTE_USER_LIST', {
    asPromise: true,
});

/**
 * Merge single user
 * @type {Object}
 */
export const mergeSingleUser = createActions('admin', 'MERGE_SINGLE_USER', {
    asPromise: true,
});

/**
 * Merge single user
 * @type {Object}
 */
export const runMergeSingleUser = createActions('admin', 'RUN_MERGE_SINGLE_USER', {
    asPromise: true,
});

/**
 * Run mass update process
 * @type {Object}
 */
export const runMassUpdate = createActions('admin', 'RUN_MASS_UPDATE', {
    asPromise: true,
});

/**
 * Run sprint batch process
 * @type {Object}
 */
export const runSprintBatchProcess = createActions('admin', 'RUN_SPRINT_BATCH_PROCESS', {
    asPromise: true,
});

/**
 * Run merge multiple companies
 * @type {Object}
 */
export const runMergeMultipleCompanies = createActions('admin', 'RUN_MERGE_MULTIPLE_COMPANIES', {
    asPromise: true,
});

/**
 * Fetch details merge multiple companies
 * multiple companies
 * @type {Object}
 */
export const fetchDetailsMultipleCompanies = createActions(
    'admin',
    'FETCH_DETAILS_MULTIPLE_COMPANIES',
    {
        asPromise: true,
    }
);

/**
 * Run merge - move single company
 * @type {Object}
 */
export const bulkSingleCompany = createActions('admin', 'BULK_SINGLE_COMPANY', {
    asPromise: true,
});

/**
 * Check csv to mass welcome email
 * multiple companies
 * @type {Object}
 */
export const checkCsvMassWelcomeEmail = createActions('admin', 'CHECK_CSV_MASS_WELCOME_EMAIL', {
    asPromise: true,
});

/**
 * Get user list to mass welcome email
 * multiple companies
 * @type {Object}
 */
export const getWelcomeEmailUsersList = createActions('admin', 'GET_WELCOME_EMAIL_USERS_LIST', {
    asPromise: true,
});

/**
 * Fetch details to mass update users
 * multiple companies
 * @type {Object}
 */
export const fetchMassUpdateInformation = createActions('admin', 'FETCH_MASS_UPDATE_INFORMATION', {
    asPromise: true,
});

/**
 * Run mass welcome email process
 * multiple companies
 * @type {Object}
 */
export const runMassWelcomeEmailProcess = createActions('admin', 'RUN_MASS_WELCOME_EMAIL_PROCESS', {
    asPromise: true,
});

/**
 * Fetch info to merge or move single company
 * multiple companies
 * @type {Object}
 */
export const fetchSingleCompanyInfo = createActions('admin', 'FETCH_SINGLE_COMPANY_INFO', {
    asPromise: true,
});

/**
 * Fetch list of users to multiple companies
 * multiple companies
 * @type {Object}
 */
export const fetchMultipleCompaniesUserList = createActions(
    'admin',
    'FETCH_MULTIPLE_COMPANIES_USER_LIST',
    {
        asPromise: true,
    }
);

/**
 * Run merge or move single company process
 * multiple companies
 * @type {Object}
 */
export const runMoveOrMergeSingleCompany = createActions(
    'admin',
    'RUN_MOVE_OR_MERGE_SINGLE_COMPANY',
    {
        asPromise: true,
    }
);

/**
 * Fetch list of companies to move or merge single company
 * @type {Object}
 */
export const getMoveOrMergeSingleCompanyList = createActions(
    'admin',
    'GET_MOVE_OR_MERGE_SINGLE_COMPANY_LIST',
    {
        asPromise: true,
    }
);

/**
 * Fetch list of companies to move single company
 * @type {Object}
 */
export const getMoveCompaniesList = createActions('admin', 'GET_MOVE_COMPANIES_LIST', {
    asPromise: true,
});

/**
 * Fetch list of user to move or merge multiple company
 * @type {Object}
 */
export const fetchUserListToMultipleCompanies = createActions(
    'admin',
    'FETCH_USER_LIST_TO_MULTIPLE_COMPANIES',
    {
        asPromise: true,
    }
);

/**
 * Run move multiple companies
 * @type {Object}
 */
export const runMoveMultipleCompanies = createActions('admin', 'RUN_MOVE_MULTIPLE_COMPANIES', {
    asPromise: true,
});

/**
 * Check merge partners data
 * @type {Object}
 */
export const checkMergePartners = createActions('admin', 'CHECK_MERGE_PARTNERS', {
    asPromise: true,
});

/**
 * Fetch merge partner information
 * @type {Object}
 */
export const fetchMergePartnersInformation = createActions(
    'admin',
    'FETCH_MERGE_PARTNERS_INFORMATION',
    {
        asPromise: true,
    }
);

/**
 * Fetch merge partner company list
 * @type {Object}
 */
export const getMergePartnerCompanyList = createActions('admin', 'GET_MERGE_PARTNER_COMPANY_LIST', {
    asPromise: true,
});

/**
 * Fetch merge partner user list
 * @type {Object}
 */
export const getMergePartnerUsersList = createActions('admin', 'GET_MERGE_PARTNER_USERS_LIST', {
    asPromise: true,
});

/**
 * Run merge partner process
 * @type {Object}
 */
export const runMergePartnersProcess = createActions('admin', 'RUN_MERGE_PARTNER_PROCESS', {
    asPromise: true,
});

/**
 * Get employee count by state by companyId
 * @type {Object}
 */
export const fetchEmployeeCountByCompanyId = createActions(
    'admin',
    'FETCH_EMPLOYEE_COUNT_BY_COMPANY_ID',
    {
        asPromise: true,
    }
);

/**
 * Cancel Bulk change tool process by id
 * @type {Object}
 */
export const cancelBtcProcessById = createActions('admin', 'CANCEL_BTC_PROCESS_BY_ID', {
    asPromise: true,
});

/**
 * Check status of services to run bulk change tool process
 * @type {Object}
 */
export const checkBctStatusServices = createActions('admin', 'CHECK_BTC_STATUS_SERVICES', {
    asPromise: true,
});

/**
 * Download list of users or companies.
 * @type {Object}
 */
export const downloadCsvList = createActions('admin', 'DOWNLOAD_CSV_LIST', {
    asPromise: true,
});

/**
 * Fetch information to mass welcome email
 * @type {Object}
 */
export const fetchMassWelcomeEmailInformation = createActions(
    'admin',
    'FETCH_MASS_WELCOME_EMAIL_INFORMATION',
    {
        asPromise: true,
    }
);

/**
 * Fetch skus by configuration id
 *
 * @type {Object}
 */
export const fetchSkusByConfigurationId = createActions('admin', 'FETCH_SKUS_BY_CONFIGURATION_ID', {
    asPromise: true,
});

/**
 * Fetch Licenses
 *
 * @type {Object}
 */
export const fetchLicenses = createActions('admin', 'FETCH_LICENSES', {
    asPromise: true,
});

/**
 * Fetch Import history
 *
 * @type {Object}
 */
export const fetchImportHistoryList = createActions('admin', 'FETCH_IMPORT_HISTORY_LIST', {
    asPromise: true,
});

/**
 * Fetch Import history
 *
 * @type {Object}
 */
export const fetchImportDetails = createActions('admin', 'FETCH_IMPORT_DETAILS', {
    asPromise: true,
});
export const fetchAdminOptions = createActions('admin', 'FETCH_ADMIN_OPTIONS', {
    asPromise: true,
});
export const updateAdminOptions = createActions('admin', 'UPDATE_ADMIN_OPTIONS', {
    asPromise: true,
});
