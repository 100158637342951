import { formatDate } from 'ravenjs/utils/date';
import differenceInDays from 'date-fns/difference_in_days';
import differenceInHours from 'date-fns/difference_in_hours';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import isBefore from 'date-fns/is_before';
import isToday from 'date-fns/is_today';
import isYesterday from 'date-fns/is_yesterday';
import parseDate from 'date-fns/parse';
import endOfDay from 'date-fns/end_of_day';
import startOfDay from 'date-fns/start_of_day';
import _inRange from 'lodash/inRange';
import { parseFromTimeZone } from 'date-fns-timezone';

export const getTimeDifferenceBetweenDates = (dateToCompare, date = new Date()) => {
    const days = Math.abs(differenceInDays(dateToCompare, date));
    const hours = Math.abs(differenceInHours(dateToCompare, date)) % 24;
    const minutes = Math.abs(differenceInMinutes(dateToCompare, date)) % 60;
    const seconds = Math.abs(differenceInSeconds(dateToCompare, date)) % 60;
    let timeDifference = '';

    if (days > 1) {
        timeDifference = hours === 1 ? `${days} days, 1 hour` : `${days} days, ${hours} hours`;
    } else if (days === 1) {
        timeDifference = hours === 1 ? `1 day, 1 hour` : `1 day, ${hours} hours`;
    } else if (hours > 1) {
        timeDifference =
            minutes === 1 ? `${hours} hours, 1 minute` : `${hours} hours, ${minutes} minutes`;
    } else if (hours === 1) {
        timeDifference = minutes === 1 ? `1 hour, 1 minute` : `1 hour, ${minutes} minutes`;
    } else if (minutes > 0) {
        timeDifference = minutes === 1 ? `1 minute` : `${minutes} minutes`;
    } else {
        timeDifference = seconds === 1 ? `1 second` : `${seconds} seconds`;
    }

    return timeDifference;
};

export const isDueSoon = (dateToCompare, date = new Date()) => {
    const hours = Math.abs(differenceInHours(dateToCompare, date));
    const pastDue = isBefore(dateToCompare, date);

    return !pastDue && hours < 24;
};

export const isPastDue = (dateToCompare, date = new Date()) => {
    const pastDue = isBefore(dateToCompare, date);

    return pastDue;
};

/**
 * Get the number of days from a set of given dates.
 *
 * @method getNumberOfDaysBetweenDates
 * @param  {string|number|Object}      oldDate The old date or timestamp
 * @param  {string|number|Object}      newDate The new date or timestamp
 * @return {Object}
 */
export function getNumberOfDaysBetweenDates(oldDate, newDate = new Date()) {
    const formatOldDate = formatDate(new Date(oldDate), 'MMMM DD, YYYY hh:mm:ss a', 'UTC');
    const formatNewDate = newDate
        ? formatDate(newDate, 'MMMM DD, YYYY hh:mm:ss a', 'UTC')
        : formatDate(new Date(), 'MMMM DD, YYYY hh:mm:ss a', 'UTC');

    const days = differenceInDays(parseDate(formatNewDate), parseDate(formatOldDate));
    switch (true) {
        case days === 0: {
            return {
                daysInWords: 'Today',
                // Fix the issue with the date-fns library at times returning `-0`
                days: 0,
            };
        }
        case days === 1: {
            return {
                daysInWords: 'Yesterday',
                days,
            };
        }
        case days < 0: {
            // Since we are in the 'negative' number territory,
            // we have to use Math.abs() to correct the final `date`.
            // This also means that the date is in the future.
            return {
                daysInWords: `${Math.abs(days)} from Today`,
                days,
            };
        }
        default: {
            return {
                daysInWords: `${days} Days Ago`,
                days,
            };
        }
    }
}

/**
 * Get timestamp from given date, time and meridian.
 *
 * @param {string}        date
 * @param {string}        time
 * @param {string}        meridian
 * @return {number}
 */
export const getTimeStampFromDateTimeMeridian = ({ date, time, meridian }) => {
    return new Date(`${date} ${time} ${meridian}`).getTime();
};

export const getCSTTimeStampFromDateTimeMeridian = ({ date, time, meridian }) => {
    const dateValue = `${date} ${time} ${meridian}`;
    const timeZone = 'America/Chicago';
    const formattedDate = parseFromTimeZone(dateValue, { timeZone });
    return formattedDate.getTime();
};

/**
 * Get timestamp from current date in seconds.
 *
 * @return {number}
 */
export const getCurrentTimeStampInSeconds = () => {
    return Math.floor(new Date().getTime() / 1000);
};

export const getFormattedTimestamp = (timestamp, dateFormat = 'MM/DD/YYYY hh:mm A') => {
    if (isYesterday(timestamp)) {
        return `yesterday at ${formatDate(timestamp, dateFormat)}`;
    } else if (isToday(timestamp)) {
        return `today at ${formatDate(timestamp, dateFormat)}`;
    }
    return formatDate(timestamp, dateFormat);
};

export const getSimpleFormattedTimestamp = timestamp => {
    const seconds = differenceInSeconds(new Date(), timestamp);
    if (seconds < 60) {
        return `${seconds}s`;
    } else if (seconds < 3600) {
        return `${Math.floor(seconds / 60)}mn`;
    } else if (seconds < 86400) {
        return `${Math.floor(seconds / 3600)}hr`;
    } else if (seconds < 604800) {
        return `${Math.floor(seconds / 86400)}d`;
    }
    return `${Math.floor(seconds / 604800)}w`;
};

export const getDiffDays = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
};

export const dateUpdated = updatedAt => {
    if (updatedAt === 0) {
        return 'N/A';
    } else if (typeof updatedAt === 'number') {
        return formatDate(updatedAt * 1000, 'MM/DD/YYYY', 'UTC');
    } else if (typeof updatedAt === 'string') {
        return formatDate(Date.parse(updatedAt), 'MM/DD/YYYY', 'UTC');
    }

    return '';
};

export const isValidRangeBetweenYears = (date1, date2, numberYears) => {
    return (
        differenceInDays(date1, date2) >= 0 && numberYears * 366 >= differenceInDays(date1, date2)
    );
};

/**
 * Get downtime warning phase in 1 minute range, This buffer helps to re-verify before user warning.
 * @param {Object}    timestamp
 * @return {string|null}
 */
export const getDowntimeWarningPhase = timestamp => {
    const timeNow = Math.round(new Date().getTime() / 1000);

    if (_inRange(timeNow, timestamp - 1860, timestamp - 1800)) {
        return 'INITIAL_PHASE';
    } else if (_inRange(timeNow, timestamp - 960, timestamp - 900)) {
        return 'MEDIAL_PHASE';
    } else if (_inRange(timeNow, timestamp - 120, timestamp - 60)) {
        return 'TERMINAL_PHASE';
    }
    return null;
};

export const getCustomDate = {
    yesterdayStartDate: () => {
        const today = new Date();
        const yesterdayStart = new Date(today);
        yesterdayStart.setDate(yesterdayStart.getDate() - 1);
        return startOfDay(new Date(yesterdayStart));
    },
    yesterdayEndDate: () => {
        const today = new Date();
        const yesterdayEnd = new Date(today);
        yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);
        return endOfDay(new Date(yesterdayEnd));
    },
    todayStartDate: () => {
        const todayStart = new Date();
        return startOfDay(todayStart);
    },
    todayEndDate: () => {
        const todayEnd = new Date();
        return endOfDay(todayEnd);
    },
    tomorrowStartDate: () => {
        const today = new Date();
        const tomorrowStart = new Date(today);
        tomorrowStart.setDate(tomorrowStart.getDate() + 1);
        return startOfDay(new Date(tomorrowStart));
    },
    tomorrowEndDate: () => {
        const today = new Date();
        const tomorrowEnd = new Date(today);
        tomorrowEnd.setDate(tomorrowEnd.getDate() + 1);
        return endOfDay(new Date(tomorrowEnd));
    },
};

export const getCustomFormattedTimestamp = timestamp => {
    const seconds = differenceInSeconds(new Date(), timestamp);
    if (seconds < 60) {
        return `${seconds} sec${seconds === 1 ? '' : 's'} ago`;
    } else if (seconds < 3600) {
        const mins = Math.floor(seconds / 60);
        return `${mins} min${mins === 1 ? '' : 's'} ago`;
    } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    } else if (seconds < 604800) {
        const days = Math.floor(seconds / 86400);
        return `${days} day${days === 1 ? '' : 's'} ago`;
    }
    const weeks = Math.floor(seconds / 604800);
    return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
};

export const convertTimestampToUtcTimestamp = timestampInSeconds => {
    return timestampInSeconds ? timestampInSeconds - new Date().getTimezoneOffset() * 60 : '';
};

export const convertTimestampToUtc = timestampInSeconds => {
    if (!timestampInSeconds) {
        return '';
    }

    const date = new Date(timestampInSeconds * 1000);
    const offsetInMinutes = date.getTimezoneOffset();

    return new Date(timestampInSeconds * 1000 + offsetInMinutes * 60 * 1000);
};

export const convertUnixTimestampToDateFormat = timestamp => {
    // Convert Unix timestamp to milliseconds
    const milliseconds = timestamp * 1000;

    // Create a new Date object
    const dateObject = new Date(milliseconds);

    // Options for formatting the date
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    // Format the date using the options
    const formattedDate = dateObject.toLocaleDateString('en-US', options);
    return formattedDate;
};

export const convertUnixTimestampToDateFormatv2 = (
    timestamp,
    options = {
        year: 'numeric',

        month: 'short',
        day: 'numeric',
    }
) => {
    // Create a new Date object
    const dateObject = new Date(timestamp);

    // Format the date using the options
    const formattedDate = dateObject.toLocaleDateString('en-US', options);
    return formattedDate;
};

export const convertUnixTimestampToDateFormatv3 = (timestamp, timezone = 'America/Los_Angeles') => {
    const dateObject = new Date(timestamp);

    // Get the date components
    const day = dateObject
        .getDate()
        .toString()
        .padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = dateObject.getFullYear();

    // Use toLocaleTimeString to format the time (in 12-hour format)
    const timeOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: timezone,
    };
    const timeString = dateObject.toLocaleTimeString('en-US', timeOptions);

    // Manually adjust the timezone abbreviation to "PT"
    const timeZoneAbbreviation = 'PT';

    // Return the formatted date and time with "PT"
    return `${month}/${day}/${year} ${timeString} ${timeZoneAbbreviation}`;
};

export function convertCalendarToDate(dateStr) {
    const [month, day, year] = dateStr.split('-');
    const date = new Date(Date.UTC(year, month - 1, day));
    date.setUTCHours(0, 0, 0, 0);
    return date;
}
