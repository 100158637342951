import { takeLatest } from 'redux-saga/effects';

import * as handbookActions from '../actions';
import {
    createCustomPolicySaga,
    fetchHandbooksListSaga,
    fetchHandbookByIdSaga,
    downloadHandbookByIdSaga,
    downloadHandbookByStateSaga,
    deleteHandbookByIdSaga,
    copyHandbookByIdSaga,
    fetchHandbookTOCSaga,
    unlinkCustomPolicySaga,
    policyChangeStatusSaga,
    updateHandbookTOCSaga,
    acceptSummaryOfChangesSaga,
    acceptPolicySummaryOfChangesSaga,
    cancelSummaryOfChangesSaga,
    fetchSummaryOfChangesSaga,
    fetchPolicySummaryOfChangesSaga,
    acceptPolicyAlertSaga,
    dismissPolicyAlertSaga,
    fetchCustomPoliciesListSaga,
    createHandbookSaga,
    fetchPolicyDetailsSaga,
    fetchCustomPolicyDetailsSaga,
    updateCustomPolicySaga,
    fetchHandbookSaga,
    updateHandbookSaga,
    savePolicySaga,
    fetchActivityStreamSaga,
    fetchActivityStreamEventIdSaga,
    linkCustomPoliciesSaga,
    uploadFileSaga,
    downloadActivityStreamSaga,
    deleteHandbookTocSaga,
    fetchHandbooksMhrListSaga,
    fetchDeletedHandbooksListSaga,
    restoreDeletedHandbookSaga,
    deleteHandbookMhrByIdSaga,
    fetchArchivedHandbooksSaga,
    downloadArchivedHandbookSaga,
    isHandbookTranslatedSaga,
    uploadHandbookPolicyImageSaga,
    getHandbookPolicyImagesUpdatedSaga,
    getFroalaOptionsSaga,
    getSmartHandbookOptionsSaga,
    getHandbookPolicyPreviewSaga,
    fetchHandbooksMoveHandbookListSaga,
    fetchHandbooksMoveCompanyListSaga,
    fetchHandbookMoveDetailSaga,
    moveHandbookSaga,
    fetchTotalDocumentsListSaga,
    fetchDocumentsReportSaga,
    checkDocumentDownloadStatusSaga,
    downloadGeneratedHandbookSaga,
} from './fetch';

/**
 * The root `handbook` saga.
 * We're triggering sagas based their respective events.
 *
 * @method handbookSaga
 * @type   {Generator}
 */
function* handbookSaga() {
    // Fetch a list of handbooks.
    yield takeLatest(handbookActions.fetchHandbooks.TRIGGER, fetchHandbooksListSaga);
    // Fetch a single handbook by id.
    yield takeLatest(handbookActions.fetchHandbookById.TRIGGER, fetchHandbookByIdSaga);
    // Download a single handbook by id.
    yield takeLatest(handbookActions.downloadHandbookById.TRIGGER, downloadHandbookByIdSaga);
    // Download a single handbook by state.
    yield takeLatest(handbookActions.downloadHandbookByState.TRIGGER, downloadHandbookByStateSaga);
    // Delete a single handbook by id.
    yield takeLatest(handbookActions.deleteHandbookById.TRIGGER, deleteHandbookByIdSaga);
    // copy a single handbook by id.
    yield takeLatest(handbookActions.copyHandbookById.TRIGGER, copyHandbookByIdSaga);
    // Fetch handbook TOC by id.
    yield takeLatest(handbookActions.fetchHandbookTOC.TRIGGER, fetchHandbookTOCSaga);
    // Unlink custom policy for a handbook TOC by id.
    yield takeLatest(handbookActions.unlinkCustomPolicy.TRIGGER, unlinkCustomPolicySaga);
    // To enable or disable policy for a handbook TOC by policy id.
    yield takeLatest(handbookActions.policyChangeStatus.TRIGGER, policyChangeStatusSaga);
    // To update TOC reorder for a handbook.
    yield takeLatest(handbookActions.updateHandbookTOC.TRIGGER, updateHandbookTOCSaga);
    // Summary of Changes
    yield takeLatest(handbookActions.acceptSummaryOfChanges.TRIGGER, acceptSummaryOfChangesSaga);
    yield takeLatest(
        handbookActions.acceptPolicySummaryOfChanges.TRIGGER,
        acceptPolicySummaryOfChangesSaga
    );
    yield takeLatest(handbookActions.cancelSummaryOfChanges.TRIGGER, cancelSummaryOfChangesSaga);
    yield takeLatest(handbookActions.fetchSummaryOfChanges.TRIGGER, fetchSummaryOfChangesSaga);
    yield takeLatest(
        handbookActions.fetchPolicySummaryOfChanges.TRIGGER,
        fetchPolicySummaryOfChangesSaga
    );
    // Accept policy alert
    yield takeLatest(handbookActions.acceptPolicyAlert.TRIGGER, acceptPolicyAlertSaga);
    // Dismiss policy alert
    yield takeLatest(handbookActions.dismissPolicyAlert.TRIGGER, dismissPolicyAlertSaga);
    // To fetch custom policies list.
    yield takeLatest(handbookActions.fetchCustomPolicies.TRIGGER, fetchCustomPoliciesListSaga);
    yield takeLatest(handbookActions.createCustomPolicy.TRIGGER, createCustomPolicySaga);
    yield takeLatest(handbookActions.createHandbook.TRIGGER, createHandbookSaga);
    yield takeLatest(handbookActions.fetchPolicyDetails.TRIGGER, fetchPolicyDetailsSaga);
    yield takeLatest(
        handbookActions.fetchCustomPolicyDetails.TRIGGER,
        fetchCustomPolicyDetailsSaga
    );
    yield takeLatest(handbookActions.updateCustomPolicy.TRIGGER, updateCustomPolicySaga);
    yield takeLatest(handbookActions.fetchHandbook.TRIGGER, fetchHandbookSaga);
    yield takeLatest(handbookActions.updateHandbook.TRIGGER, updateHandbookSaga);
    yield takeLatest(handbookActions.savePolicy.TRIGGER, savePolicySaga);
    yield takeLatest(handbookActions.fetchActivityStream.TRIGGER, fetchActivityStreamSaga);
    yield takeLatest(
        handbookActions.fetchActivityStreamEventId.TRIGGER,
        fetchActivityStreamEventIdSaga
    );
    yield takeLatest(handbookActions.linkCustomPolicies.TRIGGER, linkCustomPoliciesSaga);
    yield takeLatest(handbookActions.uploadFile.TRIGGER, uploadFileSaga);
    yield takeLatest(handbookActions.downloadActivityStream.TRIGGER, downloadActivityStreamSaga);
    yield takeLatest(handbookActions.deleteHandbookToc.TRIGGER, deleteHandbookTocSaga);
    // Fetch a list of handbooks mhr.
    yield takeLatest(handbookActions.fetchHandbooksMhr.TRIGGER, fetchHandbooksMhrListSaga);
    // Fetch list of deleted handbooks
    yield takeLatest(handbookActions.fetchDeletedHandbooks.TRIGGER, fetchDeletedHandbooksListSaga);
    // Restore a single handbook by id.
    yield takeLatest(
        handbookActions.restoreDeletedHandbookById.TRIGGER,
        restoreDeletedHandbookSaga
    );
    // Fetch Handbooks List for Handbooks Move
    yield takeLatest(
        handbookActions.fetchHandbooksMoveHandbooks.TRIGGER,
        fetchHandbooksMoveHandbookListSaga
    );

    // Fetch Companies List for Handbooks Move
    yield takeLatest(
        handbookActions.fetchHandbooksMoveCompanies.TRIGGER,
        fetchHandbooksMoveCompanyListSaga
    );
    // Fetch Handbook Move Detail
    yield takeLatest(handbookActions.fetchHandbookMoveDetail.TRIGGER, fetchHandbookMoveDetailSaga);
    // Delete a single handbook by id.
    yield takeLatest(handbookActions.deleteHandbookMhrById.TRIGGER, deleteHandbookMhrByIdSaga);
    // Move Handbook
    yield takeLatest(handbookActions.moveHandbook.TRIGGER, moveHandbookSaga);
    yield takeLatest(handbookActions.fetchArchivedHandbooks.TRIGGER, fetchArchivedHandbooksSaga);
    yield takeLatest(
        handbookActions.downloadArchivedHandbook.TRIGGER,
        downloadArchivedHandbookSaga
    );
    yield takeLatest(handbookActions.isHandbookTranslated.TRIGGER, isHandbookTranslatedSaga);
    yield takeLatest(
        handbookActions.uploadHandbookPolicyImage.TRIGGER,
        uploadHandbookPolicyImageSaga
    );
    yield takeLatest(
        handbookActions.getHandbookPolicyImagesUpdated.TRIGGER,
        getHandbookPolicyImagesUpdatedSaga
    );
    yield takeLatest(handbookActions.getFroalaOptions.TRIGGER, getFroalaOptionsSaga);
    yield takeLatest(handbookActions.getSmartHandbookOptions.TRIGGER, getSmartHandbookOptionsSaga);
    yield takeLatest(
        handbookActions.getHandbookPolicyPreview.TRIGGER,
        getHandbookPolicyPreviewSaga
    );
    yield takeLatest(handbookActions.fetchTotalDocumentsList.TRIGGER, fetchTotalDocumentsListSaga);
    yield takeLatest(handbookActions.fetchDocumentsReport.TRIGGER, fetchDocumentsReportSaga);
    yield takeLatest(
        handbookActions.checkDocumentDownloadStatus.TRIGGER,
        checkDocumentDownloadStatusSaga
    );
    yield takeLatest(
        handbookActions.downloadGeneratedHandbook.TRIGGER,
        downloadGeneratedHandbookSaga
    );
}

export default handbookSaga;

export { fetchArchivedHandbooksSaga, checkDocumentDownloadStatusSaga, fetchDocumentsReportSaga };
