import { lazy } from 'react';

import { formatDate } from 'ravenjs/utils/date';
import { renderCheck } from 'ravenjs/utils/viewport';
import { get, isEmpty } from 'ravenjs/utils/lodash';
import showdown from 'showdown';

import { AI_TIMEOUT_MILLISECONDS, API_ORIGIN, API_TOOLS_ORIGIN } from 'constants/api';
import { SEGMENT_ID } from 'constants/inPlatformMarketing';
import {
    ANY_RECORDS,
    FEDERAL_OPTION,
    RESOURCE_TYPE_CONTENTFUL,
    STATUS_COMPLETE,
    STATUS_ERROR,
    STATUS_OPEN,
} from 'constants/ai';
import { NOT_SET } from 'constants/common';

/**
 * Check if string uis valid url.
 *
 * @method isValidUrl
 * @param  {string} string
 * @return {string}
 */
export const isValidUrl = string => {
    const res = string.match(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );

    return !isEmpty(res);
};

export const getHeightIframe = () => {
    const isMobile = renderCheck('md', 'less');
    const logoBar = document.getElementsByClassName('LogoBar').item(0);
    const header = isMobile
        ? document.getElementsByClassName('mobileHeaderNav').item(0)
        : document.getElementsByTagName('header').item(0);
    const impersonatingBar = document.getElementsByClassName('impersonatingBar').item(0);
    const subHeader = document.getElementsByClassName('sticky-inner-wrapper').item(0);
    const body = document.getElementsByTagName('body').item(0);
    const footer = document.getElementsByClassName('footerPage').item(0);
    const logoBarHeight = logoBar ? logoBar.offsetHeight : 0;
    const headerHeight = header ? header.offsetHeight : 0;
    const impersonatingBarHeight = impersonatingBar ? impersonatingBar.offsetHeight : 0;
    const subHeaderHeight = subHeader ? subHeader.offsetHeight : 0;
    const bodyHeight = body ? body.offsetHeight : 0;
    const footerHeight = footer ? footer.offsetHeight : 0;
    const iframeHeight =
        bodyHeight -
        logoBarHeight -
        subHeaderHeight -
        impersonatingBarHeight -
        headerHeight -
        footerHeight;

    return iframeHeight;
};

export const lazyWithRetry = componentImport =>
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem('page-has-been-force-refreshed', 'false');

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem('page-has-been-force-refreshed', 'true');
                return window.location.reload();
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });

/**
 * Check if URL string contains the featureFlag text
 *
 * @method hasFeatureFlag
 * @param  {obj} location From the React Router history object
 * @param  {string} featureFlag
 * @return {bool}
 */
export const hasFeatureFlag = (location, featureFlag) => {
    const { search } = location;
    return search.indexOf(featureFlag) !== -1;
};

/**
 * Check if window.hostname contains the hostname text
 *
 * @method hasHostname
 * @param  {string} hostname
 * @return {bool}
 */
export const hasHostname = hostname => {
    const containsDomainString =
        window && window.location && window.location.hostname.indexOf(hostname) !== -1;

    return containsDomainString;
};

/**
 * Build payload for In-Product Marketing
 *
 * @param   {Object}    loggedUser
 * @param   {Bool}      isImpersonatingUser
 * @param   {string}    OptyName
 * @param   {string}    PendoId
 * @param   {string}    Source
 * @param   {string}    AdType
 * @param   {string}    EmployeeCount
 * @return  {Object}
 */
export function buildPayloadForInProductMarketing(
    loggedUser = {},
    isImpersonatingUser = false,
    OptyName = '',
    PendoId = '',
    Source = '',
    AdType = '',
    EmployeeCount = ''
) {
    const { company = {}, info = {} } = loggedUser;
    const {
        companyName,
        displayName,
        companyId,
        location: { address, city, state, zip },
        partnerId,
        partnerName,
    } = company;
    const { firstName, lastName, email, phone = '', userId, userName } = info;
    const currentDate = new Date();

    const bodyParams = {
        AdType,
        REName: companyName,
        REDisplayName: displayName,
        REClientID: companyId,
        REAddress: address,
        RECity: city,
        REState: state,
        REZip: zip,
        PartnerName: partnerName,
        PartnerID: partnerId,
        ImpersonatorUsername: isImpersonatingUser ? userName : '',
        ContactFName: firstName,
        ContactLName: lastName,
        ContactEmail: email,
        ContactPhone: phone,
        ContactID: userId,
        OptyName,
        Source,
        Timestamp: `${currentDate.getTime()} (${formatDate(
            currentDate,
            'MMMM Do YYYY, h:mm:ss PT'
        )})`,
        PendoId,
        EmployeeCount,
    };
    let body = '';

    Object.keys(bodyParams).forEach(param => {
        body = `${body}${param}: ${bodyParams[param]}<br/>`;
    });

    return {
        body,
        OptyName,
    };
}

/**
 * Build drop down options
 *
 * @param   {Object}    data
 * @param   {string}    labelKey
 * @param   {string}    valueKey
 * @return  [Array]
 */

export function buildDropDownOptions(data = {}, labelKey = '', valueKey = '') {
    const options = [];

    for (const ele of Object.entries(data)) {
        let option = {};
        if (ele.length > 1) {
            option = {
                label: ele[1][labelKey],
                value: ele[1][valueKey],
            };
        } else {
            option = { label: ele[labelKey], value: ele[valueKey] };
        }
        options.push(option);
    }
    const sortedOptions = options.slice(1).sort((a, b) => a.label.localeCompare(b.label));
    const result = isEmpty(sortedOptions) ? [] : [options[0], ...sortedOptions];

    return result;
}

export const isProductionEnvironment = () => {
    return API_TOOLS_ORIGIN.indexOf('trustmineral.com') !== -1;
};

export const isAlphaEnvironment = () => {
    return API_TOOLS_ORIGIN.indexOf('alpha01') !== -1;
};

export const isQAEnvironment = () => {
    return API_TOOLS_ORIGIN.indexOf('qa01') !== -1;
};

export const isDevEnvironment = () => {
    return API_TOOLS_ORIGIN.indexOf('dev') !== -1;
};

export const isPreProdEnvironment = () => {
    return API_TOOLS_ORIGIN.indexOf('preprod01') !== -1;
};

export const getEnvironment = () => {
    let environment = '';

    if (isProductionEnvironment()) {
        environment = 'PROD';
    } else if (isAlphaEnvironment()) {
        environment = 'ALPHA';
    } else if (isQAEnvironment()) {
        environment = 'QA';
    } else if (isPreProdEnvironment()) {
        environment = 'PREPROD';
    } else {
        environment = 'DEV';
    }

    return environment;
};

export const fixedEncodeURIComponent = str => {
    return encodeURIComponent(str).replace(
        /[!'()*]/g,
        c =>
            `%${c
                .charCodeAt(0)
                .toString(16)
                .toUpperCase()}`
    );
};

export const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.msMaxTouchPoints;
};

export const pluralize = (text, count, pluralize = false) => {
    return `${count} ${text}${count > 1 || pluralize ? 's' : ''}`;
};

export const capitalizeTextInHTML = (html, textToCapitalize) => {
    if (!textToCapitalize) {
        return html;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const processTextNode = node => {
        node.nodeValue = capitalizeAtStartOrAfterPeriod(node.nodeValue, textToCapitalize);
    };

    const traverseNodes = node => {
        if (node.nodeType === Node.TEXT_NODE) {
            processTextNode(node);
        } else if (node.childNodes.length > 0) {
            for (const childNode of node.childNodes) {
                traverseNodes(childNode);
            }
        }
    };

    traverseNodes(doc.body);

    return new XMLSerializer().serializeToString(doc);
};

export const capitalizeAtStartOrAfterPeriod = (text, wordToCapitalize) => {
    const capitalizedWord = wordToCapitalize.charAt(0).toUpperCase() + wordToCapitalize.slice(1);
    const regex = new RegExp(`(^|\\.\\s+)(${wordToCapitalize}\\b)`, 'gi');

    return text.replace(regex, (match, prefix, word) => {
        return prefix + capitalizedWord;
    });
};

export const getTextFromHTML = html => {
    const parser = new DOMParser();
    return parser.parseFromString(html, 'text/html').documentElement.textContent;
};

export const getTemplateWithLinkCustomizable = (ad, branding) => {
    const coreBannerUrl = get(branding, 'coreBannerUrl');
    const ctaLink = get(ad, 'ctaLink', '');
    const template = get(ad, 'template', '');
    const segmentId = get(ad, 'segment.id', '');
    const customTemplate = template.replace(
        /(<a[^>]*href=")([^"]*)("[^>]*>)(.*?)(<\/a>)/g,
        `$1${coreBannerUrl}$3$4$5`
    );

    return !isEmpty(coreBannerUrl) && segmentId === SEGMENT_ID && !isEmpty(ctaLink)
        ? customTemplate
        : template;
};

export const extractImageName = url => {
    if (!url) {
        return '';
    }
    const parts = url.split('/');
    const fileName = parts.pop().split('?')[0];
    return fileName;
};

export const compareDuplicatedImages = ({ oldHtml, newHtml }) => {
    const oldImageUrl = oldHtml.match(/<img src="([^"]*)"/);
    const newImageUrl = newHtml.match(/<img src="([^"]*)"/);

    const oldImageName =
        oldImageUrl && oldImageUrl[1] ? extractImageName(oldImageUrl[1].split('/').pop()) : '';
    const newImageName =
        newImageUrl && newImageUrl[1] ? extractImageName(newImageUrl[1].split('/').pop()) : '';

    if (oldImageName && newImageName && oldImageName === newImageName) {
        const newImage = newHtml.match(/<img(.*?)>/)[0];
        oldHtml = oldHtml.replace(/<img(.*?)>/s, newImage);
    }

    return oldHtml;
};

export const getChatSessionId = () => {
    return localStorage.getItem('ai_chat_session_active');
};

export const resizeContainer = params => {
    const { chatBodyRef, chatAiHeaderRef, chatAiFormRef } = params;
    const isMobile = renderCheck('lg', 'less');
    const logoBar = document.getElementsByClassName('LogoBar').item(0);
    const header = isMobile
        ? document.getElementsByClassName('mobileHeaderNav').item(0)
        : document.getElementsByTagName('header').item(0);
    const subHeader = document.getElementsByClassName('sticky-inner-wrapper').item(0);
    const impersonatingBar = document.getElementsByClassName('impersonatingBar').item(0);

    const logoBarHeight = logoBar ? logoBar.offsetHeight : 0;
    const headerHeight = header ? header.offsetHeight : 0;
    const subHeaderHeight = subHeader ? subHeader.offsetHeight : 0;
    const impersonatingBarHeight = impersonatingBar ? impersonatingBar.offsetHeight : 0;
    const bodyHeightElements = logoBarHeight + headerHeight + impersonatingBarHeight;
    const chatAiHeaderOffsetHeight = chatAiHeaderRef.current.offsetHeight;
    const chatAiFormOffsetHeight = chatAiFormRef.current.offsetHeight;
    const windowHeight = window.innerHeight;
    const chatBodyElement = chatBodyRef.current;

    setTimeout(() => {
        const mainContainer = document.getElementById('app_wrapper');
        if (!mainContainer) return;
        const mainContainerHeight = mainContainer.clientHeight;

        const mainContainerChatHeight = mainContainerHeight - bodyHeightElements;
        const appContainer = document.getElementById('mainAppContent');

        const chatBodyElementHeight =
            mainContainerChatHeight -
            subHeaderHeight -
            chatAiHeaderOffsetHeight -
            chatAiFormOffsetHeight -
            48;

        if (windowHeight >= 820) {
            appContainer.style.height = isMobile ? null : `${mainContainerChatHeight}px`;
            chatBodyElement.style.height = isMobile ? null : `${chatBodyElementHeight}px`;
        } else {
            appContainer.style.height = null;
            chatBodyElement.style.height = null;
        }
    }, 100);
};

export const updatedSelectedStates = states => {
    const historyChat = JSON.parse(localStorage.getItem('ai_chat_session'));
    const selectedStateFound = historyChat
        ? states.find(state => state.name === historyChat[historyChat.length - 1].state)
        : '';
    const selectedState = selectedStateFound
        ? { value: selectedStateFound.abbreviation, label: selectedStateFound.name }
        : null;

    return [historyChat, selectedState];
};

export const getIpmParams = params => {
    const { currentUser, userCompany, userCompanyLocations, encodedResponse, pageList } = params;
    let companyEmployeeCount = 0;
    const stateAbbreviations = [
        ...new Set(
            userCompanyLocations?.map(location => {
                companyEmployeeCount += location.employeeCount;
                return location.stateCode;
            })
        ),
    ];

    const ipmParams = {
        filters: {
            pageList,
            stateList: stateAbbreviations,
            industry: userCompany?.industry,
            partnerId: currentUser?.partnerId,
            email: currentUser?.email,
            employeeCount: companyEmployeeCount,
            partnerGtmModel: userCompany?.gtmModel || NOT_SET,
            userSaleForceId: currentUser?.salesforceId || null,
            companySaleForceId: userCompany?.salesforceId || null,
        },
    };

    return encodedResponse ? window.btoa(JSON.stringify(ipmParams)) : ipmParams;
};

export const getWebsocketOrigin = () => {
    return API_ORIGIN.startsWith('https:')
        ? API_ORIGIN.replace('https:', 'wss:')
        : API_ORIGIN.replace('http:', 'ws:');
};

export const buildCommunityUrl = params => {
    const { authKey, jwt, target } = params;
    const communityUrl = `${target}/entry/connect/JWTSSO/?authKey=${authKey}&jwt=${jwt}&target=${target}`;

    return communityUrl;
};

export const getOnChangeStateOfImport = formData => {
    return {
        formData,
        disabled: isEmpty(formData?.uploadInfo?.file),
        showPartialSuccessImport: false,
        response: {
            showReport: false,
        },
    };
};

export const verifyChatAnswerStatus = historyChat => {
    const lastItem = historyChat ? historyChat[historyChat.length - 1] : {};

    return lastItem
        ? (!('answer' in lastItem) || !lastItem.answer) && lastItem.chatStatus !== STATUS_COMPLETE
        : false;
};

export const updatedHistoryChatData = (historyChatApi, states) => {
    const historyChat = JSON.parse(localStorage.getItem('ai_chat_session')) || [];
    const converter = new showdown.Converter();
    const messagesChatApi = historyChatApi.messages || [];
    const stateAbbreviation = historyChatApi.state || '';
    const processedIds = new Set();
    let selectedStateFound = '';

    if (isEmpty(historyChat) || isEmpty(historyChat[0]?.selectedState)) {
        const filteredStates =
            stateAbbreviation === FEDERAL_OPTION.abbreviation
                ? FEDERAL_OPTION
                : states.find(state => state.abbreviation === stateAbbreviation);
        selectedStateFound = filteredStates.name;
        historyChat.unshift({
            selectedState: {
                value: filteredStates.abbreviation,
                label: filteredStates.name,
            },
        });
    } else {
        selectedStateFound = historyChat[0].selectedState.label;
    }

    const mergedArray = historyChat.map(item1 => {
        if (item1.chatMessageId) {
            const matchingItem = messagesChatApi.find(
                item2 =>
                    item2.id === item1.chatMessageId && item2.chatSessionId === item1.chatSessionId
            );

            if (matchingItem) {
                processedIds.add(matchingItem.id);
                return {
                    ...item1,
                    ...(matchingItem.answer && { answer: converter.makeHtml(matchingItem.answer) }),
                    ...(matchingItem.chatStatus && {
                        chatStatus:
                            matchingItem.chatStatus !== STATUS_COMPLETE
                                ? STATUS_ERROR
                                : STATUS_COMPLETE,
                    }),
                    ...(matchingItem.query && { query: matchingItem.query }),
                    ...(matchingItem.resourceType && { resourceType: matchingItem.resourceType }),
                    ...(matchingItem.resources && { resources: matchingItem.resources }),
                    state: selectedStateFound,
                };
            }
        }
        return item1;
    });

    messagesChatApi.forEach(item2 => {
        if (!processedIds.has(item2.id)) {
            mergedArray.push({
                query: item2.query,
                answer: item2.answer ? converter.makeHtml(item2.answer) : '',
                likeDislike: null,
                chatSessionId: item2.chatSessionId,
                chatStatus: item2.chatStatus !== STATUS_COMPLETE ? STATUS_ERROR : STATUS_COMPLETE,
                chatMessageId: item2.id,
                additionalResources: [],
                additionalResourcesFailed: [],
                state: selectedStateFound,
                resourceType: item2.resourceType || null,
                resources: item2.resources || [],
            });
        }
    });

    localStorage.setItem('ai_chat_session', JSON.stringify(mergedArray));

    return mergedArray;
};

export const fetchInitContentLinks = async (historyChat, fetchContentLinks) => {
    // eslint-disable-next-line no-plusplus
    for (let positionChat = 0; positionChat < historyChat.length; positionChat++) {
        const chat = historyChat[positionChat];

        if (
            chat.resourceType === RESOURCE_TYPE_CONTENTFUL &&
            !isEmpty(chat.resources) &&
            chat.chatStatus === STATUS_COMPLETE &&
            chat.additionalResources.length === 0 &&
            chat.additionalResourcesFailed.length === 0
        ) {
            for (const [key, value] of Object.entries(chat.resources)) {
                const contentLinkId = key;
                const searchParams = {
                    search: contentLinkId,
                    limit: 1,
                };

                try {
                    // eslint-disable-next-line no-await-in-loop
                    const contentLinksResponseData = await fetchContentLinks(searchParams);
                    const contentLinks = get(contentLinksResponseData, 'content-links', []);
                    const contentLinksMessage = get(contentLinksResponseData, 'message', '');

                    if (contentLinksMessage.trim() === ANY_RECORDS) {
                        historyChat[positionChat].additionalResourcesFailed = historyChat[
                            positionChat
                        ].additionalResourcesFailed.concat([{ id: contentLinkId }]);
                    } else {
                        contentLinks[0].count = value;
                        historyChat[positionChat].additionalResources = historyChat[
                            positionChat
                        ].additionalResources.concat(contentLinks);
                    }
                } catch (e) {
                    return Promise.reject(e);
                }
            }
        }
    }

    return historyChat;
};

export const getContentLinks = async (
    historyChat,
    contentLinkIds,
    positionChat,
    fetchContentLinks
) => {
    for (const [key, value] of Object.entries(contentLinkIds)) {
        const contentLinkId = key;
        const searchParams = {
            search: contentLinkId,
            limit: 1,
        };

        try {
            // eslint-disable-next-line no-await-in-loop
            const contentLinksResponseData = await fetchContentLinks(searchParams);
            const contentLinks = get(contentLinksResponseData, 'content-links', []);
            const contentLinksMessage = get(contentLinksResponseData, 'message', '');

            if (contentLinksMessage.trim() === ANY_RECORDS) {
                historyChat[positionChat].additionalResourcesFailed = historyChat[
                    positionChat
                ].additionalResourcesFailed.concat([{ id: contentLinkId }]);
            } else {
                contentLinks[0].count = value;
                historyChat[positionChat].additionalResources = historyChat[
                    positionChat
                ].additionalResources.concat(contentLinks);
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }

    return historyChat;
};

export const insertAnswerError = (historyChat, positionChat) => {
    const lastItem = historyChat[positionChat];
    let historyChatError = historyChat;

    if (lastItem && lastItem.chatStatus === STATUS_OPEN) {
        historyChatError = historyChat.map((item, index) =>
            index === positionChat ? { ...item, chatStatus: STATUS_ERROR } : item
        );
    }

    return historyChatError;
};

export const verifyTimeoutEnd = (historyChat, lastCallTime, positionChat) => {
    const currentMessage = historyChat[positionChat];
    const chatStatus = get(currentMessage, 'chatStatus', null);
    const currentTime = Date.now();
    const elapsedTime = currentTime - lastCallTime;

    return elapsedTime >= AI_TIMEOUT_MILLISECONDS && chatStatus !== STATUS_COMPLETE;
};

export const removeChatSessionId = () => {
    localStorage.removeItem('ai_chat_session_active');
};

export const updatedChatTabs = (event, selectedState) => {
    let selectedStateCurrent = selectedState;
    let updatedHistoryChat = [];

    if (event.newValue) {
        updatedHistoryChat = JSON.parse(event.newValue);
        selectedStateCurrent = updatedHistoryChat[0]?.selectedState || selectedStateCurrent;
    } else {
        const historyChat = JSON.parse(event.oldValue);
        updatedHistoryChat = [
            ...historyChat,
            {
                previousSelectedState: selectedState,
                endConversation: true,
            },
        ];
        selectedStateCurrent = null;
    }

    return [updatedHistoryChat, selectedStateCurrent];
};

export const saveStatusLoadingInLocalStorage = status => {
    localStorage.setItem('ai_chat_answer_loading', status);
};

export const cleanSafetyLocalStorage = () => {
    const keysToRemove = ['guideName', 'safetyCategory', 'safetyTopic', 'safetyContent', 'source'];

    keysToRemove.forEach(key => {
        window.localStorage.removeItem(key);
    });
};

export const setSafetyLocalStorage = data => {
    if (isEmpty(data)) {
        return false;
    }

    Object.keys(data).forEach(key => {
        window.localStorage.setItem(key, data[key]);
    });

    return true;
};

export const convertStringToBoolean = input => {
    if (typeof input === 'boolean') {
        return input;
    }

    if (typeof input === 'string') {
        const trueValues = ['true', '1', 'yes', 'y'];
        const falseValues = ['false', '0', 'no', 'n'];
        const lowerStr = input.toLowerCase();

        if (trueValues.includes(lowerStr)) return true;
        if (falseValues.includes(lowerStr)) return false;
    }

    return false;
};

export const setPreviousSafetyLocalStorage = () => {
    const safetyKeys = ['guideName', 'safetyCategory', 'safetyTopic', 'safetyContent', 'source'];

    const previousSafetyContent = {};
    safetyKeys.forEach(key => {
        if (!isEmpty(window.localStorage.getItem(key)))
            previousSafetyContent[key] = window.localStorage.getItem(key);
    });
    if (window.localStorage.getItem('source'))
        localStorage.setItem('previousSafetyContent', JSON.stringify(previousSafetyContent));
};

export const removePreviousSafetyLocalStorage = () => {
    window.localStorage.removeItem('previousSafetyContent');
};

export function capitalizeFirstLetters(inputStr) {
    return typeof inputStr === 'string'
        ? inputStr.replace(/\b\w/g, char => char.toUpperCase())
        : '';
}

export const handleVergigChatClick = async () => {
    const button = document.getElementById('openHelpCenterWidget');

    if (button) {
        button.click();

        await new Promise(resolve => setTimeout(resolve, 1000));

        const popupButton = document.getElementById('vergigchat');
        if (popupButton) {
            popupButton.click();
        }
    }
};

export const serializeParams = params => {
    return Object.entries(params)
        .filter(([_, value]) => value !== null && value !== undefined)
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return value
                    .map(item => `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`)
                    .join('&');
            } else if (typeof value === 'object') {
                return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`;
            }

            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join('&');
};
