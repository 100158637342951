import { getEnvVar } from 'utils/env';

/**
 * Extract the main API origin for the application from the env.
 *
 * @type {string}
 */
export const API_ORIGIN = getEnvVar('THR_JAPI_URL', MAESTER.thr.japiUrl);

/**
 * EC SSO url to open in iFrame
 *
 * @type {string}
 */
export const EC_SSO_ORIGIN = getEnvVar('EC_SSO_URL', MAESTER.thr.ecSsoUrl);

/**
 * Extract the zend url from the env.
 *
 * @type {string}
 */
export const ZEND_ORIGIN = getEnvVar('THR_ZEND_URL', MAESTER.thr.zendUrl);

/**
 * Extract the api tools url from the env.
 *
 * @type {string}
 */
export const API_TOOLS_ORIGIN = getEnvVar('API_TOOLS_URL', MAESTER.thr.apiToolsUrl);

/**
 * Extract the tickets url from the env.
 *
 * @type {string}
 */
export const TICKETS_ORIGIN = getEnvVar('TICKETING_URL', MAESTER.thr.ticketingUrl);

/**
 * Extract the webhook url from the env.
 *
 * @type {string}
 */
export const HOOK_ORIGIN = getEnvVar('HOOK_URL', MAESTER.thr.hookUrl);

/**
 * Extract the Handbook Express url from the env.
 *
 * @type {string}
 */
export const HANDBOOKS_EXPRESS_ORIGIN = getEnvVar(
    'HANDBOOKS_EXPRESS_URL',
    MAESTER.thr.handbooksExpressUrl
);

/**
 * Partner Enablement logout url
 *
 * @type {string}
 */
export const PARTNER_ENABLEMENT_LOGOUT_URL = getEnvVar(
    'PARTNER_ENABLEMENT_SSO_LOGOUT_URL',
    MAESTER.thr.partnerEnablementSsoLogoutUrl
);

/**
 * Partner Enablement SSO url to open in iFrame
 *
 * @type {string}
 */
export const PARTNER_ENABLEMENT_SSO_ORIGIN = getEnvVar(
    'PARTNER_ENABLEMENT_SSO_URL',
    MAESTER.thr.partnerEnablementSsoUrl
);

/**
 * Partner Enablement SSO url to open in iFrame
 *
 * @type {string}
 */
export const IMPARTNER_SSO_ORIGIN = getEnvVar('IMPARTNER_SSO_URL', MAESTER.thr.impartnerSsoUrl);

/**
 * Extract the static json url from the env.
 *
 * @type {string}
 */
export const STATIC_JSON_ORIGIN = getEnvVar('STATIC_JSON_URL', MAESTER.thr.staticJsonUrl);

/**
 * Extract the surveys url from the env.
 *
 * @type {string}
 */
export const SURVEYS_ORIGIN = getEnvVar('SURVEYS_URL', MAESTER.thr.surveysUrl);

/**
 * JAPI params
 *
 * @type {string}
 */
export const JAPI_VARIABLES = {
    COMPANY_TYPE: {
        PARTNER: 'partners',
        CLIENT: 'companies',
    },
};

/**
 * Maximum time in ms to wait for the vendor logout iframe to load
 *
 * @type {string}
 */
export const VENDER_LOGOUT_IFRAME_LOAD_TIME_MAX = 3000;

/**
 * Downtime warning file url.
 *
 * @type {string}
 */
export const DOWNTIME_WARNING_JSON_URL = getEnvVar(
    'MNL_DOWNTIME_WARNING_URL',
    MAESTER.thr.mnlDowntimeWarningUrl
);

/**
 * Feature flag to enable support for Oasis
 *
 * @type {string}
 */
export const ENABLE_OASIS = getEnvVar('ENABLE_OASIS', MAESTER.thr.enableOasis);

/**
 * Pendo API key
 *
 * @type {string}
 */
export const PENDO_API_KEY = getEnvVar('PENDO_API_KEY', MAESTER.thr.pendoApiKey);

/**
 * Pendo Vergic group key
 *
 * @type {string}
 */
export const VERGIC_GROUP_KEY = getEnvVar('VERGIC_GROUP_KEY', MAESTER.thr.vergicGroupKey);

/**
 * Handbook express URL
 *
 * @type {string}
 */
export const HANDBOOKS_EXPRESS_URL = getEnvVar(
    'HANDBOOKS_EXPRESS_URL',
    MAESTER.thr.handbooksExpressUrl
);

/**
 * Document creator URL
 *
 * @type {string}
 */
export const BENEFITS_DOCUMENT_CREATOR_URL = getEnvVar(
    'BENEFITS_DOCUMENT_CREATOR_URL',
    MAESTER.thr.benefitsDocumentCreatorUrl
);

/**
 * Salary URL
 *
 * @type {string}
 */
export const SALARY_URL = getEnvVar('SALARY_URL', MAESTER.thr.salaryUrl);

/**
 * THR client ID
 *
 * @type {string}
 */
export const THR_CLIENT_ID = getEnvVar('THR_CLIENT_ID', MAESTER.thr.clientId);

/**
 * IMPORT_THRESHOLD
 *
 * @type {string}
 */
export const IMPORT_THRESHOLD = getEnvVar('IMPORT_THRESHOLD', 120);

/**
 * Learn url
 *
 * @type {string}
 */
export const THR_LEARN_URL = getEnvVar('THR_LEARN_URL', MAESTER.thr.learnUrl);

/**
 * Auth url
 *
 * @type {string}
 */
export const MNL_AUTH_URL = getEnvVar('MNL_AUTH_URL', MAESTER.thr.authUrl);

/**
 * Auth url
 *
 * @type {string}
 */
export const MNL_IDLE_SESSION_TIMEOUT = getEnvVar(
    'MNL_IDLE_SESSION_TIMEOUT',
    MAESTER.thr.mnlIdleSessionTimeout
);

/**
 * Footer logo url
 *
 * @type {string}
 */
export const FOOTER_LOGO_URL = `${getEnvVar(
    'STATIC_JSON_URL',
    MAESTER.thr.staticJsonUrl
)}/assets/MIN-PrimaryLogo-TM-White-RGB-01.png`;

/**
 * Hello Sign Client ID
 *
 * @type {string}
 */
export const HELLO_SIGN_CLIENT_ID = getEnvVar('HELLO_SIGN_CLIENT_ID', MAESTER.HelloSign.clientId);

/**
 * Paychex RE user handbook guide
 *
 * @type {string}
 */
export const EMPLOYEE_HANDBOOK_ID = getEnvVar(
    'EMPLOYEE_HANDBOOK_ID',
    MAESTER.thr.employeeHandbookGuide
);

/**
 * Paychex Admin and Regular user handbook guide
 *
 * @type {string}
 */
export const ADMIN_HANDBOOK_ID = getEnvVar('ADMIN_HANDBOOK_ID', MAESTER.thr.adminUserHandbookGuide);

/**
 * SPELLCHECKER ID TO HANDBOOK EDITOR
 *
 * @type {string}
 */
export const HANDBOOK_EDITOR_SPELLCHECK_ID = getEnvVar(
    'HANDBOOK_EDITOR_SPELLCHECK_ID',
    MAESTER.thr.handbookEditorSpellcheckId
);

/**
 * FROALA ID TO HANDBOOK EDITOR
 *
 * @type {string}
 */
export const HANDBOOK_EDITOR_FROALA_ID = getEnvVar(
    'HANDBOOK_EDITOR_FROALA_ID',
    MAESTER.thr.handbookEditorFroalaId
);

/**
 * Extract the ai assistant url from the env.
 *
 * @type {string}
 */
export const AI_ASSISTANT_URL = getEnvVar('AI_ASSISTANT_URL', MAESTER.thr.aiAssistantUrl);

/**
 * Polling Interval Milliseconds for AI.
 *
 * @type {string}
 */
export const AI_POLLING_INTERVAL_MILLISECONDS = getEnvVar(
    'AI_POLLING_INTERVAL_MILLISECONDS',
    MAESTER.thr.aiPollingIntervalMilliseconds
);

/**
 * Timeout Milliseconds for AI.
 *
 * @type {string}
 */
export const AI_TIMEOUT_MILLISECONDS = getEnvVar(
    'AI_TIMEOUT_MILLISECONDS',
    MAESTER.thr.aiTimeoutMilliseconds
);

/**
 * Feature flag to enable AI Debugging.
 *
 * @type {string}
 */
export const AI_DEBUG_ENABLED = getEnvVar('AI_DEBUG_ENABLED', MAESTER.thr.aiDebugEnabled);

/**
 * Feature flag to enable AI Maintenance.
 *
 * @type {boolean}
 */
export const AI_DIGITAL_ASSISTANT_ENABLED = getEnvVar(
    'AI_DIGITAL_ASSISTANT_ENABLED',
    MAESTER.thr.aiDigitalAssistantEnabled
);

/**
 * Feature flag to enable MSA SignIn Override.
 *
 * @type {boolean}
 */
export const MSA_SIGN_IN_OVERRIDE_ENABLED = getEnvVar(
    'MSA_SIGN_IN_OVERRIDE_ENABLED',
    MAESTER.thr.msaSignInOverrideEnabled
);

/**
 * AI Privacy Policy url
 *
 * @type {string}
 */
export const AI_PRIVACY_POLICY_URL = getEnvVar(
    'AI_PRIVACY_POLICY_URL',
    MAESTER.thr.aiPrivacyPolicyUrl
);

export const HR_LOGICS_REDIRECT_URL = getEnvVar(
    'HR_LOGICS_REDIRECT_URL',
    MAESTER.thr.hrLogicsRedirectUrl
);
