export const MODIFICATION_VALUE_TITLE = 'MODIFY CALCULATION VALUES';
export const CLIENT_USAGE_TITLES = {
    MAIN_DESCRIPTION:
        'View a snapshot of your user’s activity, see recent HR compliance library questions asked, and view the top five clients using Mineral Experts™️.',
    COMPLY_VIEWS: 'HR Compliance Library Views',
    QUESTIONS_BY_CATEGORY: 'Questions by Category',
    RECENT_QUESTIONS: 'Most Recent Questions Asked by Clients',
    USAGE_SNAPSHOT: 'Usage Snapshot',
    TOP_5_CLIENTS: 'Top 5 clients using Mineral Experts™️',
};
export const GENERATE_REPORT_TITLE = 'Generate Report';
export const GENERATED_REPORT_TITLE = 'Report Parameters Summary';
export const GENERATED_REPORT_MESSAGE =
    'Your pdf is being generated. You will receive an email shortly with a link to the pdf.';
export const PVR_DESCRIPTION =
    'View a snapshot of your users’ activity, see recent HR compliance library questions asked, and learn which clients are the top Mineral Experts™️ users.';
export const CLIENT_VALUE_REPORT_TITLES = {
    MAIN: 'Client Value Report',
    COMPANY_USAGE: 'Company Usage',
    LIVE_CONSULTATION_CATEGORIES: 'Mineral Experts™️ Consultation Categories',
    TOP_TRAINING_USERS: 'Top Training Users',
    TOP_COURSES: 'TOP COURSES',
    TOP_COMPLY_USERS: 'Top HR Compliance Library Users',
    TOP_COMPLY_USERS_BY_VIEW: 'Top HR Compliance Library Users By Views',
    TOP_COMPLY_SECTIONS_ACTIVITY: 'Top HR Compliance Library Sections Activity',
    LIVING_HANDBOOKS: 'Smart Employee Handbooks',
    BENEFITS_DOCUMENT_CREATOR: 'Benefits Document Creator',
    TOP_BENEFITS_COMPLIANCE_SUITE: 'Top Benefits HR Compliance Library Suite',
};
export const TOTAL_COMPANY_USAGE = 'TOTAL COMPANY USAGE';
export const CLIENT_VALUE_REPORT_TYPICAL_TITLES = {
    ISSUES: 'TYPICAL CASE CATEGORIES',
    TRAINING: 'TOP COURSES TAKEN BY TYPICAL COMPANIES',
    COMPLIANCE: 'Top HR Compliance Library Sections Viewed By Typical Users',
};
export const TOTAL_VALUE_SERVICES = 'TOTAL VALUE OF SERVICES: ';
export const DOWNLOAD_REPORT_LABEL = 'DOWNLOAD REPORT';
export const RUN = 'RUN';
export const TYPICAL_ISSUE_CATEGORIES = [
    { label: 'Termination', value: 100 },
    { label: 'Customer Success and Support', value: 200 },
    { label: 'Employee Benefits', value: 100 },
    { label: 'Leaves of Absence', value: 250 },
    { label: 'Safety, Privacy and Security', value: 50 },
    { label: 'Recruiting & Hiring', value: 70 },
    { label: 'Employee Management', value: 150 },
];
export const TOP_COURSES_TAKEN_BY_TYPICAL_COMPANIES = [
    { label: 'Noncommercial Defensive Driving', value: 100 },
    { label: 'Sexual Harassment-What Employees Need to Know', value: 200 },
    { label: 'Sexual Harassment-What Supervisors Need to Know', value: 100 },
    { label: 'Blood Borne Pathogens', value: 250 },
    { label: 'HIPAA-For Employees', value: 50 },
];
export const TOP_COMPLY_SECTIONS_VIEWED_BY_TYPICAL_USERS = [
    { label: 'Smart Employee Handbooks', value: 100 },
    { label: 'Wage & Hour', value: 200 },
    { label: 'Safety & Health', value: 100 },
    { label: 'Q&A', value: 250 },
    { label: 'Forms & Sample Policies', value: 50 },
];

export const ERROR_DATE_RANGE =
    'Please select a timeframe period that is less than NUM_YEARS year(s)';
export const MIN_DATE_REPORT = new Date('01-01-2010');

export const TELL_US_REPORT = {
    MAIN: 'Anonymous Reporting',
    RUN_REPORT_LABEL: 'RUN',
    DOWNLOAD_REPORT_LABEL: 'DOWNLOAD REPORT',
};
export const MODIFY_VALUES_MESSAGE =
    'Note: Changes to the calculation values will become the new values applied for ALL future Partner Value Reports generated across ALL partners until the values are updated again.';
export const BUTTONS_DROPDOWN_LABEL = {
    SELECT_ALL: 'Select All',
    DESELECT_ALL: 'Deselect All',
};
export const TOTAL_LABELS = {
    USERS: 'TOTAL USERS',
    COURSES: 'TOTAL COURSE',
};
export const ACTIVE_CLIENT_USERS_EXPORT_MESSAGE =
    'A link to download the report will be emailed to you.';
export const ACTIVE_CLIENT_USERS = {
    MAIN: 'Administration Reports',
    DESCRIPTION: 'Download a list of Clients, Users, or Locations.',
    TYPES: ['Clients', 'Users', 'Locations'],
    CLEAR: 'Clear',
    RUN_REPORT_LABEL: 'Run Report',
    CLIENT_TOTALS: 'Client Totals',
    SUCCESS_MESSAGE:
        'A spreadsheet is being generated with your data. You will receive an email shortly with a link to the spreadsheet.',
    TOTAL_CLIENTS_CONTRACTED: 'Total Clients Contracted: ',
    TOTAL_CLIENTS_ACTIVATED: 'Total Clients Activated: ',
};
export const EMAIL_ENGAGEMENT = {
    BOUNCED: 'Bounced',
    BOUNCED_TOOLTIP: 'Total emails successfully bounced',
    DELIVERED: 'Delivered',
    DELIVERED_TOOLTIP: 'Total emails successfully delivered',
    DESCRIPTION:
        "View your user's engagement with emails sent from the Mineral Platform for the last 30 days.",
    DESCRIPTION_1:
        'This report does not count emails that were filtered out by receiving server spam filters.',
    DESCRIPTION_2: 'Data collection for this report started on November 3, 2022.',
    MAIN: 'Communications Report',
    OPENED: 'Opened',
    OPENED_TOOLTIP: 'Total emails successfully opened',
    SEND: 'Sent',
    SEND_TOOLTIP: 'Total emails successfully sent',
    TITLE_CHART: 'Emails Sent by Type',
    TYPES: ['sent', 'delivered', 'opened', 'bounced'],
    TOOLTIPS: [
        'Total number of emails Sent is the sum of emails Delivered, Opened, Clicked, Bounced, and Dropped.',
        'Total number of emails successfully sent and accepted by the receiving server. This number includes the status of Opened and Clicked.',
        'Total number of emails opened by the recipient. This number includes the status of Clicked.',
        'Total number of emails rejected by the receiving server. This includes the statuses of Bounced and Dropped.',
    ],
    DOWNLOAD_MESSAGE:
        'Your report is being generated. You will receive an email shortly with a link to the report.',
};
export const CLIENT_SOLUTION_REPORT = {
    TITLE: 'Client Solution Report',
    SELECT_PARTNER: 'Select a Partner',
    SUB_TITLE:
        "Generate a snapshot of today's current data or enter a specific date range to narrow your search.",
    NO_RESULT_FOUND: 'There are no results matching your search criteria',
    BUTTONS: {
        USE_DATE_RANGE: 'Use Date Range',
        TODAY_SNAPSHOT: "Today's Snapshot",
        DOWNLOAD: 'Download',
        GENERATE_TODAY_SNAPSHOT: 'Generate Today’s Snapshot',
    },
    NOTE: 'Genereate Report From:',
    TOOLTIP: {
        DOWNLOAD: 'Download CSV',
    },
    SEARCH: {
        TITLE:
            'Generate the most up-to-date Client Solutions Report. You’ll receive an email when your report is generated.',
        SUB_TITLE: 'Select a Partner',
        PLACEHOLDER: 'Search a Partner',
        ERROR_MESSAGE:
            'Please select a partner who is on a Mineral contract, otherwise the report will be blank.',
    },
    ACTION_BAR: {
        TITLE: 'Generate a Current Snapshot',
        SUB_TITLE:
            'Generate the most up-to-date Client Solutions Report. You’ll receive an email when your report is generated. ',
    },
    FILTER: {
        TITLE_SUFFIX: 'Your ',
        TITLE: '${partnerName}Report Archive',
        LABEL: 'Filter Reports',
        CLEAR: 'Clear',
    },
    REPORT_LIST: {
        REPORT_NAME_SUFFIX: 'Report from',
        DEFAULT_TITLE: 'Reports by Date',
        PARTNER_TITLE: 'Your Reports by Date',
        INTERNAL_TITLE: '${partnerName} Reports by Date',
        NO_PARTNER_SELECTED: 'No Partner Selected',
        PARTNER_NO_REPORT_TITLE: 'Please select a date range to view reports',
        INTERNAL_NO_REPORT_TITLE: 'Please select a date range to view reports',
        NO_REPORT_FOR_SELECTED_DATE: 'There are no reports to display for the selected date range.',
        LOADING: 'Loading..',
    },
    CONFIRMATION_MODAL: {
        TITLE: 'Client Solution Report',
        SUBJECT: 'Check Your Email...',
        BODY:
            'We’ve emailed you a link to your report for ${partnerName}. It may take a few minutes to process.',
    },
    INTUIT_PARTNER_NAME: 'Intuit QuickBooks HR',
};

export const MAP_SKU_KEY = {
    SKU_KEY: 'mineral_advantage_program',
};

export const RESULTS_MESSAGE = 'Results include activity of both active and inactive users.';

export const CLIENT_VALUE_REPORT_ADMIN_SCHEMA = {
    required: ['selectedPartner', 'selectedClient', 'from', 'until'],
    schema: {
        selectedPartner: {
            title: 'Select Partner',
            type: 'object',
        },
        hiddenField: {
            title: '',
            type: 'string',
        },
    },
    uiSchema: {
        hiddenField: {
            'ui:widget': 'hidden',
            'ui:options': {
                col: {
                    size: {
                        md: 6,
                        xs: 12,
                    },
                },
                label: false,
            },
        },
        selectedPartner: {
            'ui:field': 'TypeaheadField',
            'ui:options': {
                isClearable: true,
                closeMenuOnSelect: true,
                placeholder: 'Search and select by Partner Name',
                fetchData: 'fetchPvrPartners',
                col: {
                    size: {
                        md: 6,
                        xs: 12,
                    },
                },
                shouldReset: true,
            },
        },
    },
};
