import _get from 'lodash/get';

import { createReducer } from 'utils/reducer';
import { createSelector } from 'reselect';
import * as uiActions from './actions';

import * as helpers from './reducerHelpers';
import * as authActions from '../../auth/actions';

const INITIAL_STATE = {
    isHybridSsoEnabled: false,
    isAcaAdmin: false,
    hasAccessToHandbookMoveTool: false,
    isImpartnerEnabled: false,
    isMultiYearSupportEnabled: false,
};

const featureFlags = {
    [authActions.logout.SUCCESS]: helpers.logoutSuccess,
    [uiActions.setFeatureFlag.SUCCESS]: helpers.setFeatureFlagSuccess,
};

export default createReducer(INITIAL_STATE, featureFlags);

/**
 * Get state of the FeatureFlags store
 *
 * @method getFeatureFlag
 * @param  {Object}      state The current state
 * @return {Object}            The Feature Flags state
 */
export const getFeatureFlags = state => _get(state, 'ui.featureFlags', {});

/**
 * Get the isAdministrationOptionEnabled status
 *
 * @method isAdministrationOptionEnabled
 * @param  {Object}     state
 * @return {boolean}
 */
export const isAdministrationOptionEnabled = createSelector([getFeatureFlags], featureFlag =>
    _get(featureFlag, 'isAdministrationOptionEnabled', false)
);

/**
 * Get the isHybridSsoEnabled status
 *
 * @method isHybridSsoEnabled
 * @param  {Object}     state
 * @return {boolean}
 */
export const isHybridSsoEnabled = createSelector([getFeatureFlags], featureFlag =>
    _get(featureFlag, 'isHybridSsoEnabled', false)
);

/**
 * Get the isAcaAdmin status
 *
 * @method isAcaAdmin
 * @param  {Object}     state
 * @return {boolean}
 */
export const isAcaAdmin = createSelector([getFeatureFlags], featureFlag =>
    _get(featureFlag, 'isAcaAdmin', false)
);

/**
 * Get the hasAccessToHandbookMoveTool status
 *
 * @method hasAccessToHandbookMoveTool
 * @param  {Object}     state
 * @return {boolean}
 */
export const hasAccessToHandbookMoveTool = createSelector([getFeatureFlags], featureFlag =>
    _get(featureFlag, 'hasAccessToHandbookMoveTool', false)
);

/**
 * Get the isImpartnerEnabled status
 *
 * @method isImpartnerEnabled
 * @param  {Object}     state
 * @return {boolean}
 */
export const isImpartnerEnabled = createSelector([getFeatureFlags], featureFlag =>
    _get(featureFlag, 'isImpartnerEnabled', false)
);

/**
 * Get the isMultiYearSupportEnabled status
 *
 * @method isMultiYearSupportEnabled
 * @param  {Object}     state
 * @return {boolean}
 */
export const isMultiYearSupportEnabled = createSelector([getFeatureFlags], featureFlag =>
    _get(featureFlag, 'isMultiYearSupportEnabled', false)
);
