import axios from 'axios';
import Qs from 'qs';

import companyStatuses from 'static/config/companyStatuses.json';

/**
 * API call to fetch info about the given company, via `companyId`.
 *
 * @method fetchCompanyByIdAPI
 * @param  {Object}             companyId The company to fetch
 * @return {Promise}
 */
export const fetchCompanyByIdAPI = companyId => {
    return axios({
        method: 'get',
        url: `/v2/partners/${companyId}`,
    });
};

/**
 * API call to fetch a company. This API will not fetch a partner.
 *
 * @method fetchCompanyAPI
 * @param  {Object}             companyId The company to fetch
 * @return {Promise}
 */
export const fetchCompanyAPI = companyId => {
    return axios({
        method: 'get',
        url: `/v2/companies/${companyId}`,
    });
};

/**
 * API call to fetch company locations
 *
 * @method fetchCompanyLocations
 * @param  {string}             companyId
 * @param  {string}             type       type of location
 * @return {Promise}
 */
export const fetchCompanyLocations = (companyId, type = '') =>
    axios({
        method: 'get',
        url: `/v2/companies/${companyId}/location/${type}`,
    });

/**
 * API call create a company for any partner.
 *
 * @method createCompanyAPI
 * @param  {number|string}      partnerId
 * @param  {Object}             data The company info
 * @return {Promise}
 */
export const createCompanyAPI = (partnerId, data) =>
    axios({
        method: 'post',
        url: `/v2/companies?partnerId=${partnerId}`,
        data,
    });

/**
 * API to update a company.
 *
 * @method updateCompanyAPI
 * @param  {Object}             companyInfo The company info
 * @return {Promise}
 */
export const updateCompanyAPI = companyInfo => {
    const { partnerId, companyId } = companyInfo;
    const type = partnerId === companyId ? 'partners' : 'companies';
    return axios({
        method: 'put',
        url: `/v2/${type}/${companyInfo.companyId}`,
        data: companyInfo,
    });
};

/**
 * API call to deactivate/active a company.
 *
 * @method changeCompanyStatusAPI
 * @param  {Object}         companyInfo The current user's information to be updated
 * @return {Promise}
 */
export const changeCompanyStatusAPI = companyInfo => {
    const { companyId, isActive, isPartner } = companyInfo;

    return axios({
        method: 'patch',
        url: isPartner ? `/v2/partners/${companyId}` : `/v2/companies/${companyId}`,
        data: {
            isActive,
        },
    });
};

/**
 * API call to fetch headers for the given company, via `companyId`.
 *
 * @method fetchCompanyHeadersAPI
 * @param  {Object}             companyId The company to fetch
 * @return {Promise}
 */
export const fetchCompanyHeadersAPI = companyId => {
    return axios({
        method: 'get',
        url: `/v2/companies/${companyId}`,
    });
};

/**
 * API call to fetch info about the naics codes
 *
 * @method fetchNaicsCodesAPI
 * @return {Promise}
 */
export const fetchNaicsCodesAPI = () => {
    return axios({
        method: 'get',
        url: `/v2/lookup-types?categoryName=naics-code`,
    });
};

/**
 * API call to validate zip code
 *
 * @method fetchZipCodeValidateAPI
 * @param  {string}             zipcode The zip code to validate
 * @return {Promise}
 */
export const fetchZipCodeValidateAPI = zipcode => {
    return axios({
        method: 'get',
        url: `/v2/ziplookup?zipcode=${zipcode}`,
    });
};

/**
 * API call to get industry types
 *
 * @method fetchIndustryTypesAPI
 * @return {Promise}
 */
export const fetchIndustryTypesAPI = () => {
    return axios({
        method: 'get',
        url: `/v2/lookup-types?categoryName=industry`,
    });
};

/**
 * API to fetch email settings.
 *
 * @method fetchWelcomeEmailSettingsAPI
 * @param  {Object}             partnerInfo The email configuration info
 * @return {Promise}
 */
export const fetchWelcomeEmailSettingsAPI = partnerInfo => {
    const { partnerId } = partnerInfo;
    return axios({
        method: 'get',
        url: `/v2/partners/${partnerId}/email-templates?type=welcome`,
        data: partnerInfo,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API to update email settings.
 *
 * @method updateWelcomeEmailSettingsAPI
 * @param  {Object}             emailConfigurationInfo The email configuration info
 * @return {Promise}
 */
export const updateWelcomeEmailSettingsAPI = emailConfigurationInfo => {
    const { partnerId } = emailConfigurationInfo;
    return axios({
        method: 'patch',
        url: `/v2/partners/${partnerId}/email-templates?type=welcome`,
        data: emailConfigurationInfo,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API to fetch all locations by company
 *
 * @method fetchAllLocationsForCompanyAPI
 * @param   {string}    type
 * @param   {number}    companyId
 * @param   {Object}    params
 * @return  {Promise}
 */
export const fetchAllLocationsForCompanyAPI = (type, companyId, params) => {
    return axios({
        method: 'get',
        url: `/v2/${type}/${companyId}/locations`,
        params,
    });
};

/**
 * API to fetch employee counts by state
 *
 * @method fetchEmployeeCountsByStateAPI
 * @param   {number}    companyId
 * @param   {Object}    params
 * @return  {Promise}
 */
export const fetchEmployeeCountsByStateAPI = (companyId, params) => {
    return axios({
        method: 'get',
        url: `/v2/companies/${companyId}/locations/employeeCount/state`,
        params,
    });
};

/**
 * API to fetch clients from partner
 *
 * @method fetchPartnerClientsAPI
 * @param  {Object}             partnerInfo The partner info
 * @return {Promise}
 */
export const fetchPartnerClientsAPI = partnerInfo => {
    const { partnerId } = partnerInfo;
    return axios({
        method: 'get',
        url: `/v2/companies?partnerId=${partnerId}`,
        params: partnerInfo,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API to fetch all configurations from partner
 *
 * @method fetchAllConfigurationsForPartnerAPI
 * @param  {Object}             partnerInfo The partner info
 * @return {Promise}
 */
export const fetchAllConfigurationsForPartnerAPI = partnerInfo => {
    const { partnerId } = partnerInfo;
    partnerInfo.partnerId = undefined;
    return axios({
        method: 'get',
        url: `/v2/configurations?partnerId=${partnerId}&configurationType=master&configurationType=alternate`,
        params: partnerInfo,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API to validate bulk update company configuration
 *
 * @method validateBulkUpdateCompanyConfigurationsAPI
 * @param  {Object}             partnerInfo The partner info
 * @return {Promise}
 */
export const validateBulkUpdateCompanyConfigurationsAPI = partnerInfo => {
    const { allCompanies, partnerId, validateOnly } = partnerInfo;
    let validate = '';
    let methodType = 'post';

    if (validateOnly) {
        validate = '&validateOnly=true';
    } else {
        partnerInfo.validateOnly = undefined;
        methodType = 'patch';
    }

    return axios({
        method: methodType,
        url: `/v2/companies?allCompanies=${allCompanies ||
            false}&partnerId=${partnerId}${validate}`,
        data: partnerInfo,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API to fetch PrismHR configuration from partner.
 *
 * @method fetchPrismHRConfigurationAPI
 * @param  {Object}  partnerId The partner id
 * @return {Promise}
 */
export const fetchPrismHRConfigurationAPI = partnerId => {
    return axios({
        method: 'get',
        url: `/v2/ext-connector-config?partnerId=${partnerId}`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API to fetch PrismHR configuration from partner.
 *
 * @method fetchPrismHRConfigurationAPI
 * @param  {Object}  configurationInfo Configuration info
 * @return {Promise}
 */
export const updatePrismHRConfigurationAPI = configurationInfo => {
    const { partnerId, id, emailRecipients } = configurationInfo;
    configurationInfo.partnerId = undefined;
    configurationInfo.emailRecipients = emailRecipients || '';
    const reqBody = {
        headers: {
            'Content-Type': 'application/json',
        },
        data: configurationInfo,
    };

    if (id) {
        return axios({
            ...reqBody,
            method: 'put',
            url: `/v2/ext-connector-config/${id}?partnerId=${partnerId}`,
        });
    }

    return axios({
        ...reqBody,
        method: 'post',
        url: `/v2/ext-connector-config?partnerId=${partnerId}`,
    });
};

/**
 * API to fetch PrismHR Integration operation center data.
 *
 * @method fetchPrismHRIntegrationDataAPI
 * @return {Promise}
 */
export const fetchPrismHRIntegrationDataAPI = () => {
    return axios({
        method: 'get',
        url: `/v2/partners/externalPartnerConfig?isActive=true`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API to reset prism hr integration sync status
 *
 * @method resetPrismHRSyncStatusAPI
 * @param  {string}  syncName
 * @param  {string}  peoId
 * @return {Promise}
 */
export const resetPrismHRSyncStatusAPI = ({ syncName, peoId }) => {
    return axios({
        method: 'post',
        url: `/v2/prismhr/monitor/resetSyncMonitor/${syncName}?extPartnerId=${peoId}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            last_sync_status: 'NOT STARTED',
            last_sync_fail_count: 0,
        },
    });
};

/**
 * API to recreate prism hr subscription.
 *
 * @method recreatePrismHRSubscriptionAPI
 * @param  {boolean}  forceUpdate
 * @param  {string}  peoId
 * @return {Promise}
 */
export const recreatePrismHRSubscriptionAPI = ({ forceUpdate, peoId }) => {
    return axios({
        method: 'post',
        url: `/v2/prismhr/subscription?forceUpdate=${forceUpdate}&extPartnerId=${peoId}`,
        data: {},
    });
};

/**
 * API to fetch csv template from partner.
 *
 * @method fetchCsvPartnerTemplateAPI
 * @param  {Object}         partnerId
 * @return {Promise}
 */
export const fetchCsvPartnerTemplateAPI = partnerId => {
    return axios({
        method: 'get',
        url: `/v2/companies/csv?type=template&partnerId=${partnerId}`,
    });
};

/**
 * API call to import clients.
 *
 * @method importClientsAPI
 * @param  {Object}         importInfo
 * @return {Promise}
 */
export const importClientsAPI = importInfo => {
    const { partnerId, file } = importInfo;
    return axios({
        method: 'post',
        url: `/v2/companies/bulk-async?partnerId=${partnerId}`,
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

/**
 * API to fetch clients
 *
 * @method fetchClientsAPI
 * @param  {Object}             data
 * @return {Promise}
 */
export const fetchClientsAPI = data => {
    return axios({
        method: 'get',
        url: '/v2/companies',
        params: data,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });
};

/**
 * API to fetch partners
 *
 * @method fetchPartnersAPI
 * @param  {Object}             data
 * @return {Promise}
 */
export const fetchPartnersAPI = data => {
    return axios({
        method: 'get',
        url: '/v2/partners',
        params: data,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });
};

/**
 * API to update partner licensing
 *
 * @method updatePartnerLicensingAPI
 * @param  {Object}  licensingInfo
 * @return {Promise}
 */
export const updatePartnerLicensingAPI = licensingInfo => {
    const { partnerId } = licensingInfo;
    licensingInfo.partnerId = undefined;
    return axios({
        method: 'patch',
        url: `/v2/partner-settings/${partnerId}`,
        data: licensingInfo,
    });
};

/**
 * API to update partner licensing
 *
 * @method generateAuthenticationCodeAPI
 * @param  {Object}  partnerId
 * @return {Promise}
 */
export const generateAuthenticationCodeAPI = partnerId => {
    return axios({
        method: 'get',
        url: `v2/sso-configurations/generate_authentication_code/${partnerId}`,
    });
};

/**
 * API to fetch sso mapped field
 *
 * @method fetchSsoMappedFieldAPI
 * @return {Promise}
 */
export const fetchSsoMappedFieldAPI = () => {
    return axios({
        method: 'get',
        url: '/v2/lookup-types?categoryName=sso-mapped-field',
    });
};

/**
 * API to fetch company sso configuration
 *
 * @method fetchSsoConfigurationAPI
 * @param  {Object}  companyId
 * @return {Promise}
 */
export const fetchSsoConfigurationAPI = companyId => {
    return axios({
        method: 'get',
        url: `/v2/sso-configurations/${companyId}`,
    });
};

/**
 * API to update partner sso configuration
 *
 * @method updatePartnerSsoConfigurationAPI
 * @param  {Object}  ssoConfiguration
 * @return {Promise}
 */
export const updatePartnerSsoConfigurationAPI = ({ params, companyId, requestType }) => {
    let url = `/v2/sso-configurations/`;

    if (requestType === 'put') {
        url += `${companyId}`;
    }

    return axios({
        method: requestType,
        url,
        data: params,
    });
};

/**
 * API to update sso api options configuration
 *
 * @method updateSsoApiOptionsAPI
 * @param  {Object}  ssoApiOptionsConfiguration
 * @return {Promise}
 */
export const updateSsoApiOptionsAPI = ({ params, companyId }) => {
    const url = `/v2/sso-configurations/${companyId}/options`;

    return axios({
        method: 'put',
        url,
        data: params,
    });
};

/**
 * API to fetch sign in options
 *
 * @method fetchSignInOptions
 * @return {Promise}
 * @param {string} partnerId
 */
export const fetchSignInOptions = partnerId => {
    return axios({
        method: 'get',
        url: `/v2/sso-configurations/${partnerId}/options`,
    });
};

/**
 * API to fetch sso Api Options Configuration
 *
 * @method fetchSsoApiOptionsAPI
 * @param  {string}             partnerId
 * @return {Promise}
 */
export const fetchSsoApiOptionsAPI = partnerId => {
    return axios({
        method: 'get',
        url: `v2/sso-configurations/${partnerId}`,
    });
};

/**
 * API to fetch company types
 *
 * @method fetchPartnersAPI
 * @param  {Object}             data
 * @return {Promise}
 */
export const fetchCompanyTypesAPI = data => {
    return axios({
        method: 'get',
        url: '/v2/company-types',
        params: data,
    });
};

/**
 * API to fetch company statuses
 *
 * @method fetchCompanyStatusesAPI
 * @return {Promise}
 */
export const fetchCompanyStatusesAPI = () => {
    // TODO: Remove comment when the api works correctly.
    // return axios({
    //     method: 'get',
    //     url: `/v2/company_statuses`,
    // });

    return new Promise(resolve =>
        setTimeout(() => {
            resolve(companyStatuses);
        }, 100)
    );
};

/**
 * API to deactivate companies
 *
 * @method bulkDeactivateCompaniesAPI
 * @param  {Object}  bulkInfo The bulk configuration info
 * @return {Promise}
 */
export const bulkDeactivateCompaniesAPI = bulkInfo => {
    const { partnerId, file } = bulkInfo;
    return axios({
        method: 'patch',
        url: `/v2/companies/bulk?partnerId=${partnerId}`,
        data: file,
    });
};

/**
 * API to fetch custom fields by partnerId
 *
 * @method fetchCustomFieldsAPI
 * @param  {string}  partnerInfo
 * @return {Promise}
 */
export const fetchCustomFieldsAPI = partnerInfo => {
    const { partnerId, type } = partnerInfo;
    return axios({
        method: 'get',
        url: `/v2/custom-fields?partnerId=${partnerId}&type=${type}`,
    });
};

/**
 * API to move company from one to another
 *
 * @method moveCompanyAPI
 * @param  {string}  params
 * @return {Promise}
 */
export const moveCompanyAPI = params => {
    const { companyId, partnerId, configurationId } = params;
    return axios({
        method: 'post',
        url: `/v2/companies/${companyId}/move`,
        data: {
            partnerId,
            configurationId,
        },
    });
};

/**
 * API to get contacts by tagId
 *
 * @method fetchContactByTagIdAPI
 * @param  {Object}  params
 * @param  {string}  tagId
 * @return {Promise}
 */
export const fetchContactByTagIdAPI = (params, tagId) => {
    return axios({
        method: 'get',
        params,
        url: `/v2/companies/tags/${tagId}`,
    });
};

/**
 * API to get employee count by state by companyId
 *
 * @method fetchEmployeeCountByCompanyIdAPI
 * @param  {string}  companyId
 * @return {Promise}
 */
export const fetchEmployeeCountByCompanyIdAPI = companyId => {
    return axios({
        method: 'get',
        url: `/throne/handbook/v1/company/employee-count/${companyId}`,
        type: 'zend',
    });
};

/**
 * API to delete PrismHR configuration from partner.
 *
 * @method deletePrismHRConfigurationAPI
 * @param  {Object}  partnerId
 * @return {Promise}
 */
export const deletePrismHRConfigurationAPI = partnerId => {
    return axios({
        method: 'delete',
        url: `/v2/ext-connector-config/${partnerId}`,
    });
};

/**
 * API call to fetch import history list data.
 *
 * @method fetchImportHistoryListAPI
 * @param  {Object}             companyInfo The company to fetch
 * @return {Promise}
 */
export const fetchImportHistoryListAPI = companyInfo => {
    const { companyId } = companyInfo;
    return axios({
        method: 'get',
        url: `/v2/import-history/${companyId}`,
        params: companyInfo,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });
};

/**
 * API call to fetch import history details
 *
 * @method fetchImportDetailsAPI
 * @param  {Object}             importHistoryId The import to fetch
 * @return {Promise}
 */
export const fetchImportDetailsAPI = importHistoryId => {
    return axios({
        method: 'get',
        url: `/v2/import-history/fetch/${importHistoryId}`,
    });
};

/**
 * API call to fetch partner admin options
 *
 * @method fetchAdminOptionsAPI
 * @param  {Object}             partnerId
 * @return {Promise}
 */
export const fetchAdminOptionsAPI = partnerId => {
    return axios({
        method: 'get',
        url: `/v2/partner-administration-options/${partnerId}`,
    });
};

/**
 * API call to updated partner admin options
 *
 * @method updateAdminOptionsAPI
 * @param  {Object}             partnerInfo
 * @return {Promise}
 */
export const updateAdminOptionsAPI = partnerInfo => {
    const { partnerId, data } = partnerInfo;
    return axios({
        method: 'put',
        url: `/v2/partner-administration-options/${partnerId}`,
        data,
    });
};
