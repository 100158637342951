import { API_ORIGIN } from 'constants/api';

const thrSsoUrl = API_ORIGIN;
/**
 * Brokers excluded to move.
 *
 * @type {String}
 */
export const EXCLUDED_MOVE_BROKERS = [
    8148, // Mineral, Inc.
];

export const COMPANIES_DEACTIVATE_MESSAGES = {
    ERROR_500:
        'Unable to process your request. Please try to re-upload the file again. If problem persist, please contact customer support.',
    IMPORT_FAILED:
        'The import process failed. Please check the csv file download. To upload successfully, correct the errors in the file, delete the header rows and re-upload the final document.',
    IMPORT_INCOMPLETE:
        'Import process not complete. Some clients were not successfully deactivated from the file. Please check the csv file download. To upload successfully, correct the errors in the file, dlete the header rows and re-upload the final document.',
};

export const COMPANY_TYPE = {
    CLIENT: 'client',
    DIRECT: 'direct',
    PARTNER: 'partner',
};

export const CUSTOM_FIELDS_TYPE = {
    USER: 'USER',
    COMPANY: 'COMPANY',
};

export const CUSTOM_FIELDS_LABEL = {
    BUSINESS_ID: 'BUSINESS_ID',
};

export const SSO_INCLUDE_SCRIPT = `<script type="text/javascript" id="thinkhr-sso">function getExplorerVersion(){var e=navigator.userAgent.toLowerCase();return-1!=e.indexOf("msie")&&parseInt(e.split("msie")[1])}var cacheBuster=(new Date).getTime();if(9==getExplorerVersion()){var oHead=document.getElementsByTagName("HEAD").item(0),oScript=document.createElement("script");oScript.type="text/javascript",oScript.src="${thrSsoUrl}/ssojs/?t="+cacheBuster,oHead.appendChild(oScript),null!=(script=document.getElementById("thinkhr-sso"))&&script.parentElement.removeChild(script)}else{var script,src="https:"==location.protocol?"https":"http",thrSsoUrl=src+":${thrSsoUrl}/ssojs/?t="+cacheBuster;(script=document.createElement("script")).src=thrSsoUrl,(document.getElementsByTagName("head")[0]||document.getElementsByTagName("body")[0]).appendChild(script),null!=(script=document.getElementById("thinkhr-sso"))&&script.parentElement.removeChild(script)}</script>`;

export const CREATE_ANOTHER_COMPANY = 'Create another company';

export const PARTNER_SEARCH = 'Partner Search';

export const COMPANY_SEARCH = 'Company Search';

export const PARTNER_COMPANY_RESET = 'Reset Partner/Company Search';

export const MAX_API_CONFIGURATION_EMAIL_RECIPIENTS = 5;

export const ENABLE_CLIENT_TO_CREATE_OWN_COMPANY =
    'Enable client ability to create their own plan documents';

export const COMPANY_LOCATIONS = {
    EMPLOYEE_COUNT: {
        MAX_COUNT_LIMIT: 500000,
        PATTERN: '(^\\d{1,10}$)',
        ERROR_MESSAGE: 'This count is limited to 500K.',
        READ_ONLY_LOCATIONS:
            'Your location information and employee count are updated automatically. Please request changes through your partner’s application.',
    },
};

export const AR_DEMO_SUFFIX = '_ar_demo';

export const SIDE_NAV_GROUP_NUMBER = {
    GROUP_1_1: 1.1,
    GROUP_1_2: 1.2,
    GROUP_1_3: 1.3,
    GROUP_2_1: 2.1,
    GROUP_3_1: 3.1,
    GROUP_3_2: 3.2,
    GROUP_3_3: 3.3,
    GROUP_4_1: 4.1,
    GROUP_4_2: 4.2,
    GROUP_4_3: 4.3,
    GROUP_4_4: 4.4,
    GROUP_4_5: 4.5,
    GROUP_4_6: 4.6,
    GROUP_5: 5,
    GROUP_6: 6,
    GROUP_7: 7,
    GROUP_8_1: 8.1,
    GROUP_8_2: 8.2,
};

export const SIDE_NAV_OPTIONS = {
    CUSTOM_API: 'Custom API',
    VIEW_IMPORT_HISTORY: 'View Import History',
    SEND_WELCOME_EMAIl_BATCH: 'Send Welcome Email Batch',
    ADMINISTRATION_OPTIONS: 'Administration Options',
    VIEW_ALL_USERS: 'View All Users',
    SUPPORT_HR_CONTACT: 'Support/HR Contact',
    SSO_CONFIGURATION: 'SSO Configuration',
    IMPORT_CLIENTS: 'Import Clients',
    IMPORT_USERS: 'Import Users',
    IMPORT_LOCATIONS: 'Import Locations',
    VIEW_ALL_LOCATIONS: 'View all locations',
    ADD_USERS: 'Add user',
    LICENSING_LIMIT: 'Licensing Limits',
    CUSTOM_SIGN_IN_WIDGET: 'Custom Sign in Widget',
    MOVE_COMPANY: 'Move Company',
    TERMS_AND_CONDITIONS: 'Terms & Conditions',
    ADD_LOCATION: 'Add Location',
    VIEW_AUDIT_TRAIL: 'View Audit Trail',
    SSO_API_CONFIGURATION: 'SSO/API Configuration',
    SSO_API_OPTIONS: 'SSO/API Options',
    WEBHOOK_SETTINGS: 'Webhook Settings',
};

export const DELETE_CONFIGURATION = {
    DESCRIPTION_1: 'The following PEO’s will be deleted: ${peoIds}',
    DESCRIPTION_2: 'Are you sure you want to delete the Sync and SSO for this partner?',
};

export const IMPORT_HISTORY = {
    IMPORT_HISTORY_HEADING: 'Import History',
    DESCRIPTION_1: 'Import history for the last 3 months.',
    FILTERS: 'Filters',
    IMPORT_TYPE: 'Import Type',
    CLIENT: 'Client',
    LOCATION: 'Location',
    USER: 'User',
    ACTIONS: {
        VIEW: {
            ID: 'view',
            LABEL: 'View',
        },
        SEND_WELCOME_EMAIl: {
            ID: 'send_welcome_email',
            LABEL: 'Send Welcome Email',
        },
    },
    IMPORT_STATUS: {
        ALL_RECORDS_IMPORTED: 'All Records Imported',
        PARTIAL_RECORDS_IMPORTED: 'Partial Records Imported',
        NO_RECORDS_IMPORTED: 'No Records Imported',
        SYSTEM_ERROR: 'System Error',
        IN_PROGRESS: 'In Progress',
    },
    IMPORT_ERROR: 'Error',
};

export const BULK_IMPORT = {
    CLIENTS: {
        TYPE: 'Clients',
        DEFAULT_TITLE: 'Use this feature to upload multiple client companies in bulk.',
        UPDATED_TITLE:
            'Use this feature to upload multiple client companies in bulk. To review the status of imports visit the <a href="/admin/companies/${partnerId}/${companyId}/import-history?type=${type}" target="_self"> Import History</a> page.',
    },
    LOCATIONS: {
        TYPE: 'Locations',
        DEFAULT_TITLE: 'Use this feature to upload multiple locations in bulk.',
        UPDATED_TITLE:
            'Use this feature to upload multiple locations in bulk. To review the status of imports visit the <a href="/admin/companies/${partnerId}/${companyId}/import-history?type=${type}" target="_self"> Import History</a> page.',
    },
    USERS: {
        TYPE: 'Users',
        DEFAULT_TITLE: 'Use this feature to upload multiple users in bulk.',
        UPDATED_TITLE:
            'Use this feature to upload multiple users in bulk. To review the status of imports visit the <a href="/admin/companies/${partnerId}/${companyId}/import-history?type=${type}" target="_self"> Import History</a> page.',
    },
};

export const CUSTOM_PARTNER_ID = {
    LABEL: 'partner_id',
    UPDATED_VALUE: 'hasPartnerId',
    CHECK_FOR: 'CLIENT_ID',
    COMPANY_TYPE_VALUES: [1, 3],
    INPUT_TYPE: 'text',
    DATA_PATTERN: 'numeric',
};

export const STATIC_CUSTOM_FIELD = {
    isActive: true,
    createdOn: 0,
    modifiedOn: 0,
    id: '',
    partnerId: '',
    type: 'COMPANY',
    displayOrder: '',
    displayKey: '',
    displayLabel: 'PARTNER_ID',
    importRequired: true,
    multiDataNodeName: 'customFields',
    readOnly: false,
};
