import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';
import * as uiActions from 'modules/ui/actions';
import {
    fetchHandbookByIdAPI,
    downloadHandbookByIdAPI,
    fetchHandbookReceiptAPI,
    downloadHandbookIndividualStateAPI,
    deleteHandbookByIdAPI,
    copyHandbookByIdAPI,
    fetchHandbookAPI,
    fetchHandbooksListAPI,
    fetchHandbookTOCAPI,
    policyChangeStatusAPI,
    unlinkCustomPolicyAPI,
    updateHandbookTOCAPI,
    createCustomPolicyAPI,
    fetchCustomPoliciesAPI,
    createHandbookAPI,
    acceptSummaryOfChangesAPI,
    acceptPolicySummaryOfChangesAPI,
    cancelSummaryOfChangesAPI,
    fetchSummaryOfChangesAPI,
    fetchPolicySummaryOfChangesAPI,
    acceptPolicyAlertAPI,
    dismissPolicyAlertAPI,
    fetchCustomPolicyDetailsAPI,
    fetchPolicyDetailsAPI,
    updateCustomPolicyAPI,
    updateHandbookAPI,
    savePolicyChanges,
    fetchActivityStreamAPI,
    fetchActivityStreamEventIdAPI,
    linkCustomPolicies,
    uploadFileAPI,
    downloadActivityStreamAPI,
    deleteHandbookTocAPI,
    fetchHandbooksMhrListAPI,
    fetchDeletedHandbooksListAPI,
    deleteHandbookMhrByIdAPI,
    fetchArchivedHandbooksAPI,
    downloadArchivedHandbookAPI,
    isHandbookTranslatedAPI,
    uploadHandbookPolicyImageAPI,
    getHandbookPolicyImagesUpdatedAPI,
    getFroalaOptionsAPI,
    getSmartHandbookOptionsAPI,
    getHandbookPolicyPreviewAPI,
    restoreDeletedHandbookAPI,
    fetchHandbooksMoveHandbookListAPI,
    fetchHandbooksMoveCompanyListAPI,
    fetchHandbooksMoveDetailAPI,
    moveHandbookAPI,
    fetchTotalDocumentsListAPI,
    fetchDocumentsReportAPI,
    checkDocumentDownloadStatusAPI,
    downloadGeneratedHandbookAPI,
} from 'modules/apis/handbooks';

import * as handbookActions from '../actions';

/**
 * The saga for fetching the list of handbooks.
 *
 * @method fetchHandbooksListSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchHandbooksListSaga(action) {
    try {
        // Start the loading spinner for fetching the handbooks list.
        yield put(uiActions.isLoading(true, 'LOADING HANDBOOKS'));

        // Fetch the list of handbooks...
        const { payload: param } = action;
        const resp = yield call(fetchHandbooksListAPI, param);
        const data = get(resp, 'data');
        const hbInfo = get(data, '_embedded.handbook.0');

        // Delay the saga to make it look like an API call.
        // TODO: remove `delay` once the real API call is in place.
        // Dispatch a success action after fetching the list.
        yield put(handbookActions.fetchHandbooks.success(hbInfo));
        // Return the handbooks list.
        return hbInfo;
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(handbookActions.fetchHandbooks.error(error));

        return error;
    } finally {
        // Clear the loading spinner.
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for fetching a single handbook by id.
 *
 * @method fetchHandbookByIdSaga
 * @param  {Object}              action The original redux-action
 * @return {Generator}
 */
export function* fetchHandbookByIdSaga(action) {
    try {
        // Start the loading spinner for fetching the requested handbook.
        yield put(uiActions.isLoading(true, 'fetching handbook'));
        // Extract the handbookId.
        const { payload: handbookId } = action;
        // Fetch the handbook.
        const resp = yield call(fetchHandbookByIdAPI, handbookId);
        // Extract the handbook from resp.
        const handbook = get(resp, 'data');
        // Throw error if handbook is empty / not available.
        if (!handbook) {
            throw new Error(`Unable to fetch handbook for id '${handbookId}'`);
        }
        // Dispatch a success action after fetching the handbook.
        yield put(handbookActions.fetchHandbookById.success(handbook));
        // Return the handbook.
        return handbook;
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(handbookActions.fetchHandbookById.error(error));

        return error;
    } finally {
        // Clear the loading spinner.
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for fetching a single handbook TOC by id.
 *
 * @method fetchHandbookTOCSaga
 * @param  {Object}              action The original redux-action
 * @return {Generator}
 */
export function* fetchHandbookTOCSaga(action) {
    try {
        // Start the loading spinner for fetching the requested handbook TOC.
        // Extract the handbookId.
        const { payload } = action;
        const { search, id, policyId, sectionId } = payload;
        // Fetch the handbook.
        const resp = yield call(fetchHandbookTOCAPI, id, policyId, sectionId, search);
        // Extract the handbook from resp.
        const handbookTOC = get(resp, 'data');
        // Throw error if handbook is empty / not available.
        if (!handbookTOC) {
            // throw new Error(`Unable to fetch handbook TOC for id '${handbookId}'`);
        }
        // Dispatch a success action after fetching the handbook TOC.
        yield put(handbookActions.fetchHandbookTOC.success(handbookTOC));
        // Return the handbook TOC.
        return handbookTOC;
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(handbookActions.fetchHandbookTOC.error(error));
        return error;
    }
}

/**
 * The saga for downloading a single handbook by id.
 *
 * @method fetchHandbookByIdSaga
 * @param  {Object}              action The original redux-action
 * @return {Generator}
 */
export function* downloadHandbookByIdSaga(action) {
    try {
        // Extract the handbookId.
        let resp;
        const { payload } = action;
        const { id, fileType, locale, filters } = payload;
        if (fileType === 'receipt') {
            // Download the handbook.
            resp = yield call(fetchHandbookReceiptAPI, id);
        } else {
            // Download the handbook.
            resp = yield call(downloadHandbookByIdAPI, id, fileType, locale, filters);
        }
        // Extract the download handbook info from resp.
        const downloadHandbook = get(resp, 'data');
        // Throw error if handbook is empty / not available.
        if (!downloadHandbook) {
            throw new Error(`Unable to download handbook for id '${id}'`);
        }
        // Dispatch a success action after fetching the handbook.
        yield put(handbookActions.downloadHandbookById.success(downloadHandbook));
        // Return the handbook.
        return downloadHandbook;
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(handbookActions.downloadHandbookById.error(error));
        return error;
    } finally {
        // Clear the loading spinner.
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for downloading a single handbook by ID & state.
 *
 * @method downloadHandbookByStateSaga
 * @param  {Object}              action The original redux-action
 * @return {Generator}
 */
export function* downloadHandbookByStateSaga(action) {
    try {
        // Extract the handbookId.

        const { payload } = action;
        const { filters, id, fileType } = payload;
        // Download the handbook.
        const resp = yield call(downloadHandbookIndividualStateAPI, id, fileType, filters);

        // Extract the download handbook info from resp.
        const downloadHandbook = get(resp, 'data');
        // Throw error if handbook is empty / not available.
        if (!downloadHandbook) {
            throw new Error(`Unable to download handbook for id '${id}'`);
        }
        // Dispatch a success action after fetching the handbook.
        yield put(handbookActions.downloadHandbookByState.success(downloadHandbook));
        // Return the handbook.
        return downloadHandbook;
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(handbookActions.downloadHandbookByState.error(error));
        return error;
    } finally {
        // Clear the loading spinner.
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for deleting a single handbook by id.
 *
 * @method deleteHandbookByIdSaga
 * @param  {Object}              action The original redux-action
 * @return {Generator}
 */
export function* deleteHandbookByIdSaga(action) {
    try {
        // Start the loading spinner for deleting the requested handbook.
        yield put(uiActions.isLoading(true, 'Deleting handbook'));
        // Extract the handbookId.
        const { payload: handbookId } = action;
        // delete the handbook.
        const resp = yield call(deleteHandbookByIdAPI, handbookId);
        // Dispatch a success action after delete the handbook.
        yield put(handbookActions.deleteHandbookById.success(resp));
        // Return the response.
        return resp;
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(handbookActions.deleteHandbookById.error(error));
        return error;
    } finally {
        // Clear the loading spinner.
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for copying a single handbook by id.
 *
 * @method copyHandbookByIdSaga
 * @param  {Object}              action The original redux-action
 * @return {Generator}
 */
export function* copyHandbookByIdSaga(action) {
    try {
        // Start the loading spinner for copying the requested handbook.
        yield put(uiActions.isLoading(true, 'Copying handbook'));
        // Extract the handbookId.
        const { payload } = action;
        const { handbookId, handbookName, suggestedName } = payload;
        // Copy the handbook.
        const resp = yield call(copyHandbookByIdAPI, handbookId, handbookName, suggestedName);
        // Dispatch a success action after copying the handbook.
        yield put(handbookActions.copyHandbookById.success(resp));
        // Return the resp.
        return resp;
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(handbookActions.copyHandbookById.error(error));
        return error;
    } finally {
        // Clear the loading spinner.
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Saga to unlink custom policy from a handbook
 *
 * @method linkCustomPoliciesSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* linkCustomPoliciesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(linkCustomPolicies, payload);
        const linkPoliciesResponse = get(response, 'data');
        yield put(handbookActions.linkCustomPolicies.success(linkPoliciesResponse));
        return linkPoliciesResponse;
    } catch (error) {
        yield put(handbookActions.linkCustomPolicies.error(error));
        return error;
    }
}

/**
 * The saga for unlink a custom policy for a handbook by id.
 *
 * @method unlinkCustomPolicySaga
 * @param  {Object}              action The original redux-action
 * @return {Generator}
 */
export function* unlinkCustomPolicySaga(action) {
    try {
        // Start the loading spinner for copying the requested handbook.
        yield put(uiActions.isLoading(true, 'Loading Data'));
        // Extract the handbookId.
        const { payload } = action;
        const { handbookId, id } = payload;
        // Copy the handbook.
        const resp = yield call(unlinkCustomPolicyAPI, handbookId, id);
        // Dispatch a success action after copying the handbook.
        yield put(handbookActions.unlinkCustomPolicy.success(resp));
        // Return the resp.
        return resp;
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(handbookActions.unlinkCustomPolicy.error(error));
        return error;
    } finally {
        // Clear the loading spinner.
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga to update the policy status (enabled/disabled) for a handbook
 *
 * @method policyChangeStatusSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* policyChangeStatusSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'Loading Data'));
        const { payload } = action;
        const { policyType, id, isEnabled } = payload;
        const resp = yield call(policyChangeStatusAPI, id, policyType, isEnabled);
        yield put(handbookActions.policyChangeStatus.success(resp));
        return resp;
    } catch (error) {
        yield put(handbookActions.policyChangeStatus.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for update a single handbook TOC.
 *
 * @method updateHandbookTOCSaga
 * @param  {Object}              action The original redux-action
 * @return {Generator}
 */
export function* updateHandbookTOCSaga(action) {
    try {
        // Start the loading spinner for fetching the requested handbook.
        yield put(uiActions.isLoading(true, 'Loading Data'));
        const { payload } = action;
        const { id, policyGroups, customPolicies } = payload;
        const resp = yield call(updateHandbookTOCAPI, id, policyGroups, customPolicies);
        // Extract the handbookTOC from resp.
        const handbookTOCReorder = get(resp, 'data');

        // Dispatch a success action after fetching the updated handbook TOC.
        yield put(handbookActions.updateHandbookTOC.success(handbookTOCReorder));
        // Return the handbookTOC.
        return handbookTOCReorder;
    } catch (error) {
        // Dispatch the error action with the error.
        yield put(handbookActions.updateHandbookTOC.error(error));
        return error;
    } finally {
        // Clear the loading spinner.
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Saga to accept a handbook's Summary of Changes
 *
 * @method acceptSummaryOfChangesSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* acceptSummaryOfChangesSaga(action) {
    try {
        const {
            payload: { changeId },
        } = action;
        const response = yield call(acceptSummaryOfChangesAPI, changeId);
        const data = get(response, 'data');
        yield put(handbookActions.acceptSummaryOfChanges.success(data));
        return data;
    } catch (error) {
        yield put(handbookActions.acceptSummaryOfChanges.error(error));
        return error;
    }
}

/**
 * Saga to accept a handbook's Policy Summary of Changes
 *
 * @method acceptPolicySummaryOfChangesSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* acceptPolicySummaryOfChangesSaga(action) {
    try {
        const {
            payload: { changeId },
        } = action;
        const response = yield call(acceptPolicySummaryOfChangesAPI, changeId);
        const data = get(response, 'data');
        yield put(handbookActions.acceptPolicySummaryOfChanges.success(data));
        return data;
    } catch (error) {
        yield put(handbookActions.acceptPolicySummaryOfChanges.error(error));
        return error;
    }
}

/**
 * Saga to cancel a handbook's Summary of Changes
 *
 * @method cancelSummaryOfChangesSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* cancelSummaryOfChangesSaga(action) {
    try {
        const {
            payload: { changeId },
        } = action;
        const response = yield call(cancelSummaryOfChangesAPI, changeId);
        const data = get(response, 'data');
        yield put(handbookActions.cancelSummaryOfChanges.success(data));
        return data;
    } catch (error) {
        yield put(handbookActions.cancelSummaryOfChanges.error(error));
        return error;
    }
}

/**
 * Saga to fetch a handbook's Summary of Changes
 *
 * @method fetchSummaryOfChangesSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchSummaryOfChangesSaga(action) {
    try {
        const {
            payload: { changeId },
        } = action;
        const response = yield call(fetchSummaryOfChangesAPI, changeId);
        const data = get(response, 'data');
        yield put(handbookActions.fetchSummaryOfChanges.success(data));
        return data;
    } catch (error) {
        yield put(handbookActions.fetchSummaryOfChanges.error(error));
        return error;
    }
}

/**
 * Saga to fetch a handbook's Summary of Changes
 *
 * @method fetchPolicySummaryOfChangesSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* fetchPolicySummaryOfChangesSaga(action) {
    try {
        const {
            payload: { changeId },
        } = action;
        const response = yield call(fetchPolicySummaryOfChangesAPI, changeId);
        const data = get(response, 'data');
        yield put(handbookActions.fetchPolicySummaryOfChanges.success(data));
        return data;
    } catch (error) {
        yield put(handbookActions.fetchPolicySummaryOfChanges.error(error));
        return error;
    }
}

/**
 * Saga to accept a policy alert
 *
 * @method acceptPolicyAlertSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* acceptPolicyAlertSaga(action) {
    try {
        const {
            payload: { policyId, policyType },
        } = action;
        const response = yield call(acceptPolicyAlertAPI, { policyId, policyType });
        const data = get(response, 'data');
        yield put(handbookActions.acceptPolicyAlert.success(data));
        return data;
    } catch (error) {
        yield put(handbookActions.acceptPolicyAlert.error(error));
        return error;
    }
}

/**
 * Saga to dismiss a policy alert
 *
 * @method dismissPolicyAlertSaga
 * @param  {Object} action The original redux-action
 * @return {Generator}
 */
export function* dismissPolicyAlertSaga(action) {
    try {
        const {
            payload: { policyId, policyType },
        } = action;
        const response = yield call(dismissPolicyAlertAPI, { policyId, policyType });
        const data = get(response, 'data');
        yield put(handbookActions.dismissPolicyAlert.success(data));
        return data;
    } catch (error) {
        yield put(handbookActions.dismissPolicyAlert.error(error));
        return error;
    }
}

/**
 * The saga for fetching the custom policies list.
 *
 * @method fetchCustomPoliciesListSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchCustomPoliciesListSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING DATA'));
        // Fetch the list of custom policies...
        const { payload: param } = action;
        const resp = yield call(fetchCustomPoliciesAPI, param);
        const data = get(resp, 'data');
        const customPolicy = get(data, '_embedded.handbook_custom_policy.0');
        yield put(handbookActions.fetchCustomPolicies.success(customPolicy));
        return customPolicy;
    } catch (error) {
        yield put(handbookActions.fetchCustomPolicies.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Saga to create custom policy
 *
 * @method createCustomPolicySaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* createCustomPolicySaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createCustomPolicyAPI, payload);
        const customPolicy = get(response, 'data');
        yield put(handbookActions.createCustomPolicy.success(customPolicy));
        return customPolicy;
    } catch (error) {
        yield put(handbookActions.createCustomPolicy.error(error));
        return error;
    }
}

/**
 * Saga to create a new handbook
 *
 * @method createHandbookSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* createHandbookSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(createHandbookAPI, payload);
        const handbook = get(response, 'data');
        yield put(handbookActions.createHandbook.success(handbook));
        return handbook;
    } catch (error) {
        yield put(handbookActions.createHandbook.error(error));
        return error;
    }
}
/**
 * The saga for fetching policies details.
 *
 * @method fetchPolicyDetailsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchPolicyDetailsSaga(action) {
    try {
        const { payload: param } = action;
        const resp = yield call(fetchPolicyDetailsAPI, param);
        const response = get(resp, 'data');
        yield put(handbookActions.fetchPolicyDetails.success(response));
        return response;
    } catch (error) {
        yield put(handbookActions.fetchPolicyDetails.error(error));
        return error;
    }
}

/**
 * The saga for fetching custom policies details.
 *
 * @method fetchCustomPolicyDetailsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchCustomPolicyDetailsSaga(action) {
    try {
        const { payload: param } = action;
        const resp = yield call(fetchCustomPolicyDetailsAPI, param);
        const response = get(resp, 'data');
        yield put(handbookActions.fetchCustomPolicyDetails.success(response));
        return response;
    } catch (error) {
        yield put(handbookActions.fetchCustomPolicyDetails.error(error));
        return error;
    }
}

/**
 * Saga to update custom policy
 *
 * @method updateCustomPolicySaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* updateCustomPolicySaga(action) {
    try {
        const {
            payload: { data, policyId },
        } = action;
        const response = yield call(updateCustomPolicyAPI, data, policyId);
        const customPolicy = get(response, 'data');
        yield put(handbookActions.updateCustomPolicy.success(customPolicy));
        return customPolicy;
    } catch (error) {
        yield put(handbookActions.updateCustomPolicy.error(error));
        return error;
    }
}

/**
 * Saga to fetch handbook
 *
 * @method fetchHandbookSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchHandbookSaga(action) {
    try {
        const { payload: handbookId } = action;
        const response = yield call(fetchHandbookAPI, handbookId);
        const handbook = get(response, 'data');
        yield put(handbookActions.fetchHandbook.success(handbook));
        return handbook;
    } catch (error) {
        yield put(handbookActions.fetchHandbook.error(error));
        return error;
    }
}

/**
 * Saga to update handbook
 *
 * @method updateHandbookSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* updateHandbookSaga(action) {
    try {
        const {
            payload: { handbookId, params },
        } = action;
        const response = yield call(updateHandbookAPI, handbookId, params);
        const handbook = get(response, 'data');
        yield put(handbookActions.updateHandbook.success(handbook));
        return handbook;
    } catch (error) {
        yield put(handbookActions.updateHandbook.error(error));
        return error;
    }
}

/**
 * The saga for saving policy changes.
 *
 * @method savePolicySaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* savePolicySaga(action) {
    try {
        const { payload: param } = action;
        const resp = yield call(savePolicyChanges, param);
        const response = get(resp, 'data');
        yield put(handbookActions.savePolicy.success(response));
        return response;
    } catch (error) {
        yield put(handbookActions.savePolicy.error(error));
        return error;
    }
}

/**
 * The saga for fetch activity stream.
 *
 * @method fetchActivityStreamSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchActivityStreamSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(fetchActivityStreamAPI, payload);
        const activity = get(resp, 'data.result', {});
        yield put(handbookActions.fetchActivityStream.success(activity));
        return activity;
    } catch (error) {
        yield put(handbookActions.fetchActivityStream.error(error));
        return error;
    }
}

/**
 * The saga for fetch activity stream EventId.
 *
 * @method fetchActivityStreamEventIdSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchActivityStreamEventIdSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(fetchActivityStreamEventIdAPI, payload);
        const activity = get(resp, 'data.event', {});
        yield put(handbookActions.fetchActivityStreamEventId.success(activity));
        return activity;
    } catch (error) {
        yield put(handbookActions.fetchActivityStreamEventId.error(error));
        return error;
    }
}

/**
 * The saga for upload File.
 *
 * @method uploadFileSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* uploadFileSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(uploadFileAPI, payload);
        const data = get(resp, 'data', {});
        yield put(handbookActions.uploadFile.success(data));
        return data;
    } catch (error) {
        yield put(handbookActions.uploadFile.error(error));
        return error;
    }
}

/**
 * The saga for download activity stream.
 *
 * @method downloadActivityStreamSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* downloadActivityStreamSaga(action) {
    try {
        const {
            payload: { filters, handbookId, type },
        } = action;
        const resp = yield call(downloadActivityStreamAPI, filters, handbookId, type);
        const download = get(resp, 'data', '');
        yield put(handbookActions.downloadActivityStream.success(download));
        return download;
    } catch (error) {
        yield put(handbookActions.downloadActivityStream.error(error));
        return error;
    }
}

/**
 * The saga for download activity stream.
 *
 * @method deleteHandbookTocSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* deleteHandbookTocSaga(action) {
    try {
        const {
            payload: { id },
        } = action;
        const resp = yield call(deleteHandbookTocAPI, id);
        const download = get(resp, 'data', '');
        yield put(handbookActions.deleteHandbookToc.success(download));
        return download;
    } catch (error) {
        yield put(handbookActions.deleteHandbookToc.error(error));
        return error;
    }
}

/**
 * The saga for fetching the list of MHR handbooks.
 *
 * @method fetchHandbooksMhrListSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchHandbooksMhrListSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING MHR HANDBOOKS'));

        const { payload: param } = action;
        const resp = yield call(fetchHandbooksMhrListAPI, param);
        const data = get(resp, 'data');
        const hbInfo = get(data, '_embedded.mhr_handbook.0');

        yield put(handbookActions.fetchHandbooksMhr.success(hbInfo));

        return hbInfo;
    } catch (error) {
        yield put(handbookActions.fetchHandbooksMhr.error(error));

        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for fetching the list of handbooks in handbooks move section.
 *
 * @method fetchHandbooksMoveListSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchHandbooksMoveHandbookListSaga(action) {
    try {
        const { payload: param } = action;
        const resp = yield call(fetchHandbooksMoveHandbookListAPI, param);
        const data = get(resp, 'data');
        const hbInfo = get(data, 'handbookList');

        yield put(handbookActions.fetchHandbooksMoveHandbooks.success(hbInfo));

        return hbInfo;
    } catch (error) {
        yield put(handbookActions.fetchHandbooksMoveHandbooks.error(error));

        return error;
    }
}

/**
 * The saga for fetching the list of companies in handbooks move section.
 *
 * @method fetchHandbooksMoveCompanyListSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchHandbooksMoveCompanyListSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(fetchHandbooksMoveCompanyListAPI, payload);
        const data = get(resp, 'data');
        const hbInfo = get(data, 'client');

        yield put(handbookActions.fetchHandbooksMoveCompanies.success(hbInfo));

        return hbInfo;
    } catch (error) {
        yield put(handbookActions.fetchHandbooksMoveCompanies.error(error));

        return error;
    }
}

/**
 * The saga for fetching the handbook move detail.
 *
 * @method fetchHandbookMoveDetailSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchHandbookMoveDetailSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING HANDBOOK MOVE DETAIL'));

        const { payload: param } = action;
        const resp = yield call(fetchHandbooksMoveDetailAPI, param);
        const data = get(resp, 'data');

        yield put(handbookActions.fetchHandbookMoveDetail.success(data));

        return data;
    } catch (error) {
        yield put(handbookActions.fetchHandbookMoveDetail.error(error));

        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Saga to move a handbook
 *
 * @method moveHandbookSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* moveHandbookSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'MOVING HANDBOOK'));

        const { payload } = action;
        const response = yield call(moveHandbookAPI, payload);
        const policy = get(response, 'data');
        yield put(handbookActions.moveHandbook.success(policy));
        return policy;
    } catch (error) {
        yield put(handbookActions.moveHandbook.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for fetching the list of deleted handbooks.
 *
 * @method fetchDeletedHandbooksListSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchDeletedHandbooksListSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING DELETED HANDBOOKS'));

        const { payload: param } = action;
        const resp = yield call(fetchDeletedHandbooksListAPI, param);
        const data = get(resp, 'data');
        const hbInfo = get(data, '_embedded.restore_deleted_handbook.0');

        yield put(handbookActions.fetchDeletedHandbooks.success(hbInfo));

        return hbInfo;
    } catch (error) {
        yield put(handbookActions.fetchDeletedHandbooks.error(error));

        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Saga to restore a deleted handbook
 *
 * @method restoreDeletedHandbookSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* restoreDeletedHandbookSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(restoreDeletedHandbookAPI, payload);
        const policy = get(response, 'data');
        yield put(handbookActions.restoreDeletedHandbookById.success(policy));
        return policy;
    } catch (error) {
        yield put(handbookActions.restoreDeletedHandbookById.error(error));
        return error;
    }
}

/**
 * The saga for deleting a single handbook by id.
 *
 * @method deleteHandbookMhrByIdSaga
 * @param  {Object}              action The original redux-action
 * @return {Generator}
 */
export function* deleteHandbookMhrByIdSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'Deleting mhr handbook'));

        const { payload: handbookId } = action;
        const resp = yield call(deleteHandbookMhrByIdAPI, handbookId);

        yield put(handbookActions.deleteHandbookMhrById.success(resp));

        return resp;
    } catch (error) {
        yield put(handbookActions.deleteHandbookMhrById.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Saga to fetch handbooks
 *
 * @method fetchArchivedHandbooksSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchArchivedHandbooksSaga(action) {
    try {
        const { companyId } = action;
        const response = yield call(fetchArchivedHandbooksAPI, companyId);
        const handbook = get(response, 'data');
        yield put(handbookActions.fetchArchivedHandbooks.success(handbook));
        return handbook;
    } catch (error) {
        yield put(handbookActions.fetchArchivedHandbooks.error(error));
        return error;
    }
}

/**
 * Saga to download archived handbook
 *
 * @method downloadArchivedHandbookSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* downloadArchivedHandbookSaga(action) {
    try {
        const { payload: companyId } = action;
        const response = yield call(downloadArchivedHandbookAPI, companyId);
        const handbook = get(response, 'data');
        yield put(handbookActions.downloadArchivedHandbook.success(handbook));
        return handbook;
    } catch (error) {
        yield put(handbookActions.downloadArchivedHandbook.error(error));
        return error;
    }
}

/**
 * Saga to download archived handbook
 *
 * @method isHandbookTranslatedSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* isHandbookTranslatedSaga(action) {
    try {
        const { payload: handbookId } = action;
        const response = yield call(isHandbookTranslatedAPI, handbookId);
        const handbook = get(response, 'data');
        yield put(handbookActions.isHandbookTranslated.success(handbook));
        return handbook;
    } catch (error) {
        yield put(handbookActions.isHandbookTranslated.error(error));
        return error;
    }
}

/**
 * Saga to download archived handbook
 *
 * @method uploadHandbookPolicyImageSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* uploadHandbookPolicyImageSaga(action) {
    try {
        const { payload: formData } = action;
        const response = yield call(uploadHandbookPolicyImageAPI, formData);
        const uploadImage = get(response, 'data');
        yield put(handbookActions.uploadHandbookPolicyImage.success(uploadImage));
        return uploadImage;
    } catch (error) {
        yield put(handbookActions.uploadHandbookPolicyImage.error(error));
        return error;
    }
}

/**
 * Saga to download archived handbook
 *
 * @method getHandbookPolicyImagesUpdatedSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* getHandbookPolicyImagesUpdatedSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(getHandbookPolicyImagesUpdatedAPI, payload);
        const uploadImage = get(response, 'data');
        yield put(handbookActions.getHandbookPolicyImagesUpdated.success(uploadImage));
        return uploadImage;
    } catch (error) {
        yield put(handbookActions.getHandbookPolicyImagesUpdated.error(error));
        return error;
    }
}

/**
 * Saga to get Handbook Editor Options
 *
 * @method getFroalaOptionsSaga
 * @return {Generator}
 */
export function* getFroalaOptionsSaga() {
    try {
        const response = yield call(getFroalaOptionsAPI);
        const uploadImage = get(response, 'data');
        yield put(handbookActions.getFroalaOptions.success(uploadImage));
        return uploadImage;
    } catch (error) {
        yield put(handbookActions.getFroalaOptions.error(error));
        return error;
    }
}

/**
 * Saga to get Smart Handbook Options
 *
 * @method getSmartHandbookOptionsSaga
 * @return {Generator}
 */
export function* getSmartHandbookOptionsSaga() {
    try {
        const response = yield call(getSmartHandbookOptionsAPI);
        const uploadImage = get(response, 'data');
        yield put(handbookActions.getSmartHandbookOptions.success(uploadImage));
        return uploadImage;
    } catch (error) {
        yield put(handbookActions.getSmartHandbookOptions.error(error));
        return error;
    }
}

/**
 * Saga to get preview Handbook locked policy
 *
 * @method getHandbookPolicyPreviewSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* getHandbookPolicyPreviewSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(getHandbookPolicyPreviewAPI, payload);
        const policy = get(response, 'data');
        yield put(handbookActions.getHandbookPolicyPreview.success(policy));
        return policy;
    } catch (error) {
        yield put(handbookActions.getHandbookPolicyPreview.error(error));
        return error;
    }
}

/**
 * The saga for fetching the list of handbooks in handbooks move section.
 *
 * @method fetchTotalDocumentsListSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchTotalDocumentsListSaga(action) {
    try {
        const { payload: param } = action;
        const resp = yield call(fetchTotalDocumentsListAPI, param);
        const data = get(resp, 'data');

        yield put(handbookActions.fetchTotalDocumentsList.success(data));

        return data;
    } catch (error) {
        yield put(handbookActions.fetchTotalDocumentsList.error(error));

        return error;
    }
}

/**
 * The saga for fetching the list of handbooks in handbooks move section.
 *
 * @method fetchDocumentsReportSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchDocumentsReportSaga(action) {
    try {
        const { payload: param } = action;
        const resp = yield call(fetchDocumentsReportAPI, param);
        const data = get(resp, 'data');

        yield put(handbookActions.fetchDocumentsReport.success(data));

        return data;
    } catch (error) {
        yield put(handbookActions.fetchDocumentsReport.error(error));

        return error;
    }
}

/**
 * The saga for fetching the list of handbooks in handbooks move section.
 *
 * @method checkDocumentDownloadStatusSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* checkDocumentDownloadStatusSaga(action) {
    try {
        const { payload: param } = action;
        const resp = yield call(checkDocumentDownloadStatusAPI, param);
        const data = get(resp, 'data');

        yield put(handbookActions.checkDocumentDownloadStatus.success(data));

        return data;
    } catch (error) {
        yield put(handbookActions.checkDocumentDownloadStatus.error(error));

        return error;
    }
}

/**
 * The saga for fetching the list of handbooks in handbooks move section.
 *
 * @method downloadGeneratedHandbookSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* downloadGeneratedHandbookSaga(action) {
    try {
        const { payload: param } = action;
        const resp = yield call(downloadGeneratedHandbookAPI, param);
        const data = get(resp, 'data');

        yield put(handbookActions.downloadGeneratedHandbook.success(data));

        return data;
    } catch (error) {
        yield put(handbookActions.downloadGeneratedHandbook.error(error));

        return error;
    }
}
