import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching the list of handbooks.
 *
 * @type {Object}
 */
export const fetchHandbooks = createActions('handbooks', 'FETCH_LIST', { asPromise: true });

/**
 * Redux actions for fetching a handbook by id.
 *
 * @type {Object}
 */
export const fetchHandbookById = createActions('handbooks', 'FETCH_BY_ID', {
    asPromise: true,
});

/**
 * Redux actions for fetching a handbook TOC by id.
 *
 * @type {Object}
 */
export const fetchHandbookTOC = createActions('handbooks', 'FETCH_TOC_BY_ID', {
    asPromise: true,
});

/**
 * Redux actions for Downloading a handbook by id.
 *
 * @type {Object}
 */
export const downloadHandbookById = createActions('handbooks', 'DOWNLOAD_BY_ID', {
    asPromise: true,
});

/**
 * Redux actions for Downloading a handbook by state.
 *
 * @type {Object}
 */
export const downloadHandbookByState = createActions('handbooks', 'DOWNLOAD_BY_STATE', {
    asPromise: true,
});

/**
 * Redux actions for deleting a handbook by state.
 *
 * @type {Object}
 */
export const deleteHandbookById = createActions('handbooks', 'DELETE_BY_ID', {
    asPromise: true,
});

/**
 * Redux actions for Copying a handbook by state.
 *
 * @type {Object}
 */
export const copyHandbookById = createActions('handbooks', 'COPY_BY_ID', {
    asPromise: true,
});

/**
 * Redux actions for unlink a custom policy.
 *
 * @type {Object}
 */
export const unlinkCustomPolicy = createActions('handbooks', 'UNLINK_CUSTOM_POLICY', {
    asPromise: true,
});

/**
 * Redux actions for enable/disable policy.
 *
 * @type {Object}
 */
export const policyChangeStatus = createActions('handbooks', 'POLICY_ENABLE', {
    asPromise: true,
});

/**
 * Redux actions for TOC reorder update.
 *
 * @type {Object}
 */
export const updateHandbookTOC = createActions('handbooks', 'UPDATE_TOC', {
    asPromise: true,
});

/**
 * Redux actions to accept a handbook's Summary of Changes
 *
 * @type {Object}
 */
export const acceptSummaryOfChanges = createActions('handbooks', 'ACCEPT_SUMMARY_OF_CHANGES', {
    asPromise: true,
});

/**
 * Redux actions to accept a handbook's Policy Summary of Changes
 *
 * @type {Object}
 */
export const acceptPolicySummaryOfChanges = createActions(
    'handbooks',
    'ACCEPT_POLICY_SUMMARY_OF_CHANGES',
    {
        asPromise: true,
    }
);

/**
 * Redux actions to cancel a handbook's Summary of Changes
 *
 * @type {Object}
 */
export const cancelSummaryOfChanges = createActions('handbooks', 'CANCEL_SUMMARY_OF_CHANGES', {
    asPromise: true,
});

/**
 * Redux actions to fetch a handbook's Summary of Changes
 *
 * @type {Object}
 */
export const fetchSummaryOfChanges = createActions('handbooks', 'FETCH_SUMMARY_OF_CHANGES', {
    asPromise: true,
});

/**
 * Redux actions to fetch a handbook's Policy Summary of Changes
 *
 * @type {Object}
 */
export const fetchPolicySummaryOfChanges = createActions(
    'handbooks',
    'FETCH_POLICY_SUMMARY_OF_CHANGES',
    {
        asPromise: true,
    }
);

/**
 * Redux actions to accept a policy alert
 *
 * @type {Object}
 */
export const acceptPolicyAlert = createActions('handbooks', 'ACCEPT_POLICY_ALERT', {
    asPromise: true,
});

/**
 * Redux actions to dismiss a policy alert
 *
 * @type {Object}
 */
export const dismissPolicyAlert = createActions('handbooks', 'DISMISS_POLICY_ALERT', {
    asPromise: true,
});

/**
 * Redux actions for fetching custom policy list.
 *
 * @type {Object}
 */
export const fetchCustomPolicies = createActions('handbooks', 'FETCH_CUSTOM_POLICIES', {
    asPromise: true,
});

/**
 * Redux actions to create custom policy.
 *
 * @type {Object}
 */
export const createCustomPolicy = createActions('handbooks', 'CREATE_CUSTOM_POLICY', {
    asPromise: true,
});

/**
 * Redux actions to create handbook.
 *
 * @type {Object}
 */
export const createHandbook = createActions('handbooks', 'CREATE_HANDBOOK', {
    asPromise: true,
});

/**
 * Redux actions for fetching policy details.
 *
 * @type {Object}
 */
export const fetchPolicyDetails = createActions('handbooks', 'FETCH_POLICY_DETAILS', {
    asPromise: true,
});

/**
 * Redux actions for fetching custom policy details.
 *
 * @type {Object}
 */
export const fetchCustomPolicyDetails = createActions('handbooks', 'FETCH_CUSTOM_POLICY_DETAILS', {
    asPromise: true,
});

/**
 * Redux actions to update custom policy.
 *
 * @type {Object}
 */
export const updateCustomPolicy = createActions('handbooks', 'UPDATE_CUSTOM_POLICY', {
    asPromise: true,
});

/**
 * Redux actions to fetch handbook.
 *
 * @type {Object}
 */
export const fetchHandbook = createActions('handbooks', 'FETCH_HANDBOOK', {
    asPromise: true,
});

/**
 * Redux actions to update handbook.
 *
 * @type {Object}
 */
export const updateHandbook = createActions('handbooks', 'UPDATE_HANDBOOK', {
    asPromise: true,
});

/**
 * Redux actions to save policy changes.
 *
 * @type {Object}
 */
export const savePolicy = createActions('handbooks', 'SAVE_POLICY', {
    asPromise: true,
});

/**
 * Redux actions to fetch activity stream.
 *
 * @type {Object}
 */
export const fetchActivityStream = createActions('handbooks', 'FETCH_ACTIVITY_STREAM', {
    asPromise: true,
});

/**
 * Redux actions to fetch activity stream event id.
 *
 * @type {Object}
 */
export const fetchActivityStreamEventId = createActions(
    'handbooks',
    'FETCH_ACTIVITY_STREAM_EVENT_ID',
    {
        asPromise: true,
    }
);

/**
 * Redux action to link custom policies with handbook
 *
 * @type {unknown}
 */
export const linkCustomPolicies = createActions('handbooks', 'LINK_CUSTOM_POLICIES', {
    asPromise: true,
});

/**
 * Redux actions to upload file.
 *
 * @type {Object}
 */
export const uploadFile = createActions('handbooks', 'UPLOAD_FILE', {
    asPromise: true,
});

/**
 * Redux actions to download activity stream.
 *
 * @type {Object}
 */
export const downloadActivityStream = createActions('handbooks', 'DOWNLOAD_ACTIVITY_STREAM', {
    asPromise: true,
});

/**
 * Redux actions to delete handbook TOC.
 *
 * @type {Object}
 */
export const deleteHandbookToc = createActions('handbooks', 'DELETE_HANDBOOK_TOC', {
    asPromise: true,
});

/**
 * Redux actions for fetching the list of MHR handbooks.
 *
 * @type {Object}
 */
export const fetchHandbooksMhr = createActions('handbooksMhr', 'FETCH_LIST', { asPromise: true });

/**
 * Redux actions for fetching the list of handbooks in handbooks move section
 *
 * @type {Object}
 */
export const fetchHandbooksMoveHandbooks = createActions(
    'moveHandbooks',
    'FETCH_HANDBOOKS_MOVE_HANDBOOKS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching the list of companies in handbooks move section.
 *
 * @type {Object}
 */
export const fetchHandbooksMoveCompanies = createActions(
    'moveHandbooks',
    'FETCH_HANDBOOKS_MOVE_COMPANIES',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching the handbook move detail.
 *
 * @type {Object}
 */
export const fetchHandbookMoveDetail = createActions(
    'moveHandbooks',
    'FETCH_HANDBOOK_MOVE_DETAIL',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for moving a handbook.
 *
 * @type {Object}
 */
export const moveHandbook = createActions('moveHandbooks', 'MOVE_HANDBOOK', {
    asPromise: true,
});

/**
 * Redux actions for fetching the list of deleted handbooks.
 *
 * @type {Object}
 */
export const fetchDeletedHandbooks = createActions('deletedHandbooks', 'FETCH_DELETED_HANDBOOKS', {
    asPromise: true,
});

/**
 * Redux actions for restoring a deleted handbook by id.
 *
 * @type {Object}
 */
export const restoreDeletedHandbookById = createActions('deletedHandbooks', 'RESTORE_HANDBOOK', {
    asPromise: true,
});

/**
 * Redux actions for deleting a handbook by state.
 *
 * @type {Object}
 */
export const deleteHandbookMhrById = createActions('handbooksMhr', 'DELETE_BY_ID', {
    asPromise: true,
});

/**
 * Redux actions for fetching the list of handbooks.
 *
 * @type {Object}
 */
export const fetchArchivedHandbooks = createActions('handbooks', 'FETCH_ARCHIVED_HANDBOOKS', {
    asPromise: true,
});

/**
 * Redux actions for download archived handbook.
 *
 * @type {Object}
 */
export const downloadArchivedHandbook = createActions('handbooks', 'DOWNLOAD_ARCHIVED_HANDBOOK', {
    asPromise: true,
});

/**
 * Redux actions for check if handbook is translated.
 *
 * @type {Object}
 */
export const isHandbookTranslated = createActions('handbooks', 'IS_HANDBOOK_TRANSLATED', {
    asPromise: true,
});

/**
 * Redux actions for upload handbook policy image from editor.
 *
 * @type {Object}
 */
export const uploadHandbookPolicyImage = createActions(
    'handbooks',
    'UPLOAD_HANDBOOK_POLICY_IMAGE',
    {
        asPromise: true,
    }
);
/**
 * Redux actions to get handbook policy images updated.
 *
 * @type {Object}
 */
export const getHandbookPolicyImagesUpdated = createActions(
    'handbooks',
    'GET_HANDBOOK_POLICY_IMAGES_UPDATED',
    {
        asPromise: true,
    }
);

/**
 * Redux actions to get handbook editor options.
 *
 * @type {Object}
 */
export const getFroalaOptions = createActions('handbooks', 'GET_FROALA_OPTIONS', {
    asPromise: true,
});

/**
 * Redux actions to get smart handbook options.
 *
 * @type {Object}
 */
export const getSmartHandbookOptions = createActions('handbooks', 'GET_SMART_HANDBOOK_OPTIONS', {
    asPromise: true,
});

/**
 * Redux actions to get locked preview policy.
 *
 * @type {Object}
 */
export const getHandbookPolicyPreview = createActions('handbooks', 'GET_HANDBOOK_POLICY_PREVIEW', {
    asPromise: true,
});
/**
 * Redux actions for fetching the total documents list handbooks section
 *
 * @type {Object}
 */
export const fetchTotalDocumentsList = createActions('handbooks', 'FETCH_TOTAL_DOCUMENTS_LIST', {
    asPromise: true,
});
/**
 * Redux actions for fetching the documents handbooks reports
 *
 * @type {Object}
 */
export const fetchDocumentsReport = createActions('handbooks', 'FETCH_DOCUMENTS_REPORT', {
    asPromise: true,
});

/**
 * Redux actions for checking the document status
 *
 * @type {Object}
 */
export const checkDocumentDownloadStatus = createActions(
    'handbooks',
    'CHECK_DOCUMENT_DOWNLOAD_STATUS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for downloading the generated handbook
 *
 * @type {Object}
 */
export const downloadGeneratedHandbook = createActions('handbooks', 'DOWNLOAD_HANDBOOK_GENERATED', {
    asPromise: true,
});
