import { MAX_API_CONFIGURATION_EMAIL_RECIPIENTS } from 'constants/company';
import { EXTERNAL_LINKS } from 'constants/app';
import { STATIC_JSON_ORIGIN } from 'constants/api';

/**
 * Default alerts for the application.
 *
 * @type {Object}
 */
const MESSAGES = {
    FORM: {
        CHARACTERS_ALLOWED:
            "Only letters, numbers and these characters are allowed: () - , / ' & # . ! ? @ + _",
        EMPLOYEE_COUNT_MAX_LIMIT: 'Employee Count is required and must be between 0 and 500,000',
        EMPLOYEE_COUNT_NEGATIVE: 'Number of employees per location should not be negative',
        EMPLOYEE_COUNT_NUMBER: 'Employee count must be a number',
        ONLY_LETTERS_ALLOWED: 'Only letters are allowed.',
        RESELECT_ERROR_MESSAGE:
            'The current value added is not valid. Please reselect the value from the dropdown menu.',
        NAME: "Only letters and these characters are allowed: - '",
        FORMAT_PHONE_NUMBER_ALLOWED:
            '(123) 456-7890 or (123) 456-7890 ext. 87890 format is required.',
        FEDERAL_EIN_ALLOWED: 'Field must be 9 digits and only numbers.',
        CHOOSE_INDUSTRY: 'Please select the industry that is the closest match to yours.',
        CHOOSE_CONFIGURATION: 'Please select a configuration for this company.',
        USERNAME:
            "Make sure you use only letters, numbers, and these characters @ * + - | _ !' , . Sorry, spaces are not allowed.",
        EMAIL: 'Please enter a valid email address.',
        HANDBOOK_NAME_INVALID_CHARACTERS:
            'Slashes (\u002f\u005c) are not allowed in the handbook name.',
        MAX_LENGTH: 'Should not be longer than ${maxLength} characters',
        URL: 'Invalid URL',
        VALID_URL_TEXT: 'Enter valid URL',
        SELECT_LAW_ALERTS_STATES: 'Select at least federal or one state for your Law Alerts',
        WELCOME_EMAIL_MAX_LENGTH: 'Limit ${maxLength} number of characters.',
        ZIP_CODE: 'Zip number should be between 5 and 9 characters max',
        SIC: 'SIC should be 4 characters',
        NAICS: 'NAICS should be 6 characters',
        INDUSTRY_DESCRIPTION: 'Should not be longer than 255 characters.',
        QUESTION_CHAR_LIMIT: 'Should not be longer than 1000 characters.',
        ONLY_NUMBERS_ALLOWED: 'Only numbers are allowed',
        ANNUAL_AVERAGE_NUMBER_OF_EMPLOYEES:
            'Annual average number of employees should be up to 25000',
        DAYS_OSHA: 'should be 6 characters',
        TOTAL_HOURS_WORKED_BY_ALL_EMPLOYEES: 'should be 10 characters',
        REQUIRED_FIELD: 'This field is required.',
        BLANK_ID_FIELD: 'This field cannot be empty.',
        TOO_SHORT: 'Too Short',
        FIELD_NUMERIC: 'should be numeric',
        INCORRECT_EMAIL: 'Invalid email address. Please correct and try again.',
        API_CONFIGURATION_MAX_EMAIL_RECIPIENTS: `You can enter only up to ${MAX_API_CONFIGURATION_EMAIL_RECIPIENTS} email addresses`,
        PHONE_SIZE_ALLOWED: 'Phone number must be between 10 and 25 characters.',
        DIRECT_PHONE_SIZE_ALLOWED: 'Direct phone number must be between 10 and 25 characters',
        CELL_PHONE_SIZE_ALLOWED: 'Cell phone number must be between 10 and 25 characters',
        EMPLOYEE_VALIDATION_MESSAGE: 'Employee Count is required and must be between 0 and 500,000',
        LOCATION_EMPTY_MESSAGE: 'Location name cannot be empty',
        LOCATION_NAME_LIMIT: 'Location Name must not exceed 80 characters',
        VALID_ZIP_CODE_MSG: 'Zip code is required and must be 5 digits',
        CHOOSE_INDUSTRY_MSG: 'Please choose an Industry',
        ADDRESS_1_MAX_LENGTH: 'Address must not exceed 150 characters',
        ADDRESS_2_MAX_LENGTH: 'Address must not exceed 50 characters',
        ADDRESS_VALIDATION_ERROR_MESSAGE:
            "Valid characters for address are uppercase letters (A-Z), lowercase letters (a-z), numbers (0-9), period (.), apostrophe ('), dash (-), number (#), at (@), percent (%), ampersand (&), slash (/), comma(,), parentheses(()), plus(+), colon(:) and spaces. No other characters are allowed.",
        NAME_VALIDATION_ERROR_MESSAGE: "Characters that are not allowed '%', '\\', ';'",
        PRONOUNS_LIMIT: 'Preferred pronouns must not exceed 256 characters',
        SSO_API_CONFIGURATION: {
            API_CONFIG_FIELD_TITLES: {
                API_PLATFORM: 'API Platform',
                EXTERNAL_ID: 'External Id',
                USER_NAME: 'User Name',
                PASSWORD: 'Password',
                URL_ENDPOINT: 'URL endpoint',
                CLIENT_EMAIL_ADDRESS: 'Client Email address(es)',
            },
            API_CONFIG_TITLE: 'API Configuration',
            PAGE_CAPTION: 'SSO/API Configuration',
        },
        SSO_API_OPTIONS_CONFIGURATION: {
            TITLE: 'SSO/API Options',
            ROLE_SETTINGS: {
                TITLE:
                    'Note: Welcome Email settings under Communications must be enabled for this feature to function.',
                CURRENT_STATUS: 'Current status: ${currentStatus}',
            },
            ENABLED: 'Enabled',
            DISABLED: 'Disabled',
            TOGGLE: {
                WELCOME_EMAIL_SSO: 'Welcome Email: SSO',
                WELCOME_EMAIL_DIRECT: 'Welcome Email: Direct',
            },
            USER_SETTINGS: {
                DESCRIPTION: {
                    DESCRIPTION_1:
                        'All data on the user details and My Profile pages are read-only except in the subscription and sign-in override settings.',
                    DESCRIPTION_2:
                        'The partner can still update their own partner company’s users.',
                },
            },
        },
        CUSTOM_API: {
            API_PLATFORM_DEFAULT: 'PrismHR',
        },
        IMPORT_CLIENT: {
            TITLE: 'Import Clients',
            IMPORT_CLIENT_REQUIREMENT_TITLE: 'Import Requirements',
            NOTE_1:
                'Note: It’s not recommended to assign clients the master configuration.<br> Companies assigned to the master configuration have access to and are billable for all products included in the master configuration.<br> Assigning your clients to an <a href="/help-center/system-administration-partner" target="_blank">alternate configuration</a> is the best practice.',
            NOTE_2: 'Questions about importing? Visit the HELP_CENTER for videos and other guides.',
            HELP_CENTER: 'Help Center',
            IMPORT_REQUIREMENT: [
                {
                    id: 'IMPORT_REQUIREMENT_1',
                    text: 'For best results, follow these guidelines for your data upload.',
                },
                {
                    id: 'IMPORT_REQUIREMENT_2',
                    text:
                        'Note: It’s not recommended to assign clients the master configuration.<br> Companies assigned to the master configuration have access to and are billable for all products included in the master configuration.',
                },
                {
                    id: 'IMPORT_REQUIREMENT_3',
                    text: `Assigning your clients to an <a href="/help-center/system-administration-partner" target="_blank">alternate configuration</a> is the best practice.`,
                },
            ],
            IMPORT_LIMITATIONS: {
                TITLE: 'Limitations',
                LIMITATIONS: [
                    {
                        id: 'IMPORT_LIMITATIONS_1',
                        text:
                            'Do not include more than 500 rows of data in the CSV (excluding header row fields).',
                    },
                    {
                        id: 'IMPORT_LIMITATIONS_2',
                        text: 'Do not change the column headings titles.',
                    },
                ],
            },
            REQUIRED_FIELDS: {
                TITLE: 'Required Fields',
                REQUIRED_FIELDS_ROWS: [
                    { ROW: ['CLIENT_NAME', 'DISPLAY_NAME', ''] },
                    { ROW: ['CITY', 'STATE', 'ZIP'] },
                    { ROW: ['INDUSTRY', 'COMPANY_SIZE', 'CONFIGURATION_NAME'] },
                ],
            },
            DATA_FORMATTING: {
                TITLE: 'Data Formatting',
                DATA_FORMATTING_FIELDS: [
                    {
                        TITLE: 'Client and Display Name:',
                        REQUIREMENTS: [
                            {
                                id: 'CLIENT_DISPLAY_NAME_1',
                                text: 'CLIENT_NAME must be unique.',
                            },
                            {
                                id: 'CLIENT_DISPLAY_NAME_2',
                                text: 'DISPLAY_NAME does not need to be unique.',
                            },
                            {
                                id: 'CLIENT_DISPLAY_NAME_3',
                                text: 'Special characters (such as !@#$%^&*_+!) are not allowed.',
                            },
                        ],
                    },
                    {
                        TITLE: 'Phone and Zip:',
                        REQUIREMENTS: [
                            {
                                id: 'PHONE_ZIP_1',
                                text:
                                    'Phone format: (123) 456-7890, 123-456-7890 or (123) 456-7890 ext. 87890.',
                            },
                            {
                                id: 'PHONE_ZIP_2',
                                text: 'Zip should be numbers only and is limited to 5 characters.',
                            },
                        ],
                    },
                    {
                        TITLE: 'Configuration:',
                        REQUIREMENTS: [
                            {
                                id: 'CONFIGURATION_2',
                                text:
                                    'Note: It’s not recommended to assign clients the master configuration.<br> Companies assigned to the master configuration have access to and are billable for all products included in the master configuration.',
                            },
                            {
                                id: 'CONFIGURATION_3',
                                text: `Assigning your clients to an <a href="/help-center/system-administration-partner" target="_blank">alternate configuration</a> is the best practice.`,
                            },
                        ],
                    },
                ],
            },
            IMPORT_CLIENT_LEFT_SIDE_BAR: {
                TITLE: 'Use this feature to upload multiple client companies in bulk.',
                IMPORTANT: 'Important: ',
                SUB_TITLE:
                    'Review and follow the Import Requirement guidelines below to ensure a successful import.',
            },
        },
        PARTIAL_SUCCESS: {
            IMPORT_OUTCOME: 'Import Outcome',
            SOME_ROWS_FAILED: 'Some rows failed to import.',
            SUCCESS_IMPORT: 'The import was successful for ${successfullRecords} ${renderRow}.',
            SUCCESSFULLY_IMPORTED: 'Successfully imported ${successfullRecords} ${renderRow}.',
            FAILED_IMPORT: 'The import failed for ${failedRecords} ${renderRow}.',
            DESCRIPTION:
                'Click the Download Import Issues button to see the rows that failed and the reason why.',
            FAILED_ROWS: 'To import the rows that failed:',
            FAILED_ROW_INSTRUCTION: [
                'Click the Download Import Issues button to download the <span style="color: #111111">Clients-Import-Result</span> file.',
                'Correct the errors in the <span style="color: #111111">Clients-Import-Result</span> file.',
                'Upload the corrected <span style="color: #111111">Clients-Import-Result</span> file.',
            ],
            NOTES: [
                'Only the failed rows will be in the download.',
                'Successfully imported rows should not be re imported. ',
            ],
        },
        IMPORT_FAILED: {
            TITLE: 'All rows failed to import.',
            DESCRIPTION: 'The import failed for all rows.',
        },
        DOWNLOAD_TEMPLATE: {
            TITLE: 'Step 1: Download the Import Clients Template',
            DESCRIPTION: 'Download the .CSV template file and add your data into this template.',
            LAST_DATE: 'Template last updated: 08/19/2022',
            DOWNLOAD_TEMPLATE_BUTTON: 'Download Template',
        },
        IMPORT_SUCCESSFUL: {
            TITLE: 'Import Successful',
            NEW_IMPORT: 'New Import',
        },
        HELP_CENTER: 'Help Center',
        GUIDES_AND_VIDEOS: 'Guides and videos for import and other administrative tasks',
        BACK_TO_IMPORT: 'Back to Import',
        DOWNLOAD_IMPORT_RESULTS_BUTTON: 'Download Import Results .CSV',
        USER_DETAILS: {
            LAST_SIGN_IN_DESCRITPION: 'Last signed in to the Mineral Platform on ${lastSignInAt}.',
            NOT_YET_SIGN_IN_DESCRITPION: 'This user has not signed in to the Mineral Platform.',
            COMPANY_DISCLAIMER_TEXT:
                'Your company information is updated automatically. Please request changes through your partner’s application.',
            USER_DISCLAIMER_TEXT:
                'Your user information is updated automatically. Please request changes through your partner’s application.',
        },
        UPLOAD_FIELD: {
            TITLE: 'Step 2: Upload File and Import',
            DESCRIPTION:
                'When your file is ready, choose the file to import and click the Import button.',
        },
        CHARACTERS_REMAINING: `Characters remaining: `,
        ACTIONS: {
            SAVE_LABEL: 'Save Settings',
        },
        ADMINISTRATION_OPTIONS: {
            ADMINISTRATION_OPTIONS_TYPES: {
                ALLOW_CLIENT_ADMIN_CREATE_CLIENT_ADMIN: {
                    TITLE: 'User Options',
                    TITLE_DESCRIPTION:
                        'Allow Client Admins to create additional Client Admins for their company.',
                    DESCRIPTION:
                        'When this option is enabled, client admins can create new client admins on their own company.',
                },
                BENEFIT_DOCUMENT_CREATOR_ENABLE_CLIENT_CREATE: {
                    TITLE: 'Company Options',
                    TITLE_DESCRIPTION: 'Allow Client Admins to Create Benefits Documents',
                    DESCRIPTION:
                        'When this option is enabled, all existing and new client admins with the Benefits Document Creator product in their assigned configuration can create benefits documents (such as POP, HRA, FSA, and ICHRA) for their company.',
                },
            },
            TOAST_SUCCESS: 'The administration options have been saved',
            ADMINISTRATION_TYPE: 'ALLOW_CLIENT_ADMIN_CREATE_CLIENT_ADMIN',
            BENEFITS_DOCUMENT_CREATOR_TYPE: 'BENEFIT_DOCUMENT_CREATOR_ENABLE_CLIENT_CREATE',
            LAST_UPDATED_BY: 'Last Updated by ${latestModifiedByUser}, ${latestModifiedOn}',
            ERROR_MSG:
                'Client admins creation of other client admins will be disabled except in the case of client admins who have access to the ACA Reporting product.',
            CONFIRMATION_MODAL: {
                TITLE: 'Remove the ability to create benefits documents for all clients',
                DESCRIPTION:
                    'If you proceed, <b>all clients</b> will lose their ability to create their own benefits documents. If you only want to remove this ability for certain clients, do so from their individual company detail pages.<br><br>If you choose to proceed, please note that clients will only be able to view their previously created benefits documents.',
            },
        },
    },
    ALERT: {
        CONFIGURATION: {
            CONFIG_OVERWRITE_ONE:
                'The selected configuration will overwrite any previously saved configuration for this company.',
            CONFIG_OVERWRITE:
                'The selected configuration will overwrite any previously saved configurations for the selected companies.',
            CONFIG_SELECT: 'One configuration must be selected in order to save companies.',
            EXISTS_SKU_BCS_ELITE:
                'If you change to a configuration without Benefits Documents Creator Products and Services included, any BCS document will be removed.',
            EXISTS_SKU_PEO_HANDBOOK:
                'If you change to a configuration without PEO Handbook Products and Services included, ALL previously created PEO handbooks will no longer be available.',
            EXISTS_SKU_HANDBOOKS:
                'If you change to a configuration without Employee Handbooks Builder Products and Services included, ALL previously created handbooks will no longer be available.',
            EXISTS_SKU_HANDBOOKS_MULTISTATE:
                'If you change to a configuration without Employee Handbooks Multi State Products and Services included, ALL previously created multi-state handbooks will no longer be available.',
            EXISTS_SKU_HANDBOOKS_ONESTATE:
                'If you change to a configuration without Employee Handbooks One State Products and Services included, ALL previously created one-state handbooks will no longer be available.',
            EXISTS_SKU_HANDBOOKS_FEDERAL:
                'If you change to a configuration without Employee Handbooks Federal Products and Services included, ALL previously created federal handbooks will no longer be available.',
            SELECT_COMPANY: 'Please select at least one company to assign a configuration.',
            BATCH_HANDBOOK:
                'One or more of the selected companies will be moved to a configuration that will result in the deletion of the following types of handbooks:',
        },
        COMPANY: {
            VALID_ZIP_CODE: 'Please provide a valid Zipcode',
            DELINQUENT_COMPANY:
                'This flag will turn on delinquent pop up for your partners and REs.',
            DELINQUENT_FRIENDLY_REMINDER: 'Friendly Reminder',
            AR_UPDATING_COMPANY_TO_DEMO:
                'Updating this configuration may deactivate certain premium products for this company and some clients. Do you wish to proceed?',
        },
        IMPORT: {
            FAILED:
                'The import process failed. Please check the CSV file download. To upload successfully, correct the errors in the file, delete the header rows, and re-upload the final document.',
            PARTIAL_FAILED: {
                REASON:
                    'The import failed for one or more of the records in the CSV file. Please check the downloaded <strong>Import Result</strong> file for the rows that have an issue.',
                SOLUTION: {
                    TITLE: 'To resolve you can:',
                    SOLUTION1:
                        'Fix the issues for those records in the Import Results file, save, and import.',
                    SOLUTION2:
                        'Fix the issues in the original file and upload the CSV for those specific rows. <strong>Do not reimport rows that were successful.</strong>',
                },
            },
        },
    },
    LOADING: {
        ACTIVATING_AFFILIATE: 'Activating affiliate',
        ACTIVATING_PROSPECT: 'Activating prospect',
        BULK_IMPORT_PROSPECT: 'Bulk importing prospects',
        CREATING_CUSTOM_POLICY: 'Creating custom policy',
        CREATING_USER: 'Creating user',
        CREATING_TICKET: 'Creating case',
        CREATING_BANNER: 'Creating banner',
        LOADING_ASSESSMENT_TEST: 'Loading HR Assessment',
        DEACTIVATING_AFFILIATE: 'Deactivating affiliate',
        DEACTIVATING_PROSPECT: 'Deactivating prospect',
        DELETING_LOCATION: 'Deleting location',
        DELETE_BROKEN_LINK: 'Removing broken link entry',
        DELETE_PROSPECT: 'Deleting prospect',
        DELETE_PROSPECTS: 'Deleting prospects',
        DEFAULT: 'Loading data',
        DOWNLOADING: 'Downloading',
        END_IMPERSONATION: 'Ending impersonation',
        ENROLL_IN_COMMUNICATIONS: 'Enrolling in communications',
        FETCH_ACTIVITY_STREAM: 'Fetching activity stream',
        FETCH_ACTIVITY_STREAM_EVENT_ID: 'Fetching activity stream event id',
        FETCH_AFFILIATE: 'Fetching affiliate',
        FETCH_AFFILIATES: 'Fetching affiliates',
        FETCH_BANNERS: 'Fetching banners',
        FETCH_BROKEN_LINKS: 'Loading broken links',
        FETCH_DASHBOARD_CONFIG: 'Loading Dashboard',
        FETCH_LOCATIONS: 'Loading locations',
        FETCH_CLIENTS: 'Loading clients',
        FETCH_COMMENTS: 'Loading comments',
        FETCH_COMPANY: 'Loading company',
        FETCH_COMPANIES: 'Loading companies',
        FETCH_CONFIGURATION: 'Loading configuration',
        FETCH_CUSTOM_POLICY: 'Loading custom policy',
        FETCH_EMAIL: 'Loading Email',
        FETCH_ESTABLISHMENT: 'Fetching Establishments',
        FETCH_HANDBOOK: 'Fetching Handbook',
        FETCH_PARTNERS: 'Loading Partners',
        FETCH_ROLE: 'Loading Role',
        FETCH_SKU: 'Fetching Products and Services',
        FETCH_TICKET: 'Loading Case',
        FETCH_TICKETS: 'Loading Cases',
        FETCH_USER: 'Loading user',
        FETCH_USERS: 'Loading users',
        FETCH_PROSPECT: 'Loading prospect',
        IMPERSONATE_USER: 'Impersonating user',
        LOGGING_IN: 'Signing in...',
        LOGGING_OUT: 'Signing out...',
        SAVE_COMPANY: 'Save company',
        SAVE_USER: 'Saving user',
        UPDATE_AFFILIATE: 'Updating Affiliate',
        UPDATE_CONFIGURATION: 'Updating Configuration',
        UPDATE_CUSTOM_POLICY: 'Updating custom policy',
        UPDATE_DASHBOARD_CONFIG: 'Updating Dashboard Config',
        UPDATE_INFORMATION: 'Updating information',
        UPLOAD_ATTACHMENTS: 'Uploading attachment(s)',
        VALIDATE_ZIP_CODE: 'Validate zip code',
        SENDING_EMAIL: 'Sending email',
        SENDING_RESET_EMAIL: 'Sending Reset email',
        SENDING_ASSESSMENT_TEST: 'Sending HR Assessment',
        SENDING_PROSPECT_REPORT: 'Sending prospect report',
        UPLOADING_LOGO: 'Uploading logo',
        DELETING_BANNER: 'Deleting banner',
        SAVE_JOB_DESCRIPTION: 'Saving job description',
        UPDATING_WELCOME_EMAIL: 'Updating Welcome Email',
        SAVE_SETTING: 'Saving Setting',
        POLICY_ADVISOR: 'Loading Policy Advisor',
        PREVIEW: 'Loading Preview',
        CREATING_PROSPECT: 'Creating Prospect',
        CREATING_CONFIGURATION: 'Creating configuration',
        LOADING: 'Loading...',
    },
    SUCCESS: {
        AFFILIATED_UPDATED: 'Affiliate updated successfully',
        AFFILIATED_ACTIVATED: 'Affiliate activated successfully',
        AFFILIATED_DEACTIVATED: 'Affiliate deactivated successfully',
        BRANDING_COLORS_RESTORED: 'Color settings restored successfully',
        BRANDING_COLORS_UPDATED: 'Color settings updated successfully',
        BRANDING_CONTACT_US_RESTORED: 'Default settings restored successfully',
        BRANDING_CONTACT_US_UPDATED: 'Contact settings updated successfully',
        BRANDING_CUSTOM_LOGIN_WIDGET_RESTORED: 'Sign-in Widget settings restored successfully',
        BRANDING_CUSTOM_LOGIN_WIDGET_UPDATED: 'Sign-in Widget settings updated successfully',
        BRANDING_LOGIN_LOGOUT_RESTORED: 'Default Sign-in / Sign-out settings restored successfully',
        BRANDING_LOGIN_LOGOUT_UPDATED: 'Sign-in / Sign-out settings updated successfully',
        BRANDING_LOGO_RESTORED: 'Default logo restored successfully',
        BRANDING_LOGO_UPDATED: 'Logo updated successfully',
        BROKEN_LINK_DELETED: 'Link deleted successfully',
        BULK_DELETE_PROSPECTS: 'Prospects deleted successfully',
        BULK_ENROLL_PROSPECT: 'Prospect enrolled successfully',
        BULK_SEND_FITNESS_TEST: 'Fitness test successfully',
        CLIENT_CONFIGURATION_UPDATED:
            'Updated Successfully. The change will be reflected on the platform within a few minutes.',
        CONFIGURATION_UPDATED: 'Configuration updated successfully',
        CONFIGURATION_CREATED: 'Configuration created successfully ',
        CONFIGURATION_DELETED: 'Configuration deleted successfully ',
        COMPANY_CREATED: 'Company created successfully',
        COMPANY_LICENSES_UPDATED: 'Company licenses updated.',
        COMPANY_UPDATED: 'Company updated successfully',
        COMPANY_MOVED: 'Company moved successfully',
        COMPANY_DEACTIVATED: 'Company deactivated successfully',
        COMPANY_STATUS: 'Company status changed successfully',
        DELETE_PROSPECT: 'Prospect deleted successfully',
        LOCATION_DELETED: 'Location deleted successfully',
        USER_CREATED: 'One user was created.',
        UPDATE_BANNER: 'Banner updated successfully',
        USER_EXPORT: 'Users exported successfully',
        CREATE_BANNER: 'Banner created successfully',
        DELETE_BANNER: 'Banner deleted successfully',
        HR_CONTACT_UPDATED: 'Support/HR Contact Information updated successfully',
        IMPORT_CLIENT: 'Import clients successfully',
        IMPORT_CONTACTS: 'Import contacts successfully',
        IMPORT_LOCATIONS: 'Import locations successfully',
        NEWSLETTER_SUBSCRIPTION: 'Newsletter created successfully',
        NOTIFICATIONS_SETTINGS: 'Notifications settings updated successfully',
        ROLE_CREATED: 'Role created successfully',
        ROLE_UPDATED: 'Role updated successfully',
        ROLE_DELETED: 'Role deleted successfully',
        POLICY_SAVED: 'Policy Saved',
        POLICY_AUTOMATICALLY_SAVED: 'Policy Automatically Saved',
        PROFILE_UPDATED: 'Updated account information',
        PROSPECT_ACTIVATED: 'Prospect has been activated',
        PROSPECT_DEACTIVATED: 'Prospect has been deactivated',
        PROSPECT_ADD: 'Prospect created successfully',
        PROSPECT_EDIT: 'Prospect updated successfully',
        PROSPECT_DELETE: 'Prospect deleted successfully',
        PROSPECT_ADD_MULTIPLE: 'Multiple Prospect added successfully',
        SKU_CREATED: 'Products and Services created successfully',
        SKU_UPDATED: 'Products and Services updated successfully',
        SSO_API_CONFIGURATION: 'The SSO/API configuration have been saved',
        SSO_API_OPTIONS: 'The SSO/API options have been saved',
        SSO_CONFIGURATION: 'The SSO configuration have been saved',
        CUSTOM_API: 'The Custom API configuration have been saved',
        DELETE_CUSTOM_API: 'The Custom API configuration successfully deleted',
        WELCOME_EMAIL_UPDATED: 'Welcome email updated',
        WELCOME_EMAIL_SENT: 'Successfully Sent Welcome email',
        EMAIL_SENT: 'Email Sent Successfully',
        USER_SETTINGS_SAVED: 'User settings were saved',
        USER_DEACTIVATED: 'User has been deactivated',
        USER_ACTIVATED: 'User has been activated',
        HR_FITNESS_TEST_CREATED: 'HR Assessment successfully created',
        HR_FITNESS_TEST_SENT: 'HR Assessment successfully sent',
        HR_ASSESSMENT_TEST_SENT: 'HR Assessment successfully sent',
        SAVED_SUCCESSFULLY: 'Setting saved successfully',
        ESTABLISHMENT_CREATED: 'One establishment was created',
        ESTABLISHMENT_UPDATED: 'One establishment was updated',
        ESTABLISHMENT_DELETED: 'One establishment was deleted',
        CASE_CREATED: 'One case was created',
        CASE_UPDATED: 'One case was updated',
        CASE_DELETED: 'One case was deleted',
        SEND_FITNESS_TEST: 'HR Assessment sent successfully',
        PROSPECT_REPORT_SUCCESS: 'Prospect report sent successfully',
        DOWNLOAD_SUCCESS: 'Download successful',
        ADD_LOCATION: 'Successfully added a new location',
        EDIT_LOCATION: 'Successfully updated location',
        UPDATE_PASSWORD: 'Successfully updated password',
        ADDING_INTELLIGENCE_TODO: 'Action item(s) successfully added to the to-do list!',
    },
    NOT_RECORD_FOUND: 'No records found for this request',
    ERROR: {
        ERROR_LOADING_DATA: 'Error loading data',
        ERROR_LOADINDG: 'Error Loading',
    },
    FAILED: {
        BULK_DELETE_PROSPECT: 'Failed to delete selected prospect(s)',
        BULK_ENROLL_PROSPECT: 'Failed to enroll selected prospect(s)',
        BULK_SEND_FITNESS_TEST: 'Failed to send HR Assessment',
        CREATE_TICKET: 'Failed to create case',
        DELETE_PROSPECT: 'Failed to delete the prospect',
        FOLLOW_UP_TICKET: `The company location originally associated with this case has changed.
        Because of this change, follow up questions for this company location can no longer be accepted.
        Please submit a new case.`,
        FETCH_BANNERS: 'Failed to load banners',
        HR_CONTACT_UPDATE: 'Failed to update Support/HR Contact Information',
        NOTIFICATIONS_SETTINGS: 'Failed to update notifications settings',
        SEND_FITNESS_TEST: 'Failed to send HR Assessment',
        CREATE_CONFIGURATION: 'Failed to create configuration',
        UPLOAD_ATTACHMENT: 'Failed to upload attachments',
        JAVASCRIPT_BLOCKED_CLOSE:
            'Your browser has blocked javascript on this page. Please close your browser tab directly to exit.',
    },
    MODAL: {
        ACTIONS: {
            CANCEL: 'Cancel',
            SEND: 'Send',
            NEXT: 'Next',
            DOWNLOAD: 'Download',
            NO: 'NO',
            OK: 'OK',
            YES: 'Yes',
            AGREE: 'I Agree',
            GOBACK: 'Go Back',
            SUBMIT: 'Submit',
            SIGN_OUT: 'Sign Out',
            CONTINUE_SESSION: 'Continue Session',
            DISMISS: 'Dismiss',
            SUBMIT_FEEDBACK: 'Submit Feedback',
            YES_DELETE: 'Yes, Delete',
            APPLY: 'Apply',
            CLEAR: 'Clear',
        },
        CONFIRM: {
            TITLE: {
                ALERT: 'Alert',
                ERROR: 'Error',
                SUCCESS: 'Success',
                WARNING: 'Warning',
                CAUTION: 'Caution',
            },
            ACTIONS: {
                CANCEL: 'Cancel',
                CLOSE: 'Close',
                CONFIRM: 'Confirm',
                NO: 'No',
                OK: 'OK',
                YES: 'Yes',
            },
        },
        EHS: {
            SAFETY_MANUAL_TEMPLATE: {
                CONTENT: 'Would you like to download the Safety Manual Template?',
                TITLE: 'Safety Manual Template',
            },
        },
        DEPENDENT_SKU_WARNING: {
            description: "These Products and Services are required to enable 'SKU_NAME':",
        },
        UPDATE_BANNER: {
            TITLE: 'Updating banner for',
        },
        CREATE_BANNER: {
            TITLE: 'Create banner for',
        },
        OSHA: {
            OSHA300: 'Download OSHA Form 300/300A',
            OSHA301: {
                TITLE: 'Download OSHA Form 301',
                CONTENT: 'Do you want to download the OSHA Form 301?',
                FILE_URL: `${STATIC_JSON_ORIGIN}/downloads/osha/Form301.pdf`,
            },
            OSHA_REPORT: 'Download OSHA Report',
            RECORDABLE_INCIDENT: {
                TITLE: 'Guide to recordability of cases under OSHA',
                NOTE:
                    'Note: An OSHA case must involve a death, an illness, or an injury to an employee.',
                CASE_RECORDED: 'Case must be recorded.',
                CASE_NOT_BE_RECORDED: 'Case is not to be recorded.',
            },
            OSHA300_300A_DOWNLOAD: {
                OPTIONS: {
                    DOWNLOAD_OSHA_FORM_300: 'Download OSHA Form 300 (.pdf)',
                    DOWNLOAD_OSHA_FORM_300A_POSTING: 'Download OSHA Form 300A (.pdf) for Posting',
                    DOWNLOAD_OSHA_FORM_300A_ELECTRONIC_SUBMISSION:
                        'Download OSHA Form 300A (.csv) for electronic submission',
                },
                INTRO_TEXT:
                    'OSHA Form 300 - Log of Work-Related Injuries and Illnesses. Please select an establishment and year of the log that you want to view or update.',
                NOTE: 'Note: Near miss cases will be excluded from downloads.',
            },
            OSHA_REPORT_DOWNLOAD: {
                OPTIONS: {
                    DOWNLOAD_REPORT_PDF: 'Download Report (.PDF)',
                    DOWNLOAD_DATA_CSV: 'Download Raw Data (.CSV)',
                },
                EROR_RANGE_DATES: 'There is no data available with the selected date range.',
            },
        },
        BANNER_AD_TERMS_SERVICE: {
            TITLE: 'Terms of Service',
            SUB_TITLE: 'Mineral',
            SUB_HEADER: 'In-Product Marketing Terms and Conditions',
            DESCRIPTION: `These Mineral In-Product Marketing Terms and Conditions ("Marketing Terms") govern Your ability to use and engage Mineral's In-Product Marketing Services (defined below) on the Mineral Platform located at www.trustmineral.com and apps.trustmineral.com (collectively, the “Mineral Platform”), provided by Mineral, Inc., a subsidiary of Mitratech Holdings, Inc., (individually referred to as “Mineral” and “Mitratech” respectively, and collectively referred to as "Company") within its products or services ("Services").<br><br>
            By using the In-Product Marketing Services offered by Mineral, You acknowledge that You have read, understood, and agree to be bound by these Marketing Terms, and any additional terms and conditions provided by Mineral in the use and/or resale of the Services. If You do not agree with any part of these Marketing Terms, You may not use the In-Product Marketing Services.<br><br>
            <ol style="padding: 0 16px 0 16px;margin-bottom:0">
                <li><span style=font-weight:500>In-Product Marketing Services.</span> As a Mineral reseller partner, You may use within the Mineral Platform various marketing materials to create in-platform package upgrade banners, marketing materials and promotions (“Banners”) to help promote the relationship between Mineral and You as a partner of Mineral, Mineral products and services, and Mineral package upgrades to Your clients that have access to Mineral services and products (“In-Product Marketing Services”). To assist in creating the in-product Banners, the In-Product Marketing Services provide access to materials such as, without limitation, campaigns, offers, advertisements, product highlights, marketing materials, sponsored content, branding, images, banners, and advertisements, which must be used according to the Mineral marketing guidelines (“Marketing Materials”).</li>
                <br>
                <li><span style=font-weight:500>Eligibility.</span> Participation in the In-Product Marketing Services is limited to current Mineral reseller partners. For the purpose of these Marketing Terms, “You” and “Your” shall refer to a currently contracted reseller partner with Mineral. Mineral reserves the right to update the eligibility requirements at any time and without notice to You.</li>
                <br>
                <li><span style=font-weight:500>Restricted Uses.</span> You shall not:
                <ol type="a">
                    <li>Copy, reverse engineer, decompile or disassemble the Marketing Materials;</li>
                    <li>Modify, combine, adapt, create derivative works from the Marketing Materials;</li>
                    <li>Share, sub-license, license or transfer any rights granted herein to any subsidiary, partner, affiliate or client;</li>
                    <li>Sell, save, commercialize or exploit the Marketing Materials or any of the parts thereof;</li>
                    <li>Hold Yourself out as the creator of the Marketing Materials;</li>
                    <li>Use any of the Marketing Materials beyond the purpose outlined herein, including without limitation, using the Marketing Materials to promote, market or offer non-Mineral products and/or services;</li>
                    <li>Upload, post, publish, or display in the in-platform Banners any data, information, content, material or intellectual property that You do not own or have the legal right and/or license to use or that is a material misrepresentation, outdated or inaccurate;</li>
                    <li>Use the Marketing Materials and/or the In-Product Marketing Services for illegal purposes, including on behalf of others;</li>
                    <li>Access the Platform and/or the Marketing Materials in order to build a competitive product or service, to build a product or service using similar ideas, features, functions or graphics of the Product, or to copy any ideas, features, functions or graphics of the Product, or allow a third party to do so using Your our Your Users’ accounts;</li>
                    <li>Remove, alter, cover, or obfuscate any proprietary notices, labels, trademarks, or service marks on the Platform or any part thereof, unless agreed upon otherwise in writing between the parties;</li>
                    <li>Create banners and/or use the In-Product Marketing Services and/or the Marketing Materials in a way that violates or infringes upon the rights of a third party, including those related to contract, intellectual property, privacy, or publicity; and</li>
                    <li>Create banners and/or use the In-Product Marketing Services and/or the Marketing Materials in any manner that could damage, tarnish, disparage, disable, overburden, or impair the Platform and/or the Company’s services, products, reputation, brand and/or goodwill.</li>
                </ol>
                </li>
                <br>
                <li><span style=font-weight:500>Take-Down Right.</span> Mineral reserves the exclusive right to take down the Marketing Materials used by You in the Platform banner, with notice to You. Further, we have the right to take down Your banner in our sole discretion at any time and with notice to You</li>
                <br>
                <li><span style=font-weight:500>Mineral Intellectual Property and Licenses.</span> The Marketing Materials provided by Mineral, in their entirety and parts thereof, are proprietary to Mineral and are owned exclusively by Mineral. You are granted a limited, revocable, non-transferrable, non-sublicensable and non-exclusive right to use the Marketing Materials solely for the purposes outlined herein. You shall not copy, share, recreate, publicly display, disseminate, commercialize, sell, license, transfer, modify, distribute, or reverse engineer the Marketing Materials or any part thereof for purposes beyond these Marketing Terms. You shall not hold Yourself out to be the creator, author or owner of the Marketing Materials. You shall not use any of Minerals Marks (as outlined in the online Terms of Service located at https://trustmineral.com/terms-of-service/) for purposes beyond those outlined herein. The licenses granted herein will automatically terminate if You are no longer a Partner of Mineral.</li>
                <br>
                <li><span style=font-weight:500>Termination and Modification.</span> The Company reserves the right to modify, suspend, or terminate the In-Product Marketing Services at any time, with or without notice, in its sole discretion. The Company also reserves the right to disqualify participants who violate these Terms or engage in fraudulent, deceptive, or abusive behavior.</li>
                <br>
            </ol>
            <ol style="padding: 0 16px 0 16px; margin-bottom: 0">
            <li><span style=font-weight:500>DISCLAIMERS.</span> MINERAL MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE MARKETING MATERIALS, ITS ACCURACY, RELEVANCY, SUCCESS, FITNESS FOR A PARTICULAR PURPOSE, MARKETABILITY, MERCHANTABILITY, AND/OR APPLICABILITY. THE MARKETING MATERIALS ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. THE COMPANY DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT.</li>
            <br>
            <li><span style=font-weight:500>Limitation of Liability.</span> TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL MINERAL, ITS AFFILIATES, OR THEIR DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, OR AGENTS BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS, REVENUES, CUSTOMERS, OPPORTUNITIES, GOODWILL, USE, OR DATA, YOUR FAILURE TO COMPLY WITH ANY STATE, LOCAL AND/OR FEDERAL LAWS, ORDINANCES, RULES, REGULATIONS AND/OR STATUTES, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING WITHOUT LIMITATION NEGLIGENCE) OR OTHERWISE, ARISING FROM OR IN ANY WAY CONNECTED WITH THESE MARKETING TERMS, EVEN IF ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES, SUCH DAMAGES WERE FORESEEABLE, AND REMEDIES FAIL OF THEIR ESSENTIAL PURPOSE. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF MINERAL, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING FROM OR RELATED TO THESE MARKETING TERMS EXCEED THE AMOUNTS PAID TO MINERAL BY THE RELEVANT CUSTOMER UNDER THE RELEVANT ORDER FORM FOR ACCESS TO OR USE OF THE IN-PRODUCT MARKETING SESRVICES DURING THE TWELVE (12) MONTHS PRECEDING THE EVENT GIVING RISE TO THE CLAIM. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE FOREGOING LIMITATION OF LIABILITY PROVISION SHALL APPLY TO ANY CLAIM ARISING OUT OF OR IN ANY WAY CONNECTED WITH THESE MARKETING TERMS, EVEN IF ANY REMEDIES FAIL OF THEIR ESSENTIAL PURPOSE. No action, regardless of form, arising out of or related to the Product shall be brought by You more than one (1) year after the cause of action has accrued.</li>
            <br>
            <li><span style=font-weight:500>Indemnification.</span> You agree to indemnify, defend, and hold harmless Mineral, its affiliates, officers, directors, employees, and agents from and against any and all claims, lawsuits, demands, penalties, fines, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or in connection with (a) Your use of the In-Product Marketing Services and/or the Marketing Materials; (b) Your breach of these Marketing Terms or violation of applicable law; (c) a dispute between You and Your Client; (d) any claim alleging that Your In-Product banner, marketing, advertisements, offers, information and/or promotions are invalid, inaccurate, infringing, and/or misappropriating of a third party’s rights, titles, and/or interests, including privacy rights, titles, goodwill, interests and/or publicity rights; (e) any claim alleging the materials used in Your banner are illegal, non-compliant with applicable laws, inaccurate, or infringing.</li>
            <br>
            <li><span style=font-weight:500>Governing Law.</span> These Terms shall be governed by and construed in accordance with the laws of California, without regard to its conflict of law principles. You hereby consent to personal jurisdiction in State and/or Federal court in Alameda County, California, and waive all objections to forum and venue, including any claims to forum non conveniens.</li>
            <br>
            <li><span style=font-weight:500>Attorney Fees.</span> You hereby agree that Mineral is entitled to receive reasonable attorney fees and costs if it or its Affiliates are a prevailing party to any claim, dispute, arbitration, litigation, and/or settlement arising from or relating to these Marketing Terms.
            </li>
            <br>
            </ol>
            If you have any questions about these Marketing Terms, please contact your Mineral representative.`,
            BUTTON_LABEL: 'Accept',
        },
        BANNER_AD_UPGRADE_MESSAGE: {
            TITLE: 'Thank you for expressing your interest!',
            DESCRIPTION:
                'To get more information about upgrading your Mineral products and services, please contact your partner (${partnerName}).',
            BUTTON_LABEL: 'OK',
        },
        PUBLISH_DEFAULT_ADS: {
            TITLE: 'Publish ${adName} Ad',
            DESCRIPTION: `If you are ready to publish your ad and make it visible on the platform (or schedule it for your selected date if you’ve chosen that option), select <span style=font-weight:500>Yes, Publish</span>. If you select <span style=font-weight:500>Cancel</span>, you will return to the <span style=font-weight:500>Create Ad</span> page. Your ad will not be visible to clients until you return to publish it.`,
            BUTTON_LABEL: 'Yes, Publish',
        },
        UNPUBLISH_DEFAULT_ADS: {
            TITLE: 'Unpublish ${adName} Ad',
            DESCRIPTION:
                'If you want to remove your ad from the platform, select <span style="font-weight:500">Yes, Unpublish Ad</span>. If you want the ad to remain visible, select <span style="font-weight:500">Exit Without Unpublish</span>. Your ad will remain active for up to one year or until you unpublish it.',
            BUTTON_LABEL: 'Yes, Unpublish Ad',
            CANCEL_LABEL: 'Exit Without Unpublish',
        },
        UNSAVED_CHANGES: {
            TITLE: 'Caution! Unsaved changes',
            DESCRIPTION:
                'Your changes have not been saved. Do you want to stay on the page so that you can save your changes?',
            ACTIONS: {
                CONTINUE: 'Continue without Saving',
            },
        },
        SSO: {
            DESCRIPTION:
                'You are about to change the SSO type. This will delete all the previous SSO details.',
            MINTEL_CORE_TITLE: 'Mineral Intelligence Core Settings',
            MINTEL_CORE_DESCRIPTION: 'Enable Welcome Insights for Core Clients',
            ENABLE_SAVE: 'Yes, Enable',
            DISABLE_SAVE: 'Yes, Disable',
            CANCEL: 'No, Cancel',
            MNL_CORE_SETTINGS_REQUIRED_MSG: 'Partner platform Sign in URL required.',
            CONFIRM: 'Confirm Your Changes',
            ENABLE_MESSAGE: 'Enable Mineral Intelligence Welcome Insights',
            ENABLE_DESCRIPTION_MSG:
                'You are about to enable Mineral Intelligence to generate Welcome Insights to this partner’s clients with the Mineral Core Package.',
            ENABLE_DESCRIPTION_SUMMARY:
                'When enabled, all Welcome Insights and their notifications will be delivered the next business day.',
            DISENABLE_MESSAGE: 'Disable Mineral Intelligence Welcome Insights',
            DISENABLE_DESCRIPTION_MSG: `By disabling this feature, you will stop Mineral Intelligence from sending Welcome Insights to this partner's clients with the Mineral Core Package. Please confirm if you would like to disable Mineral Intelligence from sending welcome insights to this partner's core clients.`,
            MNL_CORE_SETTING_DESC:
                'Enabling this toggle will trigger Mineral Intelligence to send Welcome Insights to clients underneath this partner who have the Core package and have not received a Welcome Insight yet. When enabled, all Welcome Insights and their notifications will be delivered the next business day.',
            DIRECT_SIGN_IN: 'Direct Sign-In',
            SINGLE_SIGN_ON: 'Single Sign-On',
            DESCRIPTION_WHEN_CHANGE_VERIFIED_SSO_DETAILS:
                'Are you sure you want to turn off verified SSO? This will set SSO related information to default.',
        },
        UNSUBSCRIBE: {
            TITLE: 'You Have Successfully Unsubscribed from Mineral',
            DESCRIPTION:
                'You will no longer receive any Mineral newsletters and law alert emails. To resubscribe, please Sign in to your Mineral and go to the My Account menu to modify your subscription settings',
        },
        SEND_TEST_EMAIL: {
            TITLE: 'Send Test Email',
            DESCRIPTION: 'Send a test email for this communication.',
        },
        NEWSLETTER_SUBSCRIPTION: {
            TITLE: 'Manage Newsletter Subscriptions',
        },
        HR_FITNESS_TEST: {
            ACTIONS: {
                OK: 'Go to results',
            },
        },
        ADD_SKU: {
            CONFIRM: {
                DESCRIPTION:
                    'Do you want to add the selected Products and Services to this configuration?',
            },
            TITLE: 'Please select the products to add to this configuration',
            CONFIRM_SELECTIONS: 'Confirm Selections',
        },
        REMOVE_SKU: {
            CONFIRM:
                'Are you sure you want to remove ${name} Products and Services? <br/> ${skuDeletionMessage}',
        },
        PACKAGE: {
            ADD_SKU_PACKAGE: {
                DESCRIPTION:
                    '${newAddedSkuCount} New Products and Services will be added to this configuration.',
            },
            PACKAGE_CHANGE: {
                CURRENT_PACKAGE: 'License: ',
                PREV_PACKAGE: 'Previous value: ',
                CHECKBOX_LABEL:
                    'Confirm license change: You’re about to change the license(s) used by this configuration based on the products and services you’ve added or removed. You will continue to be billed for all licenses for the duration of your current contract. For more information, please review the terms of your contract.',
            },
        },
        DUPLICATE_SKU: {
            TITLE:
                'The following companies already have the selected Products and Services in their Add-on configurations. Before you can continue to add the Products and Services to the Master configuration, make sure you first remove the Products and Services from the Add-on configurations for these companies.',
            TITLE_SPONSORSHIP:
                'The following companies already have the selected Products and Services in their Add-on configurations.',
        },
        SESSION_EXPIRED: {
            TITLE: 'Session Expired',
            DESCRIPTION: 'Your last session has ended. Please Sign in again to continue.',
        },
        CONFIGURATION: {
            ACTIONS: {
                SAVE: 'Save Configuration',
                CREATE: 'Create Configuration',
                OK: 'OK',
                CANCEL: 'Cancel',
                CONTINUE: 'Continue',
                CONFIRM_UPGRADE: 'Confirm Upgrade',
            },
            HANDBOOK_CONFIRM: {
                DESCRIPTION:
                    'Caution: If you continue to add the Products and Services: Smart Employee Handbooks to this configuration, ALL previously created PEO handbooks will no longer be available.',
            },
        },
        EULA: {
            ACTIONS: {
                CONFIRM: 'I Agree',
            },
            TITLE: 'Welcome to ${platformName}',
            DESCRIPTION:
                'By clicking I_AGREE you consent to our collection and use of information as described in our PRIVACY_POLICY and TERMS_OF_SERVICE.',
        },
        COMPANY_DETAILS_UPDATE_REMINDER: {
            TITLE: 'Update your company information',
            ACTIONS: {
                CONFIRM: 'UPDATE IT NOW',
                CANCEL: 'SKIP FOR NOW',
            },
            DESCRIPTION:
                'For us to continue providing relevant, accurate and intelligent HR solutions, please make sure all information about your company is updated',
        },
        COMMUNICATIONS: {
            WELCOME_EMAIL: {
                EDITOR_TITLE: 'Edit welcome email',
                USER_PROFILE: 'User’s profile',
            },
        },
        RESEND_WELCOME_EMAILS: {
            TITLE: 'Resend Welcome Email',
        },
        DATE_VALIDATION_MESSAGE: 'Please select a Start Date that occurs before the End Date',
        PROSPECT: {
            CONFIRM: {
                DELETE: {
                    DESCRIPTION: 'Are you sure you want to remove this prospect?',
                },
            },
        },
        ATTACHMENTS: {
            CONFIRM: 'Are you sure you want to delete this attachment?',
            OK: 'OK',
            CANCEL: 'CANCEL',
        },
        COMMENTS: {
            CONFIRM: 'Are you sure you want to delete this comment?',
            OK: 'OK',
            CANCEL: 'CANCEL',
        },
        RESOURCES: {
            CONFIRM: 'Are you sure you want to delete this resource?',
            UNLINK_PREPPED_ANSWER:
                'Would you like to delete this Prepped Answer - and all of its associated Content Links and Resources - from this Case?',
            OK: 'OK',
            CANCEL: 'CANCEL',
            EDIT: 'EDIT RESOURCE',
            ADD: 'ADD RESOURCE',
        },
        REDIRECT_TO_PARTNER_LOGIN: {
            DESCRIPTION:
                "You will be redirected to the partner's page to Sign in to the application.",
            TITLE: 'Sign in',
            BUTTON: 'Close',
        },
        REDIRECT_TO_PARTNER_SIGN_IN: {
            DESCRIPTION:
                "This session has timed out. To protect your security, you will be redirected to your partner's site to Sign in to the application.",
        },
        TOKEN_EXPIRED: {
            ACTIONS: {
                LOGIN: 'Sign in',
            },
            TITLE: 'Session Expired',
            DESCRIPTION:
                'Your session has expired.<br>Please Sign in again to access the platform.',
        },
        TODO_HANDBOOK: {
            CONFIRM: 'GO TO HANDBOOKS',
            CANCEL: 'CANCEL',
        },
        VIEW_ALL_PRODUCTS: {
            TITLE: 'Products Available to',
        },
        BCT: {
            DESCRIPTION: `The report is being generated. <br>It may take a few minutes to complete. <br>Once it\'s ready, we\'ll send a link to your email.`,
        },
        UPGRADE_ADS: {
            TITLE: 'Delete ${adName}',
            DESCRIPTION:
                'To delete ${adName} and remove it from the platform, select <span style=font-weight=500>Yes, Delete</span>. Select Cancel to return to the Ad Preview on the Banner Ads page.',
        },
        ADD_CONFIG_MODAL: {
            PRODUCTS_SELECT: {
                TITLE: 'Include standard products from a package',
                TOOLTIP:
                    'Select a package to include those products and solutions in the configuration you’re creating. You can add or remove products after the configuration is created.',
                PLACEHOLDER: {
                    CONTENT: '-- Select an Option --',
                    NO_CONTENT: 'No pre-set packages available',
                },
            },
        },
        DOWNLOAD_HANDBOOK: {
            DOWNLOADING: {
                TITLE: 'Downloading Your Handbook',
                DESCRIPTION: `Please wait while the file for your handbook processes. <br/>Once it\'s ready, a download button will display in this message. You\'ll also receive an email with a link to download your handbook.`,
            },
            TAKING_LONGER: {
                TITLE: 'Downloading Your Handbook is Taking Longer Than Expected',
                DESCRIPTION: `Would you like to keep waiting, or exit and receive the handbook in your email once it\'s ready?`,
                KEEP: 'Yes, Keep Waiting',
                CANCEL: 'Close and Send by Email',
            },
            UPDATE_DETECTED: {
                TITLE: 'Handbook Update Detected',
                DESCRIPTION: `An update was applied to your handbook during generation. <br/>Please try downloading it again.`,
                CLOSE: 'Close and Try Again',
            },
            READY: {
                TITLE: 'Your Handbook is Ready!',
                DESCRIPTION: 'Select the button below to download your handbook.',
                DOWNLOAD: 'Download My Handbook',
            },
            ERROR: {
                TITLE: 'Error Downloading Handbook',
                DESCRIPTION: `Something went wrong while downloading your handbook. <br/>Please try again in a few minutes. If this issue continues, contact Technical Support.`,
                CLOSE: 'Close',
            },
            SERVER_ERROR: {
                TITLE: 'Warning',
                DESCRIPTION: 'A server error has occurred and your download could not be completed',
            },
            CURRENTLY_UNAVAILABLE: {
                TITLE: 'Download Currently Unavailable',
                DESCRIPTION:
                    'This handbook is currently being translated by Google and will be available to download once complete.',
            },
        },
    },
    BROKEN_LINKS: {
        REMOVE: {
            CONFIRM: {
                TITLE: 'Remove Broken Link Entry',
                DESCRIPTION: 'Are you sure you want to remove this entry?',
            },
        },
    },
    HANDBOOK: {
        HANDBOOK_NOTIFY:
            'Be notified when policy language changes and updates to this handbook are recommended.',
        HANDBOOK_TYPE: 'The handbook type may not be changed after the handbook has been created.',
        HANDBOOK_TRANSLATION:
            'If you want your handbook translated into Spanish, please select this option. Once the Spanish translation setting has been enabled and saved, it cannot be disabled for this handbook.',
        HANDBOOK_SAVE:
            'A new handbook will be created with applicable policies and you will be able to review the table of contents.',
        HANDBOOK_SAVE_AND_EXIT:
            'A new handbook will be created with all applicable policies. You will be able to edit this handbook later at any time.',
        HANDBOOK_SAVE_AND_EXIT_LABEL: 'Save Handbook & Exit',
        HANDBOOK_SAVE_DISCLAIMER: `By building a handbook, you are accepting our <a href=${EXTERNAL_LINKS.EULA.TERMS_OF_SERVICE}>TERMS OF SERVICE.</a>`,
        HANDBOOK_SAVE_LABEL: 'Save & Review Policies',
        GO_TO_HANDBOOK_TOC: 'GO TO HANDBOOK TABLE OF CONTENTS',
        POLICY_NOT_AVAILABLE: 'Policy No Longer Available',
        POLCIY_DELETED_MESSAGE: 'This policy has been deleted',
    },
    COMPLY: {
        NO_DATA_AVAILABLE: 'No data available. Please check again later.',
    },
    NO_PERMISSION_MESSAGE:
        'You do not have sufficient permissions to access this page. Please contact your system administrator.',
    UNSAVED:
        'Your changes have not been saved. Do you want to stay on the page so that you can save your changes?',
    JOB_DESCRIPTION: `It's crucial to have a job description in place for every position in your organization, both to find the right candidate and to evaluate an employee's ongoing performance and role. Browse our library of job descriptions to find one that matches your needs, then download it and customize it for your organization. For information on a position's FLSA exemption, please contact an HR Professional.`,
    IMPORT_LOCATIONS: {
        IMPORT: 'Import',
        INSTRUCTION_TEXT: ' View Industry and NAICS Codes.',
        IMPORT_LOCATIONS: 'Import Locations',
        FAILED_RECORDS: 'Failed Records: ',
        FAILED_RECORDS_MESSAGE:
            'Preview of errors. Click the Download Import Results CSV to view the full list.',
    },
    NOTIFICATIONS: {
        ERROR_MESSAGE: 'Unable to fetch notifications',
        DELETE_ALL: 'DELETE ALL',
        DELETE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete',
        ALL_NOTIFICATIONS: 'You can find all notifications ',
        DELETED: 'Deleted!',
        CONFIRMATION_MESSAGE: 'Are you sure?',
        VIEW_ALL: 'View All Notifications',
    },
    TOOLTIP: {
        ICON_TEXT: {
            DELETE: 'Delete',
            DOWNLOAD: 'Download',
            PRINT: 'Print',
            SHARE: 'Copy Link',
            URL_COPIED: 'Link Copied',
        },
        SKUS: 'Products and Services: ',
        NO_SKUS: 'No Products and Services Found',
    },
    SKUS: {
        SKUS_PERMISSIONS: 'Products and Services Permissions',
        SKUS_CONFIGURATION: 'Configuration Products and Services',
    },
    ADD_ON_MULTIPLE_SKUS: {
        SELECT_SPONSOR: 'Select Sponsor',
        SELECT_ALL: 'Select All',
        SELECT_SKU_DESCRIPTION: 'Use this sponsor for all products and services that are checked.',
        SELECT_SKU_ERROR: '* No checkboxes were selected for products to sponsor',
    },
    HR_FITNESS: {
        MULTIPLE_CHOICE:
            'Please select the response that <strong>best</strong> answers this question.',
        MULTIPLE_SELECT: 'Select <strong>all</strong> responses that apply.',
        RESULTS_LABEL: 'Your Performance On Test:',
    },
    SIDEBAR_MESSAGE: {
        RESEND_WELCOME_EMAILS:
            'The contacts in this batch already were sent a welcome email. Do you want to resend?',
        WELCOME_EMAILS_SENT:
            'The welcome email has been sent to ${totalUsers} users in this batch.',
    },
    RESET_PASSWORD: {
        MESSAGE: 'Password must fulfill the following requirement(s):',
        ERRORS: {
            PASSWORD_LENGTH:
                'Password must be no less than 8 characters and no more than 32 characters',
            PASSWORD_MATCH: 'Passwords must match',
            PASSWORD_SPECIAL_CHAR: 'Password must include a special character',
            PASSWORD_VALID_CHAR:
                'Allowed characters for passwords include: A-Z, a-z, 0-9, ! @ # $ % & + -',
            PASSWORD_UPPERCASE: 'Password must include an uppercase character',
            PASSWORD_LOWERCASE: 'Password must include a lowercase character',
            PASSWORD_INCLUDE_NUMBER: 'Password must include a number',
            PASSWORD_WHITESPACE: 'Password must not include any spaces',
        },
        LABELS: {
            PASSWORD_TITLE: 'Password',
            PASSWORD_LENGTH: '8 character minimum and 32 character maximum',
            PASSWORD_MATCH: 'Password match',
            PASSWORD_SPECIAL_CHAR: '1 allowed special character ( ! @ # $ % & + - )',
            PASSWORD_VALID_CHAR:
                'Allowed characters for passwords include: A-Z, a-z, 0-9, ! @ # $ % & + -',
            PASSWORD_UPPERCASE: '1 uppercase letter',
            PASSWORD_LOWERCASE: '1 lowercase letter',
            PASSWORD_INCLUDE_NUMBER: '1 number',
            PASSWORD_WHITESPACE: 'no spaces',
        },
    },
    PROSPECT_ASSESSMENT_TOOL: {
        INVALID:
            'The link you are using may be invalid or expired. Please contact your representative for a new link.',
        SUCCESS:
            'Congratulations! You have successfully completed the HR Assessment. Check your email to see your results and our recommendations.',
        TITLE: 'HR Assessment',
        RESULTS_TITLE: 'HR Assessment Results',
        BUTTONS: {
            NEXT: 'Next',
            PREVIOUS: 'Previous',
            SUBMIT_TEST: 'Submit Test',
        },
    },
    DISCLAIMER: {
        SAVE_SETTING: 'Changes will not be made to this ${entity} until you click Save.',
    },
    PUBLIC_CONTENT: {
        INVALID_TITLE: 'Invalid Link',
        INVALID_MESSAGE:
            "Oops! This link doesn't appear to be working. Click LOGIN_HERE to Sign in to ${platformName}.",
        LOGIN_HERE_TEXT: 'here',
        INTUIT_ERROR: {
            TITLE: 'Sorry, something went wrong.',
            DESCRIPTION: 'There was an issue with the connection, and ${errorCode} error occurred.',
            NEXT_STEP:
                'Try refreshing this page. If the issue continues, please <a class="helpTextLink" href="https://apps.trustmineral.com/auth/tech-support" target="_blank">contact support</a>.',
            LABEL_BUTTON: 'Refresh Page',
            DEFAULT_ERROR: 'an',
        },
    },
    LOGIN: {
        RETURN: 'Return to Sign in',
        EULA: {
            LINK_TEXT: {
                TERMS_OF_USE: 'Terms of Service',
                PRIVACY_POLICY: 'Privacy Policy',
            },
            DESCRIPTION:
                'By signing in, you agree to the Mineral TERMS_OF_SERVICE and PRIVACY_POLICY',
        },
        TECH_SUPPORT: {
            GREETING: 'Need Help?',
            DESCRIPTION:
                'Fill out the form below, and a member of our support staff will reach out to help answer any questions.',
        },
        SIGN_IN_VIDEO: {
            LINK_TEXT: 'Watch this video.',
            DESCRIPTION: 'Need help signing in? LINK_TEXT',
        },
        TECH_SUPPORT_SUCCESS: {
            CONFIRMATION: 'Thank you for reaching out to Tech Support!',
            DESCRIPTION: 'Our support team will respond to you within one business day.',
        },
        BUTTONS: {
            SIGN_IN: 'Sign in',
            SUBMIT: 'Submit',
            BACK_TO_LOGIN: 'Back to Sign in',
            BACK: 'Back',
            RETURN_TO_SIGN_IN: 'Return To Sign in',
        },
        SUPPORT: {
            PASSWORD_HELP_MESSAGE: 'Need PASSWORD_HELP',
            PASSWORD_HELP_TEXT: 'password help?',
            TECH_HELP_MESSAGE: 'Need TECH_HELP',
            TECH_HELP_TEXT: 'tech support help?',
            FORGOT_USERNAME_OR_PASSWORD: 'Forgot Username or Password?',
        },
        HELP_OPTIONS: {
            TITLE: 'How can we help you?',
            FORGOT_PASSWORD: 'Forgot Password',
            FORGOT_USERNAME: 'Forgot Username',
            TECH_SUPPORT: 'Contact Tech Support',
        },
    },
    OSHA: {
        FORM: {
            NAICS:
                'The North American Industry Classification System (NAICS) code which classifies an establishment\'s business. Please see US Census Website for NAICS (<a class="helpTextLink" href="https://www.census.gov/naics/" target="_blank">https://www.census.gov/naics/</a>) Please contact workers\' compensation Partner or carrier for more details.',
            ANNUAL_AVERAGE_NUMBER_OF_EMPLOYEES: `Please see URL (<a class="helpTextLink" href="${STATIC_JSON_ORIGIN}/downloads/osha/worksheet_to_help_you_fill_out_the_summary.pdf" name="keepLink" target="_blank">link to PDF</a>) for additional help.`,
            TOTAL_HOURS_WORKED_BY_ALL_EMPLOYEES: `Please see URL (<a class="helpTextLink" href="${STATIC_JSON_ORIGIN}/downloads/osha/worksheet_to_help_you_fill_out_the_summary.pdf" name="keepLink" target="_blank">link to PDF</a>) for additional help.`,
            JOB_TITLE: 'e.g. Welder.',
            DEPARTMENT: 'e.g. Marketing.',
            WHERE_EVENT_OCURRED: 'e.g. Loading dock north end.',
            DETAIL_INJURY: 'e.g. Scrape, sprain, contusion, etc.',
            BUTTON_ADD_BODY_PART: 'Add New Body Part',
            INJURY_CAUSE: 'e.g. Second degree burns on right forearm from acetylene torch.',
            CASE_TYPE:
                'Classify the case by recording the most serious outcome of the case, with (Death) being the most serious and (Other recordable cases) being the least serious.',
            NEAR_MISS:
                'An incident that could have caused injury, illness or damage but did not; also called a "close call".',
        },
        ACTIONS: {
            SAVE_LABEL: 'Save Settings',
            DELETE_LABEL: 'Delete',
            CREATE_ANOTHER_CASE: 'Create another case',
        },
        SUBNAV_ACTIONS: {
            DOWNLOAD_OSHA_300_300A: 'Download OSHA 300/300A',
            DOWNLOAD_OSHA_301: 'Download OSHA 301',
            DOWNLOAD_REPORT: 'Download Report',
        },
    },
    TICKETS: {
        BUTTONS: {
            EXPERT: 'ASK AN EXPERT',
            CLOSE: 'Close',
        },
    },
    FILES: {
        ONLY_CSV_ALLOWED: 'You are only allowed to upload a CSV document (.csv).',
        EMPTY_CSV_FILE: 'Your CSV file is empty. Please add prospects and reupload.',
    },
    TRAINING_EMAILS_PERSONALIZATION: {
        TITLE: 'Training Email Personalization',
        CERTIFICATE_GENERATION_EMAILS: {
            TITLE: 'Send me certificate generation email',
            TOOLTIP:
                'Check this to receive an email when a certificate is available after an employee has completed a course.',
        },
        COURSE_COMPLETION_EMAILS: {
            TITLE: 'Send me course completion email',
            TOOLTIP: 'Check this to receive a confirmation email when you complete a course.',
        },
    },
    RESTRICTED_NAV_ITEM: 'You are not authorized to access this feature',
    EMAIL_PREVIEW: {
        TITLE: 'Email Preview',
        BUTTONS: {
            CREATE_PASSWORD: 'CREATE PASSWORD',
            LOGIN: 'SIGN IN',
            PREVIEW_EMAIL: 'Preview Email',
            SEND_TEST_EMAIL: 'Send Test Email',
            RESEND_WELCOME_EMAIL: 'Resend Welcome Email',
            CANCEL: 'Cancel',
            SEND: 'Send',
        },
        HEADER: {
            EMAIL: 'Email',
            USER_NAME: 'User Name',
            TO: 'To:',
            FROM: 'From:',
            SUBJECT: 'Subject:',
        },
        BODY: {
            ALREADY_HAVE_PASSWORD: 'Already have a password? Sign in below:',
        },
    },
    PLATFORM_LITE_EMAIL_PREVIEW: {
        BODY: {
            INTELLIGENCE_CARD_TITLE: 'Sign in to explore',
            INTELLIGENCE_CARD_DESCRIPTION:
                '<strong>View a personalized dashboard</strong> of state and federal laws impacting your company.',
            INTELLIGENCE_CARD_TAKE_ACTION:
                '<strong>Take Action</strong> on obligations that may apply to your company.',
            INTELLIGENCE_CARD_PLAN_AHEAD:
                '<strong>Plan Ahead</strong> for obligations that may be applicable soon.',
            ALREADY_HAVE_PASSWORD: 'Already have a password? ',
        },
        SIGN_IN: 'Sign in Here',
        CONTACT: ' Need help? contact us at',
        CALL: 'or call',
        BUTTONS: {
            EXPLORE: 'Get Started',
        },
    },
    DOWNTIME: {
        INITIAL_PHASE: {
            TITLE: 'The platform will be unavailable in 30 minutes',
            DESCRIPTION:
                'The platform will be shutting down for maintenance in 30 minutes. Please save any in-progress work and sign out within the next 30 minutes.',
        },
        MEDIAL_PHASE: {
            TITLE: 'The platform will be unavailable in 15 minutes',
            DESCRIPTION:
                'The platform will be shutting down for maintenance in 15 minutes. Please save any in-progress work and sign out within the next 15 minutes.',
        },
        TERMINAL_PHASE: {
            TITLE: 'The platform will be unavailable in 1 minutes',
            DESCRIPTION:
                'The platform is shutting down for maintenance. You will be signed out in ',
        },
    },
    COMPARE_TOOLTIP: 'To compare states you must select two or more states and only one category.',
    UPGRADE_NOW: 'Upgrade now to access',
    HTTP_REQUEST: {
        BAD_REQUEST: 'Bad Request',
        REQUEST_ERROR: 'We are sorry! Your request resulted in an error.',
    },
    TOAST: {
        FEATURE_FLAG: {
            PACKAGING: {
                true: 'Enabled - Mineral Licensing Feature Flag',
                false: 'Disabled - Mineral Licensing Feature Flag',
            },
        },
    },
    AUTH: {
        AUTH_ERROR: 'Authentication Error',
        OIDC_AUTH_ERROR:
            'We are sorry!  Our server could not process your request. Please refer to the error message below and contact your application administrator for further assistance.',
    },
    LICENSING: {
        COMPANY: {
            TITLE: 'Company License',
            CONFIG_INFO: 'Configuration Information',
            CONFIG_PACKAGE_INFO: 'Configuration/License Information',
            WARNING: `Changing a client's configuration can increase the billing rate for the duration of your current contract.`,
        },
        CONFIGURATION: {
            LICENSE: 'License',
            TITLE: 'Configuration License',
        },
        SKUS: {
            MINERAL_LICENSE: 'Mineral License',
        },
    },
    IMPORT_BUTTONS: {
        IMPORT_LOCATIONS: 'Import Locations',
        IMPORT_USERS: 'Import Users',
        IMPORT_CLIENTS: 'Import Clients',
    },
    LIST_BUTTONS: {
        BUTTONS_COLORS: 'subNavActions',
        CREATE_BUTTONS_ID: 'create',
        BATCH_BUTTONS_ID: 'batchDeactivation',
        ASSIGN_CONFIG_ID: 'assignConfiguration',
        CREATE_BUTTONS_TITLE: 'Create Company',
        BATCH_BUTTONS_TITLE: 'Batch Deactivation',
        ASSIGN_BUTTONS_TITLE: 'Assign Configuration',

        ASSIGN_CONFIG_TOOLTIP: 'Please choose at least one company from the list below',
    },
    IMPORT_TYPES: {
        USER: 'USER',
        LOCATION: 'LOCATION',
        COMPANY: 'CLIENT',
    },
    IMPORT_FROM: {
        CONTACTS: 'contacts',
        LOCATION: 'locations',
        COMPANY: 'clients',
    },
    IMPORT_PROCESSING_TEXT: {
        TITLE: 'Your import is in progress',
        DESCRIPTION:
            "We are processing your import. Keep this window open until your import completes and you're returned to the confirmation page. The page will refresh automatically when the import is completed.",
        BOTTOM_TEXT: 'The processing time will vary depending on the size of your upload.',
        RETRY_IMPORT: 'The import process was unsuccessful. Please attempt the import again.',
    },
    IMPORT: {
        RESPONSE_STATUS_CODE: {
            FAILED: '400',
            SUCCESS: '200',
            PARTIAL_ERROR: '207',
        },
        RESPONSE_TYPE: {
            API_RESPONSE: 'apiResponse',
            SUCCESS: 'success',
            FAILED: 'failure',
        },
    },
    BULK_IMPORT_CONSTANT: {
        TRY_ANOTHER_IMPORT: 'Return to Import Page',
        KEEP_ALIVE: 'keepalive',
        TOAST_TYPE: {
            SUCCESS: 'success',
            FAILED: 'failed',
        },
        SUCCESS: {
            DESCRIPTION: 'Your import ${importName} successfully completed. ',
            LINK_TEXT: 'View Import History',
        },
        FAILED: {
            DESCRIPTION: 'Your import ${importName} contained errors. ',
            LINK_TEXT: 'Return to the import to resolve and try again.',
        },
        MODAL: {
            TITLE: 'Your Import is Processing',
            DESCRIPTION:
                'You’ll see a notification when your import finishes processing or you can check the Import History page by clicking the View Import History button below.',
            VIEW_IMPORT_HISTORY: 'View Import History',
            CLOSE: 'Close',
        },
    },
    USERS_ACCOUNT_INFO: {
        IS_PRIMARY: 'Primary',
        IS_ACA_ADMIN: 'This is an ACA Admin',
        ACA_ADMIN_DESCRIPTION:
            'By creating a user with ACA Admin access, you are consenting to the new users access to your ACA reporting information and understand this access may include personally identifiable information of your employees.',
    },
};
export default MESSAGES;
