import orderBy from 'lodash/orderBy';
import { get, isEmpty } from 'ravenjs/utils/lodash';
import { addSchemaValue } from 'ravenjs/utils';

import { ENABLE_OASIS, MSA_SIGN_IN_OVERRIDE_ENABLED } from 'constants/api';
import { CUSTOM_FIELDS_TYPE } from 'constants/company';
import { CUSTOM_FIELD_LABEL_MAPPINGS } from 'constants/entityLabels';
import { APP_PERMISSIONS } from 'constants/permissions';
import { ROLE_TYPE } from 'constants/roles';
import Acl from 'modules/acl';
import { selectors as userSelectors } from 'modules/user';
import { convertStringToBoolean, hasFeatureFlag } from 'utils/common';
import { formatCSVArrayToJSONWithQuotes, formatPhoneNumber } from 'utils/thrservice';

import store from 'store';
import _cloneDeep from 'lodash/cloneDeep';
import templateReplace from 'es6-template-strings';
import { generateLicenseName } from './skus';

/**
 * Format data for create company JAPI
 *
 * @param   {Object}    formData
 * @return  {Object}
 */
export function buildCreateCompanyPayload(formData = {}) {
    const {
        companyConfiguration = {},
        companyInfo = {},
        locationInfo = {},
        customFields = {},
    } = formData;
    const { configurationName, isDemoAccount, source } = companyConfiguration;

    const {
        address2 = null,
        address = null,
        partnerId,
        businessType,
        companyId,
        phone = '',
        companyType,
        employeeCount,
        industry,
        industryClassification = null,
        federalEIN = '',
        isFederalContractor = null,
        isHQ = false,
        isUnionized = null,
        locationLookup,
        locationName,
        websiteUrl,
        producer,
        referenceId,
    } = { ...companyInfo, ...locationInfo };
    const modifiedCompanyName = get(companyInfo, 'companyName', '').trim();
    const modifiedDisplayName = get(companyInfo, 'displayName', '').trim();

    return {
        partnerId,
        companyId,
        companyName: modifiedCompanyName,
        companyType,
        configurationName,
        ...customFields,
        displayName: modifiedDisplayName,
        industry,
        isDemoAccount,
        source,
        websiteUrl,
        producer,
        referenceId,
        federalEIN,
        location: {
            address2,
            address,
            businessType,
            employeeCount,
            industry,
            industryClassification,
            isFederalContractor,
            isHQ,
            isUnionized,
            locationName,
            phone: formatPhoneNumber(phone),
            ...locationLookup,
        },
    };
}

/**
 * Normalized data for JAPI end point
 *
 * @param {Object} formData
 * @return {Object}
 */
export function buildUpdateCompanyPayload(formData = {}) {
    const {
        companyConfiguration = {},
        companyInfo = {},
        locationInfo = {},
        customFields = {},
    } = formData;

    Object.entries(customFields).forEach(([key, value]) => {
        if (!value) customFields[key] = '';
    });

    const { isDemoAccount, source } = companyConfiguration;
    const canCreateBcsEliteDocuments = get(
        formData,
        'bcsEliteConfiguration.canCreateBcsEliteDocuments'
    );
    const {
        address2 = null,
        address = null,
        partnerId,
        businessType,
        companyId,
        phone = '',
        companyType,
        configurationName,
        employeeCount,
        industry,
        industryClassification = null,
        isFederalContractor = null,
        isHQ = false,
        isUnionized = null,
        locationLookup,
        locationName,
        websiteUrl = null,
        producer = null,
        referenceId = '',
        federalEIN = '',
    } = { ...companyInfo, ...locationInfo };
    const accountManagerId = get(companyInfo, 'accountManager.value', null);
    const successManagerId = get(companyInfo, 'successManager.value', null);
    const modifiedCompanyName = get(companyInfo, 'companyName', '').trim();
    const modifiedDisplayName = get(companyInfo, 'displayName', '').trim();

    return {
        partnerId,
        canCreateBcsEliteDocuments: canCreateBcsEliteDocuments ? 'true' : 'false',
        companyId,
        companyName: modifiedCompanyName,
        companyType,
        configurationName,
        ...customFields,
        displayName: modifiedDisplayName,
        industry,
        isDemoAccount,
        source,
        location: {
            address2,
            address,
            businessType,
            employeeCount,
            industry,
            industryClassification,
            isFederalContractor,
            isHQ,
            isUnionized,
            locationName,
            phone: formatPhoneNumber(phone),
            ...locationLookup,
        },
        referenceId,
        federalEIN,
        accountManagerId,
        successManagerId,
        websiteUrl,
        producer,
    };
}

/**
 * Get the names of each configuration in an array to populate the label and value field for the configs
 *
 * @param   {Array} configs
 * @return  {Array}
 */
export function normalizeConfigurations(configs) {
    if (isEmpty(configs)) {
        return [];
    }

    return configs.map(config => {
        return {
            label: config.configurationName,
            value: config.configurationId,
        };
    });
}

/**
 * Normalize the list of NAICS codes.
 *
 * @method normalizeNaicsCodes
 * @param  {Array}            [list=[]] The list of NAICS codes
 * @return {Array}
 */
export function normalizeNaicsCodes(list = []) {
    // const naicsEnums = list.map(code => code.displayLabel);
    // const naicsEnumNames = list.map(code => `${code.typeName} - ${code.displayLabel}`);
    // Return the enums and enumNames.
    // return [naicsEnums, naicsEnumNames];
    return Array.isArray(list)
        ? list.reduce(
              (acc, val) => [
                  [...acc[0], val.displayLabel], // values
                  [...acc[1], `${val.typeName} - ${val.displayLabel}`], // display-value
              ],
              [[], []]
          )
        : [];
}

/**
 * Build Custom Fields for company/partner
 *
 * @method configContainsSku
 * @param  {Array}          [customFields=[]]  The customFields
 * @return {Object}
 */
export function buildCustomFields(customFields) {
    const properties = {};
    const readOnlyProperties = {};
    const requiredFields = [];
    let companyCustomFields = customFields.filter(field => {
        return field.type === 'COMPANY';
    });
    companyCustomFields = orderBy(companyCustomFields, 'displayOrder');

    companyCustomFields.forEach(value => {
        const { displayKey, displayLabel, importRequired, readOnly } = value;
        const keyName = `custom${displayKey[displayKey.length - 1]}`;

        properties[keyName] = {
            title: displayLabel,
            type: 'string',
        };

        readOnlyProperties[keyName] = {
            'ui:disabled': readOnly,
        };

        if (importRequired) {
            properties[keyName].format = 'onlySpaces';
            requiredFields.push(keyName);
        }
    });
    return {
        schema: {
            title: 'Custom Fields',
            type: 'object',
            formGroup: true,
            properties,
            required: requiredFields,
        },
        uiSchema: {
            ...readOnlyProperties,
        },
    };
}

/**
 * Check if company is Oasis
 *
 * @method isOasisCompany
 * @param  {number} companyId Company Id
 * @return {boolean}
 */
export const isOasisCompany = companyId => {
    const OASIS_COMPANY_ID = MAESTER.companies.oasis_company_id;
    return companyId === OASIS_COMPANY_ID;
};

/**
 * Check env var to enable Oasis features
 *
 * @method isOasisEnabled
 * @return {boolean}
 */
export const isOasisEnabled = () => {
    if (hasFeatureFlag({ search: get(window, 'location.search', '') }, 'enableOasis')) {
        return true;
    } else if (typeof ENABLE_OASIS === 'string') {
        return ENABLE_OASIS === 'TRUE' || ENABLE_OASIS === 'true';
    } else if (typeof ENABLE_OASIS === 'boolean') {
        return ENABLE_OASIS;
    }
    return false;
};

export const isMsaSignInOverrideEnabled = () => {
    return convertStringToBoolean(MSA_SIGN_IN_OVERRIDE_ENABLED);
};

/**
 * Check if company is Root company
 *
 * @method isRootCompany
 * @param  {number} companyId Company Id
 * @return {boolean}
 */
export const isRootCompany = companyId => {
    return companyId === MAESTER.companies.root_company_id;
};

/**
 * Normalized data for Send Welcome Email Settings post data
 *
 * @param {Object} formData     The data comming from the form
 * @return {Object}
 */
export function buildEmailSettingsPostData(formData) {
    const { welcomeEmails, companyInfo } = formData;
    const { partnerId } = companyInfo;

    // only build the post data if we `welcomeEmails` is not null
    if (welcomeEmails) {
        const {
            emailTemplateId,
            emailTemplateType,
            sendWelcomeEmailSenderAddress,
            welcomeEmailSubjectLine,
        } = welcomeEmails;
        return {
            id: emailTemplateId,
            partnerId,
            type: emailTemplateType,
            emailConfigurations: [
                {
                    emailFieldName: 'from_email',
                    value: sendWelcomeEmailSenderAddress,
                },
                {
                    emailFieldName: 'subject',
                    value: welcomeEmailSubjectLine,
                },
            ],
        };
    }

    return null;
}

/**
 * Check if a company is Partner
 *
 * @method isPartner
 * @param  {Object} companyInfo Company Info
 * @return {boolean}
 */
export const isPartner = companyInfo => {
    if (isEmpty(companyInfo)) {
        return false;
    }

    const { companyId, partnerId } = companyInfo;
    return companyId === partnerId;
};

/**
 * Check if a company is Re
 *
 * @method isRe
 * @param  {Object} companyInfo Company Info
 * @return {boolean}
 */
export const isRe = companyInfo => {
    if (isEmpty(companyInfo)) {
        return false;
    }

    const { companyId, partnerId } = companyInfo;
    return companyId !== partnerId;
};

/**
 * Get the label for a particular custom field.
 *
 * @param {Array} customFields The array of custom fields
 * @param {string} attributeName The name of the field to find
 * @return {string|null} label
 */
export function getCustomFieldLabel(customFields, attributeName) {
    const found = customFields.find(field => {
        if (field.customFieldDisplayKey === CUSTOM_FIELD_LABEL_MAPPINGS[attributeName]) {
            return field;
        }
        return null;
    });

    return get(found, 'customFieldDisplayLabel');
}

/**
 * Format company info for the details section
 *
 * @param   {Object}    companyInfo
 * @param   {Array}     itemsSchema
 * @return  {Array}
 */
export function formatCompanyDetails(companyInfo = {}, itemsSchema = []) {
    const formattedCompanyInfo = {
        ...companyInfo,
        isDemoAccount: companyInfo.isDemoAccount ? 'Yes' : 'No',
        status: companyInfo.isActive ? 'Active' : 'InActive',
        licenses: generateLicenseName(companyInfo.licenses),
    };

    return itemsSchema.map(item => {
        return {
            ...item,
            value: get(formattedCompanyInfo, item.id, '-'),
        };
    });
}

/**
 * Create custom fields data for details view;
 *
 * @param {Object}    companyInfo
 * @return {[]}
 */
export function createCompanyCustomFields(companyInfo) {
    const { custom1, custom2, custom3, custom4, custom5, customFields = [] } = companyInfo;
    const customFieldsInfo = [];
    const displayValues = {
        c1: custom1,
        c2: custom2,
        c3: custom3,
        c4: custom4,
        c5: custom5,
    };

    if (customFields.length) {
        customFields.forEach(({ displayKey, displayLabel, type }) => {
            if (type === CUSTOM_FIELDS_TYPE.COMPANY) {
                customFieldsInfo.push({
                    title: displayLabel,
                    value: displayValues[displayKey] || '-',
                });
            }
        });
    }
    return customFieldsInfo;
}

/**
 * Extract failed records from CSV
 *
 * @param   {string}    csv
 * @param   {boolean}   outputInCSV
 * @return  {string|Array}
 */
export function getImportFailedRecords(csv, outputInCSV = false) {
    const lines = csv.split('\n');
    const failedRecords = lines.splice(7);

    if (outputInCSV) {
        return failedRecords.join('\r\n');
    }

    return formatCSVArrayToJSONWithQuotes(failedRecords);
}

/**
 * Extract import failure report from csv content
 *
 * @param   {string}    csv
 * @return  {Array}
 */
export function getImportReportFromCSV(csv) {
    const lines = csv.split('\n');
    return lines.splice(0, 5);
}

/**
 * Check whether the given role has permission to update company 'demo' status
 *
 * @param   {string}    role
 * @param   {boolean}    disableDemoFunctionality
 * @param   {boolean}   isPartnerCompany
 * @return  {boolean}
 */
export function hasDemoPermission(role, disableDemoFunctionality, isPartnerCompany) {
    if (disableDemoFunctionality) {
        return false;
    }

    if (isPartnerCompany) {
        return Acl.check(APP_PERMISSIONS.systemCompaniesPartnerDemoUpdate);
    }

    return Acl.check(APP_PERMISSIONS.systemCompaniesClientDemoUpdate);
}

/**
 * Check if current user's role type is of root company
 * @return {boolean}
 */
export const isRootCompanyRole = () => {
    const storeConfig = store;
    const state = storeConfig.store.getState();
    const roleType = userSelectors.getUserRoleType(state);
    return roleType === ROLE_TYPE.ROOT;
};

/**
 * Check if current user's role type is of partner company
 * @return {boolean}
 */
export const isPartnerCompanyRole = () => {
    const storeConfig = store;
    const state = storeConfig.store.getState();
    const roleType = userSelectors.getUserRoleType(state);
    return roleType === ROLE_TYPE.PARTNER;
};

/**
 * Check if current company logged can create a bcs document
 * @return {boolean}
 */
export const canCreateBcsEliteDocuments = () => {
    const storeConfig = store;
    const state = storeConfig.store.getState();
    const company = get(state, 'user.company', {});
    const companyCanCreateBcsEliteDocuments = get(company, 'canCreateBcsEliteDocuments', false);

    if (isRe(company)) {
        return companyCanCreateBcsEliteDocuments;
    } else {
        return Acl.check('bcsEliteCreate');
    }
};

export const goToBdcResource = history => {
    history.push('/hr-tools/benefits-document-creator/resources');
};

export const goToDashboard = history => {
    history.push('./dashboard');
};

export const goToBdcFaq = history => {
    history.push('/hr-tools/benefits-document-creator/faq');
};

export const getSSOIncludeScript = ssoUrl => {
    return `<script type="text/javascript" id="thinkhr-sso">
        function initiate_sso(){var e=new XMLHttpRequest;e.withCredentials=!0,e.addEventListener("readystatechange",function(){4===this.readyState&&(window.location.href=JSON.parse(this.responseText).sso_url)}),e.open("POST","${ssoUrl}"),e.send()}
    </script>`;
};

export function buildCompanyCustomFieldsFormData(companyInfo) {
    const { customFields = [] } = companyInfo;
    const companyCustomFields = {};
    const fieldMap = {};

    customFields.forEach(field => {
        const { displayKey } = field;
        fieldMap[displayKey] = `custom${displayKey[displayKey.length - 1]}`;
    });

    customFields.forEach(field => {
        const { type, isActive, displayKey } = field;
        if (type === 'COMPANY' && isActive)
            companyCustomFields[fieldMap[displayKey]] = companyInfo[fieldMap[displayKey]];
    });

    return companyCustomFields;
}

export const goToEditPartnerCompany = ({ navigate, partnerId, companyId }) => {
    if (partnerId && companyId) {
        navigate(`/admin/companies/${partnerId}/${companyId}/edit`);
    } else {
        navigate('/dashboard');
    }
};

export const updateUploadFileFormSchema = ({
    schema,
    type,
    response,
    importedSuccessfully,
    showPartialSuccessImport,
}) => {
    const reportList = get(response, 'reportList', []);
    const failedRecords = get(response, 'failedRecords', []);
    const totalRecords = reportList[1]?.split(':')[1];
    const successfullyImportedRecords = totalRecords - failedRecords.length;

    // Updated schema on Successfully import case
    addSchemaValue(schema, 'showSuccessFullImport', importedSuccessfully);
    addSchemaValue(
        schema,
        'SuccessFullImportSchema.successfullyImportedRecords',
        successfullyImportedRecords
    );
    addSchemaValue(schema, 'SuccessFullImportSchema.totalRecords', totalRecords);

    // Updated schema on Partial success case
    addSchemaValue(schema, 'showPartialSuccessImport', showPartialSuccessImport);
    addSchemaValue(schema, 'partialSuccessSchema.response', response);
    addSchemaValue(schema, 'partialSuccessSchema.fileName', `${type}-Import-Result.csv`);
    addSchemaValue(schema, 'partialSuccessSchema.subSection_2.instructions', [
        `Click the Download Import Issues button to download the <span style="color: #111111">${type}-Import-Result</span> file.`,
        `Correct the errors in the <span style="color: #111111">${type}-Import-Result</span> file.`,
        `Upload the corrected <span style="color: #111111">${type}-Import-Result</span> file.`,
    ]);
    addSchemaValue(schema, 'partialSuccessSchema.subSection_3.instructions', [
        'Only the failed rows will be in the download.',
        'Successfully imported rows should not be re imported. ',
    ]);

    return schema;
};
export const capitalizeFirstLetter = (str = '') => {
    if (typeof str !== 'string') {
        throw new Error('Expected a string');
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getImportUploadSchema = ({
    importUploadSchema,
    type,
    updatedTitle,
    companyId,
    partnerId,
}) => {
    const updatedSchema = _cloneDeep(importUploadSchema);

    addSchemaValue(
        updatedSchema,
        'informationSchema.title',
        templateReplace(updatedTitle, {
            companyId,
            partnerId,
            type,
        })
    );

    return updatedSchema;
};
