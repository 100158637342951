import axios from 'axios';

/**
 * API call to fetch info about the given handbooks list.
 *
 * @method fetchHandbookByIdAPI
 * @param  {string}             params The filter crtiteria to fetch
 * @return {Promise}
 */
export function fetchHandbooksListAPI(params) {
    return axios({
        method: 'get',
        url: `throne/handbook/v1/handbook`,
        type: 'zend',
        params,
    });
}

/**
 * API call to fetch info about the given handbook, via `handbookId`.
 *
 * @method fetchHandbookByIdAPI
 * @param  {string}             handbookId The handbook to fetch
 * @return {Promise}
 */
export function fetchHandbookByIdAPI(handbookId) {
    return axios({
        method: 'get',
        url: `throne/handbook/v1/handbook/${handbookId}`,
        type: 'zend',
    });
}

/**
 * API call to Download the given handbook.
 *
 * @method downloadHandbookByIdAPI
 * @param  {string}             handbookId The handbook to download
 * @param  {string}             fileType The fileType to download
 * @param  {string}             locale The locale to download
 * @param  {Object}             filters The filters to be applied

 * @return {Promise}
 */
export function downloadHandbookByIdAPI(handbookId, fileType, locale = 'en-us', filters) {
    return axios({
        method: 'get',
        url: `throne/handbook/v1/build-handbook/${fileType}/${locale}/${handbookId}?isAsync=true`,
        type: 'zend',
        params: filters,
    });
}

/**
 * API call to Download the given handbook.
 *
 * @method fetchHandbookReceiptAPI
 * @param  {string}             handbookId
 * @return {Promise}
 */
export function fetchHandbookReceiptAPI(handbookId) {
    return axios({
        method: 'get',
        url: `throne/handbook/v1/build-handbook-receipt/${handbookId}`,
        type: 'zend',
    });
}

/**
 * API call to Download the given handbook by state.
 *
 * @method downloadHandbookIndividualState
 * @param  {string}             handbookId The handbook to download
 * @param  {string}             fileType The fileType to download
 * @param  {string}             filters The state to download
 * @return {Promise}
 */
export function downloadHandbookIndividualStateAPI(handbookId, fileType, filters) {
    return axios({
        method: 'get',
        url: `throne/handbook/v1/build-handbook/${fileType}/en-us/${handbookId}?isAsync=true`,
        params: filters,
        type: 'zend',
    });
}

/**
 * API call to delete info about the given handbook, via `handbookId`.
 *
 * @method deleteHandbookByIdAPI
 * @param  {string}             handbookId The handbook to delete
 * @return {Promise}
 */
export function deleteHandbookByIdAPI(handbookId) {
    return axios({
        method: 'delete',
        url: `throne/handbook/v1/handbook/${handbookId}`,
        type: 'zend',
    });
}

/**
 * API call to copy info about the given handbook, via `handbookId`.
 *
 * @method copyHandbookByIdAPI
 * @param  {string}             handbookId The handbook to copy
 * @param  {string}             handbookName The handbook to copy
 * @param  {string}             suggestedName The handbook to copy
 * @return {Promise}
 */
export function copyHandbookByIdAPI(handbookId, handbookName, suggestedName) {
    return axios({
        method: 'post',
        url: `throne/handbook/v1/deep-copy-handbook`,
        type: 'zend',
        data: {
            handbookId,
            handbookName,
            suggestedName,
        },
    });
}

/**
 * API call to fetch TOC about the given handbook, via `handbookId`.
 *
 * @method fetchHandbookTOCAPI
 * @param  {string}             handbookId The handbook to fetch TOC
 * @param  {string}             policyId The policyId to filter TOC
 * @param  {string}             sectionId The sectionId to filter TOC
 * @param  {string}             search The search to filter TOC
 * @return {Promise}
 */
export function fetchHandbookTOCAPI(handbookId, policyId, sectionId, search) {
    return axios({
        method: 'get',
        url: `throne/handbook/v1/get-handbook-contents/${handbookId}`,
        type: 'zend',
        params: search,
    });
}

/**
 * API call to fetch handbook disclaimer, via `handbookId`.
 *
 * @method fetchHandbookDisclaimerAPI
 * @param  {string}             handbookId The handbook to fetch
 * @return {Promise}
 */
export function fetchHandbookDisclaimerAPI() {
    return axios({
        method: 'get',
        url: `api/v1/throne/documents/2.json`,
        type: 'zend',
    });
}

/**
 * API call to link custom policy for the given handbook
 *
 * @method linkCustomPolicies
 * @param   {number}    handbookId
 * @param   {Array}     customPolicyIds
 * @return  {Promise}
 */
export function linkCustomPolicies({ handbookId, customPolicyIds }) {
    return axios({
        method: 'post',
        url: `throne/handbook/v1/link-custom-policy-to-handbook/${customPolicyIds.join(',')}`,
        type: 'zend',
        data: {
            handbookId,
            id: customPolicyIds.join(','),
        },
    });
}

/**
 * API call to unlink custom policy for the given handbook, via `handbookId`.
 *
 * @method unlinkCustomPolicyAPI
 * @param  {string}             handbookId The handbook ID to unlink policy
 * @param  {string}             id The custom policy to unlink
 * @return {Promise}
 */
export function unlinkCustomPolicyAPI(handbookId, id) {
    return axios({
        method: 'post',
        url: `throne/handbook/v1/unlink-custom-policy-from-handbook/${id}`,
        type: 'zend',
        data: {
            handbookId,
            id,
        },
    });
}

/**
 * API call to enable/disable policy for a given handbook, via `handbookId`.
 *
 * @method policyChangeStatusAPI
 * @param  {string} id The policy to enable or disable
 * @param  {string} policyType   The type of policy
 * @param  {string} isEnabled The flag that indicate to enable or disable
 * @return {Promise}
 */
export function policyChangeStatusAPI(id, policyType, isEnabled) {
    return axios({
        method: 'patch',
        url: `throne/handbook/v1/handbook-client-${policyType}-policy/${id}`,
        type: 'zend',
        data: {
            isEnabled,
            id,
        },
    });
}

/**
 * API call to update TOC reorder for a given handbook, via `handbookId`.
 *
 * @method updateHandbookTOCAPI
 * @param  {string}             id The policy to enable or disable
 * @param  {Object}             policyGroups The policyGroups to update in TOC
 * @param  {Object}             customPolicies The customPolicies to update in TOC
 * @return {Promise}
 */
export function updateHandbookTOCAPI(id, policyGroups, customPolicies) {
    return axios({
        method: 'put',
        url: `throne/handbook/v1/handbook/${id}/toc-customizations`,
        type: 'zend',
        data: {
            id,
            policyGroups,
            customPolicies,
        },
    });
}

/**
 * API call to fetch a handbook's Summary of Changes
 *
 * @method fetchSummaryOfChangesAPI
 * @param  {string} changeId The changeId of the handbook, from the update handbook response
 * @return {Promise}
 */
export function fetchSummaryOfChangesAPI(changeId) {
    return axios({
        method: 'get',
        url: `/throne/handbook/v1/get-handbook-changes/${changeId}`,
        type: 'zend',
    });
}

/**
 * API call to fetch a handbook's Policy Summary of Changes
 *
 * @method fetchPolicySummaryOfChangesAPI
 * @param  {string} changeId The changeId of the handbook, from the update handbook response
 * @return {Promise}
 */
export function fetchPolicySummaryOfChangesAPI(changeId) {
    return axios({
        method: 'get',
        url: `/throne/handbook/v1/handbook/accept-policies/${changeId}`,
        type: 'zend',
    });
}

/**
 * API call to accept a handbook's Summary of Changes
 *
 * @method acceptSummaryOfChangesAPI
 * @param  {string} changeId The changeId of the handbook, from the update handbook response
 * @return {Promise}
 */
export function acceptSummaryOfChangesAPI(changeId) {
    return axios({
        method: 'post',
        url: `/throne/handbook/v1/accept-handbook-changes/${changeId}`,
        type: 'zend',
    });
}

/**
 * API call to accept a handbook's Summary of Changes
 *
 * @method acceptPolicySummaryOfChangesAPI
 * @param  {string} changeId The changeId of the handbook, from the update handbook response
 * @return {Promise}
 */
export function acceptPolicySummaryOfChangesAPI(changeId) {
    return axios({
        method: 'post',
        url: `/throne/handbook/v1/handbook/accept-policies`,
        data: {
            handbookId: changeId,
        },
        type: 'zend',
    });
}

/**
 * API call to cancel a handbook's Summary of Changes
 *
 * @method cancelSummaryOfChangesAPI
 * @param  {string} changeId The changeId of the handbook, from the update handbook response
 * @return {Promise}
 */
export function cancelSummaryOfChangesAPI(changeId) {
    return axios({
        method: 'delete',
        url: `/throne/handbook/v1/cancel-handbook-changes/${changeId}`,
        type: 'zend',
    });
}

/**
 * API call to accept a Policy Alert
 *
 * @method acceptPolicyAlertAPI
 * @param  {string} policyId The id of the policy to accept
 * @param  {string} policyType The type of policy to accept
 * @return {Promise}
 */
export function acceptPolicyAlertAPI({ policyId, policyType }) {
    return axios({
        method: 'post',
        url: `/throne/handbook/v1/accept-policy-alert/${policyType}/${policyId}`,
        type: 'zend',
    });
}

/**
 * API call to dismiss a Policy Alert
 *
 * @method dismissPolicyAlertAPI
 * @param  {string} policyId The id of the policy to dismiss
 * @param  {string} policyType The type of policy to dismiss
 * @return {Promise}
 */
export function dismissPolicyAlertAPI({ policyId, policyType }) {
    return axios({
        method: 'post',
        url: `/throne/handbook/v1/dismiss-policy-alert/${policyType}/${policyId}`,
        type: 'zend',
    });
}

/**
 * API call to fetch info custom policies list.
 *
 * @method fetchCustomPoliciesAPI
 * @param  {string}             params The filter crtiteria to fetch
 * @return {Promise}
 */
export function fetchCustomPoliciesAPI(params) {
    return axios({
        method: 'get',
        url: `throne/handbook/v1/handbook-custom-policy`,
        type: 'zend',
        params,
    });
}

/**
 * TODO: Update API endpoint when available on pscluster
 * API call to create new custom policy
 *
 * @method  createCustomPolicyAPI
 * @param   {Object}    data
 * @return  {Promise}
 */
export function createCustomPolicyAPI(data) {
    return axios({
        data,
        method: 'post',
        url: 'throne/handbook/v1/handbook-custom-policy',
        type: 'zend',
    });
}

/**
 * API call to create new handbook
 *
 * @method  createHandbook
 * @param   {Object}    data
 * @return  {Promise}
 */
export function createHandbookAPI(data) {
    return axios({
        data,
        method: 'post',
        url: '/throne/handbook/v1/handbook',
        type: 'zend',
    });
}

/**
 * API call to fetch policies details.
 *
 * @method fetchPolicyDetailsAPI
 * @param  {string}             params
 * @return {Promise}
 */
export function fetchPolicyDetailsAPI(params) {
    const { changeId, policyId, policyType } = params;
    return axios({
        method: 'get',
        url: `throne/handbook/v1/handbook-client-${policyType}-policy/${policyId}`,
        params: {
            changeId,
        },
        type: 'zend',
    });
}

/**
 * TODO: Update API endpoint when available on pscluster
 * API call to update custom policy
 *
 * @method  updateCustomPolicyAPI
 * @param   {Object}           data
 * @param   {number|string}    policyId
 * @return  {Promise}
 */
export function updateCustomPolicyAPI(data, policyId) {
    return axios({
        data,
        method: 'patch',
        url: `throne/handbook/v1/handbook-custom-policy/${policyId}`,
        type: 'zend',
    });
}

/**
 * TODO: Update API endpoint when available on pscluster
 * API call to fetch custom policies details.
 *
 * @method fetchCustomPolicyDetailsAPI
 * @param  {string}             params
 * @return {Promise}
 */
export function fetchCustomPolicyDetailsAPI(params) {
    const { policyId } = params;

    return axios({
        method: 'get',
        url: `/throne/handbook/v1/handbook-custom-policy/${policyId}`,
        type: 'zend',
    });
}

/**
 * API call to fetch handbook
 *
 * @method  fetchHandbookAPI
 * @param   {Object}    handbookId
 * @return  {Promise}
 */
export function fetchHandbookAPI(handbookId) {
    return axios({
        method: 'get',
        url: `/throne/handbook/v1/handbook/${handbookId}`,
        type: 'zend',
    });
}

export function fetchActivityStreamEventIdAPI(eventId) {
    return axios({
        method: 'get',
        url: `/throne/handbook/v1/activity-stream/detail/${eventId}`,
        type: 'zend',
    });
}

/**
 * API call to fetch handbook
 *
 * @method  updateHandbookAPI
 * @param   {number|string}    handbookId
 * @param   {Object}    data
 * @return  {Promise}
 */
export function updateHandbookAPI(handbookId, data) {
    return axios({
        data,
        method: 'patch',
        url: `/throne/handbook/v1/handbook/${handbookId}`,
        type: 'zend',
    });
}
/**
 * API call to save policy changes.
 *
 * @method savePolicyChanges
 * @param  {string}             params
 * @return {Promise}
 */
export function savePolicyChanges(params) {
    const { id, type, defaultRestored, language, isEnabled, resetMasterAttachments } = params;
    return axios({
        method: 'patch',
        url: `throne/handbook/v1/handbook-client-${type}-policy/${id}`,
        type: 'zend',
        data: {
            isEnabled,
            id,
            language,
            action: `handbook-client-${type}-policy`,
            defaultRestored,
            resetMasterAttachments,
            isHtmlContent: true,
        },
    });
}

export const fetchActivityStreamAPI = data => {
    return axios({
        method: 'post',
        url: `/throne/handbook/v1/activity-stream`,
        data,
        type: 'zend',
    });
};

/**
 * API call to upload file.
 *
 * @method uploadFileAPI
 * @param  {Object}         file
 * @return {Promise}
 */
export const uploadFileAPI = file => {
    return axios({
        method: 'post',
        url: '/throne/handbook/v1/upload-logo',
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        type: 'zend',
    });
};

/**
 * API call to download activity stream.
 *
 * @method downloadActivityStreamAPI
 * @param  {Object}   filters
 * @param  {number}   handbookId
 * @param  {string}   type
 * @return {Promise}
 */
export const downloadActivityStreamAPI = (filters, handbookId, type) => {
    const acceptHeader =
        type !== 'pdf'
            ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/json'
            : 'application/pdf, application/json';

    return axios({
        method: 'get',
        url: `throne/handbook/v1/handbook/${handbookId}/activities`,
        headers: {
            accept: acceptHeader,
        },
        params: {
            filters,
            id: handbookId,
        },
        responseType: 'blob',
        type: 'zend',
    });
};

/**
 * API call to delete TOC reorder for a given handbook, via `handbookId`.
 *
 * @method deleteHandbookTocAPI
 * @param  {string}             id The handbookId
 * @return {Promise}
 */
export function deleteHandbookTocAPI(id) {
    return axios({
        method: 'delete',
        url: `throne/handbook/v1/handbook/${id}/toc-customizations`,
        type: 'zend',
    });
}

/**
 * API call to fetch info about the given MHR handbooks list.
 *
 * @method fetchHandbooksMhrListAPI
 * @param  {string}             params The filter crtiteria to fetch
 * @return {Promise}
 */
export function fetchHandbooksMhrListAPI(params) {
    return axios({
        method: 'get',
        url: `throne/handbook/v1/mhr-handbook`,
        type: 'zend',
        params,
    });
}

/**
 * API call to fetch deleted handbooks list.
 *
 * @method fetchDeletedHandbooksListAPI
 * @param  {string}             params The filter crtiteria to fetch
 * @return {Promise}
 */
export function fetchDeletedHandbooksListAPI(params) {
    return axios({
        method: 'get',
        url: `throne/handbook/v1/restore-deleted-handbook`,
        type: 'zend',
        params,
    });
}

/**
 * API call to fetch handbooks move handbook list.
 *
 * @method fetchHandbooksMoveHandbookListAPI
 * @param  {string}             params The filter crtiteria to fetch
 * @return {Promise}
 */
export function fetchHandbooksMoveHandbookListAPI(params) {
    return axios({
        method: 'get',
        url: `throne/handbook/v1/move-tool/handbook-list`,
        type: 'zend',
        params,
    });
}

/**
 * API call to fetch handbooks move company list.
 *
 * @method fetchHandbooksMoveCompanyListAPI
 * @param  {Object}             params The filter crtiteria to fetch
 * @return {Promise}
 */
export function fetchHandbooksMoveCompanyListAPI(params) {
    return axios({
        method: 'get',
        url: `throne/handbook/v1/companies`,
        type: 'zend',
        params,
    });
}

/**
 * API call to fetch handbooks move detail.
 *
 * @method fetchHandbooksMoveDetailAPI
 * @param  {Object}             params The filter crtiteria to fetch
 * @return {Promise}
 */
export function fetchHandbooksMoveDetailAPI(params) {
    return axios({
        method: 'get',
        url: `throne/handbook/v1/move-tool/detail`,
        type: 'zend',
        params,
    });
}

/**
 * API call to move a handbook.
 *
 * @method moveHandbookAPI
 * @param  {Object}             data The filter crtiteria to fetch
 * @return {Promise}
 */
export function moveHandbookAPI(data) {
    return axios({
        method: 'patch',
        url: `throne/handbook/v1/move-tool`,
        type: 'zend',
        data,
    });
}

/**
 * API call to restore a deleted handbook.
 *
 * @method restoreDeletedHandbookAPI
 * @param  {Object}             payload The data to send
 * @return {Promise}
 */
export function restoreDeletedHandbookAPI(payload) {
    return axios({
        method: 'patch',
        url: `throne/handbook/v1/restore-deleted-handbook/${payload.handbookId}`,
        data: payload.data,
        type: 'zend',
    });
}

/**
 * API call to delete info about the given handbook, via `handbookId`.
 *
 * @method deleteHandbookMhrByIdAPI
 * @param  {string}             handbookId The handbook to delete
 * @return {Promise}
 */
export function deleteHandbookMhrByIdAPI(handbookId) {
    return axios({
        method: 'delete',
        url: `throne/handbook/v1/mhr-handbook/${handbookId}`,
        type: 'zend',
    });
}

/**
 * API call to fetch info about the given handbooks list.
 *
 * @method fetchArchivedHandbooksAPI
 * @param  {string}             companyId The filter crtiteria to fetch
 * @return {Promise}
 */
export function fetchArchivedHandbooksAPI(companyId) {
    return axios({
        method: 'get',
        url: `/throne/handbook/v1/mhr-handbook/${companyId}`,
        type: 'zend',
    });
}
/**
 * API call to download archived handbook
 *
 * @method downloadArchivedHandbookAPI
 * @param  {string}             key The filter crtiteria to fetch
 * @return {Promise}
 */
export function downloadArchivedHandbookAPI(key) {
    return axios({
        method: 'get',
        url: `/throne/handbook/v1/download-mhr-handbook?key=${key}`,
        type: 'zend',
    });
}

/**
 * API call to check if handbook has been translated
 *
 * @method isHandbookTranslatedAPI
 * @param  {string}             handbookId The handbookId to fetch
 * @return {Promise}
 */
export function isHandbookTranslatedAPI(handbookId) {
    return axios({
        method: 'get',
        url: `/throne/handbook/v1/is-translated/${handbookId}`,
        type: 'zend',
    });
}

/**
 * API call to upload handbook policy image from editor.
 *
 * @method uploadHandbookPolicyImageAPI
 * @param  {string}             formData The image to upload
 * @return {Promise}
 */
export function uploadHandbookPolicyImageAPI(formData) {
    return axios({
        method: 'post',
        url: '/throne/handbook/v1/image-upload',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        type: 'zend',
    });
}

/**
 * API call to update handbook policy images.
 *
 * @method getHandbookPolicyImagesUpdatedAPI
 * @param  {string}             imageList The image to upload
 * @return {Promise}
 */
export function getHandbookPolicyImagesUpdatedAPI(imageList) {
    return axios({
        method: 'post',
        url: '/throne/handbook/v1/s3/files/refresh-presigned-token',
        data: imageList,
        type: 'zend',
    });
}

/**
 * API call to get Froala Editor options.
 *
 * @method getFroalaOptionsAPI
 * @return {Promise}
 */
export function getFroalaOptionsAPI() {
    return axios({
        type: 'staticJson',
        ignoreAuthorization: true,
        method: 'get',
        noBearer: true,
        url: `/json/handbookEditorProps.json?cb=${new Date().getTime()}`,
    });
}

/**
 * API call to get Smart Handbook options.
 *
 * @method getSmartHandbookOptionsAPI
 * @return {Promise}
 */
export function getSmartHandbookOptionsAPI() {
    return axios({
        type: 'staticJson',
        ignoreAuthorization: true,
        method: 'get',
        noBearer: true,
        url: `/json/smartHandbookOptions.json?cb=${new Date().getTime()}`,
    });
}

/**
 * API call to get preview locked policy content.
 *
 * @method getHandbookPolicyPreviewAPI
 * @param  {string}             data The data to send
 * @return {Promise}
 */
export function getHandbookPolicyPreviewAPI(data) {
    return axios({
        method: 'post',
        url: '/throne/handbook/v1/policy/generate-preview',
        data,
        type: 'zend',
    });
}

/**
 * API call to fetch total documents handbook list.
 *
 * @method fetchTotalDocumentsListAPI
 * @param  {string}             params The filter crtiteria to fetch
 * @return {Promise}
 */
export function fetchTotalDocumentsListAPI(params) {
    return axios({
        method: 'get',
        url: `/throne/handbook/v1/report/signature/document-list`,
        type: 'zend',
        params,
    });
}

/**
 * API call to fetch total documents handbook list.
 *
 * @method fetchDocumentsReportAPI
 * @param  {string}             data The data to send
 * @return {Promise}
 */
export function fetchDocumentsReportAPI(data) {
    return axios({
        method: 'post',
        url: `/throne/handbook/v1/report/signature`,
        type: 'zend',
        data,
    });
}

/**
 * API call to check document download status.
 *
 * @method checkDocumentDownloadStatusAPI
 * @param  {string}             requestId The request id to check

 * @return {Promise}
 */
export function checkDocumentDownloadStatusAPI(requestId) {
    return axios({
        method: 'get',
        url: `/throne/handbook/v1/build-handbook/request/status/${requestId}`,
        type: 'zend',
    });
}

/**
 * API call to download handbook generated.
 *
 * @method downloadGeneratedHandbookAPI
 * @param  {string}             requestId The request id to check

 * @return {Promise}
 */
export function downloadGeneratedHandbookAPI(requestId) {
    return axios({
        method: 'get',
        url: `/throne/handbook/v1/build-handbook/request/download/${requestId}`,
        type: 'zend',
    });
}
