/**
 * Bytes per Megabyte.
 *
 * @type {number}
 */
export const BYTES_PER_MEGABYTE = 1048576;
/**
 * Max file size to upload in MB.
 *
 * @type {number}
 */
export const MAX_FILE_SIZE_TO_UPLOAD = 10;

export const FILE_UPLOAD_PLACEHOLDER = 'Select a file...';

export const BACK_TO_TOP_BUTTON_LABEL = 'BACK TO TOP';

export const RESET_ALL = 'Reset All';

export const GO_TO_PAGE = 'GO TO PAGE';

export const SHOW_MORE = 'SHOW MORE';

export const SHOW_LESS = 'SHOW LESS';

export const SHOW_ALL = 'SHOW ALL';

export const COMPARE = 'COMPARE';

export const NONE = 'None';

export const AUDIT_TRAIL_KEYS_TO_CONVERT = ['canCreateBdcDocuments', 'isActive'];

export const UNSAVED_CHANGES = 'Unsaved Changes';

export const KEEP_EDITING = 'Keep Editing';

/**
 * Permission error code we get from API.
 *
 * @type {number}
 */
export const PERMISSION_ERROR_CODE = 1051;

export const IMPORT_INFO_ID = 'Requirements_section';

export const NOT_SET = 'Not Set';

export const COVERPAGE_ACTIVATION_TIMESTAMP = 1741891980;
