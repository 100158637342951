import { get } from 'ravenjs/utils/lodash';
import { call, put, select } from 'redux-saga/effects';

import {
    bulkDeactivateCompaniesAPI,
    changeCompanyStatusAPI,
    createCompanyAPI,
    deletePrismHRConfigurationAPI,
    fetchAdminOptionsAPI,
    fetchAllConfigurationsForPartnerAPI,
    fetchAllLocationsForCompanyAPI,
    fetchClientsAPI,
    fetchCompanyAPI,
    fetchCompanyByIdAPI,
    fetchCompanyConfigurationAPI,
    fetchCompanyHeadersAPI,
    fetchCompanyStatusesAPI,
    fetchCompanyTypesAPI,
    fetchConfigurations,
    fetchContactByTagIdAPI,
    fetchContentDetailsAPI,
    fetchCsvPartnerTemplateAPI,
    fetchCustomFieldsAPI,
    fetchEmployeeCountByCompanyIdAPI,
    fetchEmployeeCountsByStateAPI,
    fetchImportDetailsAPI,
    fetchImportHistoryListAPI,
    fetchIndustryTypesAPI,
    fetchMasterConfigSkusForPartner,
    fetchNaicsCodesAPI,
    fetchPartnerClientsAPI,
    fetchPartnersAPI,
    fetchPartnerSettingsAPI,
    fetchPreviewContentDetailsAPI,
    fetchPrismHRConfigurationAPI,
    fetchPrismHRIntegrationDataAPI,
    fetchPublicContentDetailsAPI,
    fetchRelatedMaterialDetailsAPI,
    fetchSignInOptions,
    fetchSsoApiOptionsAPI,
    fetchSsoConfigurationAPI,
    fetchSsoMappedFieldAPI,
    fetchWebinarsDetailsAPI,
    fetchWelcomeEmailSettingsAPI,
    fetchZipCodeValidateAPI,
    generateAuthenticationCodeAPI,
    importClientsAPI,
    moveCompanyAPI,
    resetPrismHRSyncStatusAPI,
    recreatePrismHRSubscriptionAPI,
    updateAdminOptionsAPI,
    updateCompanyAPI,
    updatePartnerLicensingAPI,
    updatePartnerSsoConfigurationAPI,
    updatePrismHRConfigurationAPI,
    updateSsoApiOptionsAPI,
    updateWelcomeEmailSettingsAPI,
    validateBulkUpdateCompanyConfigurationsAPI,
} from 'modules/apis';
import { actions as uiActions } from 'modules/ui';
import { getUser } from 'modules/user/reducer';

import * as adminActions from '../actions';

/**
 * Fetch all configurations from a partner
 *
 * @method fetchCompanyConfigurationsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} configurations
 */
export function* fetchCompanyConfigurationsSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(fetchConfigurations, payload);
        const configurations = get(resp, 'data.configurations', {});
        yield put(adminActions.fetchCompanyConfigurations.success(configurations));
        return configurations;
    } catch (error) {
        yield put(adminActions.fetchCompanyConfigurations.error(error));
        return error;
    }
}

/**
 * Fetch configuration from a company
 *
 * @method fetchCompanyConfigurationSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} configuration
 */
export function* fetchCompanyConfigurationSaga(action) {
    try {
        const { payload: configurationId } = action;
        const resp = yield call(fetchCompanyConfigurationAPI, configurationId);
        const configuration = get(resp, 'data.configuration', []);
        yield put(adminActions.fetchCompanyConfiguration.success(configuration));
        return configuration;
    } catch (error) {
        yield put(adminActions.fetchCompanyConfiguration.error(error));
        return error;
    }
}
/**
 * Fetch a partner
 *
 * @method fetchPartnerSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 */
export function* fetchPartnerSaga(action) {
    try {
        const { payload: partnerId } = action;
        // API calls for getting a partner `fetchCompanyByIdAPI` should be renamed as to not
        // confuse that you'll be using it to get a company.
        const resp = yield call(fetchCompanyByIdAPI, partnerId);
        const partner = get(resp, 'data.company', {});
        yield put(adminActions.fetchPartner.success(partner));
    } catch (error) {
        yield put(adminActions.fetchPartner.error(error));
    }
}
/**
 * Fetch a company.
 *
 * @method fetchCompanySaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} company
 */
export function* fetchCompanySaga(action) {
    try {
        const { payload: companyId } = action;
        const resp = yield call(fetchCompanyAPI, companyId);
        const company = get(resp, 'data.company', {});
        yield put(adminActions.fetchCompany.success(company));
        return company;
    } catch (error) {
        yield put(adminActions.fetchCompany.error(error));
        return error;
    }
}
/**
 * Create company for any partner.
 *
 * @method createCompanySaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} newCompany
 */
export function* createCompanySaga(action) {
    try {
        const {
            payload: { partnerId, data },
        } = action;
        const resp = yield call(createCompanyAPI, partnerId, data);
        const company = get(resp, 'data', {});
        yield put(adminActions.createCompany.success(company));
        return company;
    } catch (error) {
        yield put(adminActions.createCompany.error(error));
        return error;
    }
}
/**
 * Update company based on an `companyId`
 *
 * @method updateCompanySaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} company
 */
export function* updateCompanySaga(action) {
    try {
        // Extract the userId from the action.
        const { payload: companyInfo } = action;
        // Make the call to update the user with the given `userId`.
        const resp = yield call(updateCompanyAPI, companyInfo);
        // Extract the updated user from the valid response.
        const company = get(resp, 'data.company', {});
        yield put(adminActions.updateCompany.success(company));
        return company;
    } catch (error) {
        yield put(adminActions.updateCompany.error(error));
        return error;
    }
}
/**
 * Disable/Enable company saga
 *
 * @method changeCompanyStatusSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} company
 */
export function* changeCompanyStatusSaga(action) {
    try {
        const { payload: companyInfo } = action;
        const resp = yield call(changeCompanyStatusAPI, companyInfo);
        const company = get(resp, 'data', {});
        yield put(adminActions.changeCompanyStatus.success(company));
        return company;
    } catch (error) {
        yield put(adminActions.changeCompanyStatus.error(error));
        return error;
    }
}
/**
 * Fetch company headers.
 *
 * @method fetchCompanyHeadersSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} company
 */
export function* fetchCompanyHeadersSaga(action) {
    try {
        const { payload: companyId } = action;
        const resp = yield call(fetchCompanyHeadersAPI, companyId);
        const company = get(resp, 'data.company', {});
        yield put(adminActions.fetchCompanyHeaders.success(company));
        return company;
    } catch (error) {
        yield put(adminActions.fetchCompanyHeaders.error(error));
        return error;
    }
}
/**
 * Fetch naics codes.
 *
 * @method fetchNaicsCodesSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 */
export function* fetchNaicsCodesSaga() {
    try {
        // API calls for getting a `fetchNaicsCodesAPI`
        const resp = yield call(fetchNaicsCodesAPI);
        const naicscodes = get(resp, 'data.lookup-types', []);
        yield put(adminActions.fetchNaicsCodes.success(naicscodes));
    } catch (error) {
        yield put(adminActions.fetchNaicsCodes.error(error));
    }
}
/**
 * Validate zip code.
 *
 * @method fetchZipCodeValidateSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 */
export function* fetchZipCodeValidateSaga(action) {
    try {
        // API calls for getting a `fetchZipCodeValidateAPI`
        const { payload: zip } = action;
        const resp = yield call(fetchZipCodeValidateAPI, zip);
        const validateZip = get(resp, 'data.zipAddresses', []);
        yield put(adminActions.fetchZipCodeValidate.success(validateZip));
    } catch (error) {
        yield put(adminActions.fetchZipCodeValidate.error(error));
    }
}
/**
 * Fetch industry types.
 *
 * @method fetchIndustryTypesSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 */
export function* fetchIndustryTypesSaga() {
    try {
        // API calls for getting a `fetchIndustryTypesAPI`
        const resp = yield call(fetchIndustryTypesAPI);
        const validateZip = get(resp, 'data.lookup-types', []);
        yield put(adminActions.fetchIndustryTypes.success(validateZip));
    } catch (error) {
        yield put(adminActions.fetchIndustryTypes.error(error));
    }
}
/**
 * Fetch welcome email settings
 *
 * @method fetchWelcomeEmailSettingsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} configuration
 */
export function* fetchWelcomeEmailSettingsSaga(action) {
    try {
        // Extract the userId from the action.
        const { payload: partnerInfo } = action;
        // Make the call to fetch the email configuration by partnerId.
        const resp = yield call(fetchWelcomeEmailSettingsAPI, partnerInfo);
        // Extract the email configuration from the valid response.
        const company = get(resp, 'data.email-templates', {});
        yield put(adminActions.fetchWelcomeEmailSettings.success(company));
        return company;
    } catch (error) {
        yield put(adminActions.fetchWelcomeEmailSettings.error(error));
        return error;
    }
}
/**
 * Update welcome email settings
 *
 * @method updateCompanySaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} configuration
 */
export function* updateWelcomeEmailSettingsSaga(action) {
    try {
        // Extract the configurationInfo from the action.
        const { payload: configurationInfo } = action;
        // Make the call to update the email configuration with the given `partnerId`.
        const resp = yield call(updateWelcomeEmailSettingsAPI, configurationInfo);
        // Extract the updated email configuration from the valid response.
        const company = get(resp, 'data.email-templates', {});
        yield put(adminActions.updateWelcomeEmailSettings.success(company));
        return company;
    } catch (error) {
        yield put(adminActions.updateWelcomeEmailSettings.error(error));
        return error;
    }
}
/**
 * Fetch all locations from a company
 *
 * @method fetchAllLocationsForCompanySaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} locations
 */
export function* fetchAllLocationsForCompanySaga(action) {
    try {
        const userStore = yield select(getUser);
        let response;
        const { payload: companyInfo } = action;
        // Note: set useStore to true only when fetching locations for the current user.
        // Do not set useStore to true when editing locations (e.g. Admin pages).
        const { companyId, partnerId, useStore, ...params } = companyInfo;

        if (userStore.locations.length && useStore) {
            response = {
                data: {
                    locations: userStore.locations,
                },
            };
        } else {
            const type = partnerId === companyId ? 'partners' : 'companies';
            response = yield call(fetchAllLocationsForCompanyAPI, type, companyId, params);
        }

        const locations = get(response, 'data', {});
        locations.useStore = useStore;
        yield put(adminActions.fetchAllLocationsForCompany.success(locations));
        return locations;
    } catch (error) {
        yield put(adminActions.fetchAllLocationsForCompany.error(error));
        return error;
    }
}

/**
 * Fetch all locations from a company
 *
 * @method fetchEmployeeCountsByStateSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} locations
 */
export function* fetchEmployeeCountsByStateSaga(action) {
    try {
        const { payload: companyId } = action;
        const resp = yield call(fetchEmployeeCountsByStateAPI, companyId);
        const counts = get(resp, 'data', {});
        yield put(adminActions.fetchEmployeeCountsByState.success(counts));
        return counts;
    } catch (error) {
        yield put(adminActions.fetchEmployeeCountsByState.error(error));
        return error;
    }
}

/**
 * Fetch clients from a partner
 *
 * @method fetchPartnerClientsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* fetchPartnerClientsSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(fetchPartnerClientsAPI, partnerInfo);
        const clients = get(resp, 'data', {});
        yield put(adminActions.fetchPartnerClients.success(clients));
        return clients;
    } catch (error) {
        yield put(adminActions.fetchPartnerClients.error(error));
        return error;
    }
}
/**
 * Fetch all configurations brom partner
 *
 * @method fetchAllConfigurationsForPartnerSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* fetchAllConfigurationsForPartnerSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(fetchAllConfigurationsForPartnerAPI, partnerInfo);
        const clients = get(resp, 'data', {});
        yield put(adminActions.fetchAllConfigurationsForPartner.success(clients));
        return clients;
    } catch (error) {
        yield put(adminActions.fetchAllConfigurationsForPartner.error(error));
        return error;
    }
}
/**
 * Validate bulk update company configurations
 *
 * @method validateBulkUpdateCompanyConfigurationsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* validateBulkUpdateCompanyConfigurationsSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(validateBulkUpdateCompanyConfigurationsAPI, partnerInfo);
        const validate = get(resp, 'data', {});
        yield put(adminActions.validateBulkUpdateCompanyConfigurations.success(validate));
        return validate;
    } catch (error) {
        yield put(adminActions.validateBulkUpdateCompanyConfigurations.error(error));
        return error;
    }
}
/**
 * Fetch PrismHR configuration by partner.
 *
 * @method fetchPrismHRConfigurationSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} configuration
 */
export function* fetchPrismHRConfigurationSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(fetchPrismHRConfigurationAPI, partnerInfo);
        const configuration = get(resp, 'data.extPartnerConnectorConfig', {});
        yield put(adminActions.fetchPrismHRConfiguration.success(configuration));
        return configuration;
    } catch (error) {
        yield put(adminActions.fetchPrismHRConfiguration.error('no error'));
        return error;
    }
}
/**
 * Update PrismHR configuration by partner.
 *
 * @method updatePrismHRConfigurationSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* updatePrismHRConfigurationSaga(action) {
    try {
        const { payload: configurationInfo } = action;
        const resp = yield call(updatePrismHRConfigurationAPI, configurationInfo);
        const configuration = get(resp, 'data.extPartnerConnectorConfig', {});
        yield put(adminActions.updatePrismHRConfiguration.success(configuration));
        return configuration;
    } catch (error) {
        yield put(adminActions.updatePrismHRConfiguration.error(error));
        return error;
    }
}

/**
 * Fetch PrismHR Integration operation center data.
 *
 * @method fetchPrismHRIntegrationDataSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} configuration
 */
export function* fetchPrismHRIntegrationDataSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(fetchPrismHRIntegrationDataAPI, payload);
        const integrationData = get(resp, 'data', {});
        yield put(adminActions.fetchPrismHRIntegrationData.success(integrationData));
        return integrationData;
    } catch (error) {
        yield put(adminActions.fetchPrismHRIntegrationData.error('no error'));
        return error;
    }
}

/**
 * Reset PrismHR Integration sync status for peoId.
 *
 * @method resetPrismHRSyncStatusSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* resetPrismHRSyncStatusSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(resetPrismHRSyncStatusAPI, payload);
        const data = get(resp, 'data', '');
        yield put(adminActions.resetPrismHRSyncStatus.success(data));
        return data;
    } catch (error) {
        yield put(adminActions.resetPrismHRSyncStatus.error(error));
        return error;
    }
}

/**
 * Recreate PrismHR Subscription.
 *
 * @method recreatePrismHRSubscriptionSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* recreatePrismHRSubscriptionSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(recreatePrismHRSubscriptionAPI, payload);
        const data = get(resp, 'data', '');
        yield put(adminActions.recreatePrismHRSubscription.success(data));
        return data;
    } catch (error) {
        yield put(adminActions.recreatePrismHRSubscription.error(error));
        return error;
    }
}

/**
 * Import Clients saga
 *
 * @method fetchCsvPartnerTemplateSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* fetchCsvPartnerTemplateSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(fetchCsvPartnerTemplateAPI, partnerInfo);
        const template = get(resp, 'data', {});
        yield put(adminActions.fetchCsvPartnerTemplate.success(template));
        return template;
    } catch (error) {
        yield put(adminActions.fetchCsvPartnerTemplate.error(error));
        return error;
    }
}

/**
 * Import Clients saga
 *
 * @method importClientsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* importClientsSaga(action) {
    try {
        const { payload: importInfo } = action;
        const response = yield call(importClientsAPI, importInfo);
        yield put(adminActions.importClients.success(response));
        return response;
    } catch (error) {
        yield put(adminActions.importClients.error(error));
        return error;
    }
}

/**
 * Fetch clients
 *
 * @method fetchClientsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* fetchClientsSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(fetchClientsAPI, partnerInfo);
        const clients = get(resp, 'data', {});
        yield put(adminActions.fetchClients.success(clients));
        return clients;
    } catch (error) {
        yield put(adminActions.fetchClients.error(error));
        return error;
    }
}

/**
 * Fetch partner licensing
 *
 * @method fetchPartnerLicensingSaga
 *
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* fetchPartnerLicensingSaga(action) {
    try {
        const { payload: partnerId } = action;
        const resp = yield call(fetchPartnerSettingsAPI, partnerId);
        const licensing = get(resp, 'data.partnersettings', {});
        yield put(adminActions.fetchPartnerLicensing.success(licensing));
        return licensing;
    } catch (error) {
        yield put(adminActions.fetchPartnerLicensing.error(error));
        return error;
    }
}

/**
 * Fetch master config SKUs for partner
 *
 * @method fetchMasterConfigSkusForPartnerSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* fetchMasterConfigSkusForPartnerSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'Fetching Partner SKUs'));
        const {
            payload: { partnerId, skus },
        } = action;
        const resp = yield call(fetchMasterConfigSkusForPartner, partnerId, skus);
        const companySKUs = get(resp, 'data.companySKUs', {});
        yield put(adminActions.fetchMasterConfigSkusForPartner.success(companySKUs));
        return companySKUs;
    } catch (error) {
        yield put(adminActions.fetchMasterConfigSkusForPartner.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Update partner licensing
 *
 * @method updatePartnerLicensingSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* updatePartnerLicensingSaga(action) {
    try {
        const { payload: licensingInfo } = action;
        const resp = yield call(updatePartnerLicensingAPI, licensingInfo);
        const licensing = resp;
        yield put(adminActions.updatePartnerLicensing.success(licensing));
        return licensing;
    } catch (error) {
        yield put(adminActions.updatePartnerLicensing.error(error));
        return error;
    }
}

/**
 * Generate authentication code to Partner
 *
 * @method generateAuthenticationCodeSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* generateAuthenticationCodeSaga(action) {
    try {
        const { payload: partnerId } = action;
        const resp = yield call(generateAuthenticationCodeAPI, partnerId);
        const code = get(resp, 'data.authentication_code', {});
        yield put(adminActions.generateAuthenticationCode.success(code));
        return code;
    } catch (error) {
        yield put(adminActions.generateAuthenticationCode.error(error));
        return error;
    }
}

/**
 * Fetch sso mapped field
 *
 * @method fetchSsoMappedFieldSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} config
 */
export function* fetchSsoMappedFieldSaga(action) {
    try {
        const { payload: partnerId } = action;
        const resp = yield call(fetchSsoMappedFieldAPI, partnerId);
        const ssoMappedField = get(resp, 'data.lookup-types', []);
        yield put(adminActions.fetchSsoMappedField.success(ssoMappedField));
        return ssoMappedField;
    } catch (error) {
        yield put(adminActions.fetchSsoMappedField.error(error));
        return error;
    }
}

/**
 * Fetch company sso configuration
 *
 * @method fetchSsoConfigurationSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} config
 */
export function* fetchSsoConfigurationSaga(action) {
    try {
        const { payload: companyId } = action;
        const resp = yield call(fetchSsoConfigurationAPI, companyId);
        const companySsoConfiguration = get(resp, 'data.ssoConfiguration', {});
        yield put(adminActions.fetchSsoConfiguration.success(companySsoConfiguration));
        return companySsoConfiguration;
    } catch (error) {
        yield put(adminActions.fetchSsoConfiguration.error(error));
        return error;
    }
}

/**
 * Update partner sso configuration
 *
 * @method updatePartnerSsoConfigurationSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* updatePartnerSsoConfigurationSaga(action) {
    try {
        const { payload: ssoConfiguration } = action;
        const response = yield call(updatePartnerSsoConfigurationAPI, ssoConfiguration);
        const partnerSsoConfiguration = get(response, 'data.ssoConfiguration', {});
        yield put(adminActions.updatePartnerSsoConfiguration.success(partnerSsoConfiguration));
        return partnerSsoConfiguration;
    } catch (error) {
        yield put(adminActions.updatePartnerSsoConfiguration.error(error));
        return error;
    }
}

/**
 * Update partner sso api options configuration
 *
 * @method updateSsoApiOptionsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} clients
 */
export function* updateSsoApiOptionsSaga(action) {
    try {
        const { payload: ssoApiOptions } = action;
        const response = yield call(updateSsoApiOptionsAPI, ssoApiOptions);
        const ssoApiOptionsConfig = get(response, 'data.ssoConfiguration', {});
        yield put(adminActions.updateSsoApiOptions.success(ssoApiOptionsConfig));
        return ssoApiOptionsConfig;
    } catch (error) {
        yield put(adminActions.updateSsoApiOptions.error(error));
        return error;
    }
}

/**
 * Fetch partner sso api options configuration
 *
 * @method fetchSsoApiOptionsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} partners
 */
export function* fetchSsoApiOptionsSaga(action) {
    try {
        const { payload: partnerId } = action;
        const resp = yield call(fetchSsoApiOptionsAPI, partnerId);
        const ssoApiOptionsConfig = get(resp, 'data', {});
        yield put(adminActions.fetchSsoApiOptions.success(ssoApiOptionsConfig));
        return ssoApiOptionsConfig;
    } catch (error) {
        yield put(adminActions.fetchSsoApiOptions.error(error));
        return error;
    }
}

/**
 * Fetch sign in options configuration
 *
 * @method fetchSignInOptionsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} partners
 */
export function* fetchSignInOptionsSaga(action) {
    try {
        const { payload: partnerId } = action;
        const resp = yield call(fetchSignInOptions, partnerId);
        const signInOptions = get(resp, 'data', {});
        yield put(adminActions.fetchSignInOptions.success(signInOptions));
        return signInOptions;
    } catch (error) {
        yield put(adminActions.fetchSignInOptions.error(error));
        return error;
    }
}

/**
 * Fetch partners
 *
 * @method fetchPartnersSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} partners
 */
export function* fetchPartnersSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(fetchPartnersAPI, partnerInfo);
        const partners = get(resp, 'data', {});
        yield put(adminActions.fetchPartners.success(partners));
        return partners;
    } catch (error) {
        yield put(adminActions.fetchPartners.error(error));
        return error;
    }
}
/**
 * Fetch company types
 *
 * @method fetchCompanyTypesSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} companyTypes
 */
export function* fetchCompanyTypesSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(fetchCompanyTypesAPI, partnerInfo);
        const companyTypes = get(resp, 'data', {});
        yield put(adminActions.fetchCompanyTypes.success(companyTypes));
        return companyTypes;
    } catch (error) {
        yield put(adminActions.fetchCompanyTypes.error(error));
        return error;
    }
}
/**
 * Fetch company statuses
 *
 * @method fetchCompanyStatusesSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} companyTypes
 */
export function* fetchCompanyStatusesSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(fetchCompanyStatusesAPI, partnerInfo);
        const companyStatuses = resp;
        yield put(adminActions.fetchCompanyStatuses.success(companyStatuses));
        return companyStatuses;
    } catch (error) {
        yield put(adminActions.fetchCompanyStatuses.error(error));
        return error;
    }
}
/**
 * Deactivate companies
 *
 * @method bulkDeactivateCompaniesSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} companyTypes
 */
export function* bulkDeactivateCompaniesSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(bulkDeactivateCompaniesAPI, partnerInfo);
        const bulk = resp;
        yield put(adminActions.bulkDeactivateCompanies.success(bulk));
        return bulk;
    } catch (error) {
        yield put(adminActions.bulkDeactivateCompanies.error(error));
        return error;
    }
}
/**
 * Fetch custyom fields by partnerId
 *
 * @method fetchCustomFieldsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} companyTypes
 */
export function* fetchCustomFieldsSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(fetchCustomFieldsAPI, partnerInfo);
        const customFields = get(resp, 'data.customFields', []);
        yield put(adminActions.fetchCustomFields.success(customFields));
        return customFields;
    } catch (error) {
        yield put(adminActions.fetchCustomFields.error(error));
        return error;
    }
}

/**
 * Move Company.
 *
 * @method moveCompanySaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* moveCompanySaga(action) {
    try {
        const { payload: params } = action;
        const resp = yield call(moveCompanyAPI, params);
        yield put(adminActions.moveCompany.success(resp));
        return resp;
    } catch (error) {
        yield put(adminActions.moveCompany.error(error));
        return error;
    }
}

/**
 * Saga to fetch content detail
 *
 * @method fetchContentDetailsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchContentDetailsSaga(action) {
    try {
        const {
            payload: { id, isSoftLogin, softLoginToken, type, isWebinars, materialId },
        } = action;
        let response;
        if (isSoftLogin) {
            response = yield call(fetchPublicContentDetailsAPI, id, type, softLoginToken);
        } else if (isWebinars && materialId) {
            response = yield call(fetchRelatedMaterialDetailsAPI, id, materialId, true);
        } else if (isWebinars) {
            response = yield call(fetchWebinarsDetailsAPI, id);
        } else if (materialId) {
            response = yield call(fetchRelatedMaterialDetailsAPI, id, materialId);
        } else {
            response = yield call(fetchContentDetailsAPI, id, type);
        }

        const content = get(response, 'data', {});

        yield put(adminActions.fetchContentDetails.success(content));
        return content;
    } catch (error) {
        yield put(adminActions.fetchContentDetails.error(error));
        return error;
    }
}

/**
 * Fetch Contacts by tagID.
 *
 * @method fetchContactByTagIdSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* fetchContactByTagIdSaga(action) {
    try {
        const {
            payload: { params, tagId },
        } = action;
        const resp = yield call(fetchContactByTagIdAPI, params, tagId);
        yield put(adminActions.fetchContactByTagId.success(resp));
        return resp;
    } catch (error) {
        yield put(adminActions.fetchContactByTagId.error(error));
        return error;
    }
}

/**
 * Saga to fetch content detail (preview)
 *
 * @method fetchPreviewContentDetailsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchPreviewContentDetailsSaga(action) {
    try {
        const {
            payload: { id },
        } = action;
        const response = yield call(fetchPreviewContentDetailsAPI, id);
        const content = get(response, 'data', {});
        yield put(adminActions.fetchPreviewContentDetails.success(content));
        return content;
    } catch (error) {
        yield put(adminActions.fetchPreviewContentDetails.error(error));
        return error;
    }
}

/**
 * Saga to get employee count by state by companyId
 *
 * @method fetchEmployeeCountByCompanyIdSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchEmployeeCountByCompanyIdSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchEmployeeCountByCompanyIdAPI, payload);
        const content = get(response, 'data', {});
        yield put(adminActions.fetchEmployeeCountByCompanyId.success(content));
        return content;
    } catch (error) {
        yield put(adminActions.fetchEmployeeCountByCompanyId.error(error));
        return error;
    }
}

/**
 * DELETE PrismHR configuration by partner.
 *
 * @method deletePrismHRConfiguration
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* deletePrismHRConfigurationSaga(action) {
    try {
        const { payload: configurationInfo } = action;
        const resp = yield call(deletePrismHRConfigurationAPI, configurationInfo);
        const configuration = get(resp, 'data.extPartnerConnectorConfig', {});
        yield put(adminActions.deletePrismHRConfiguration.success(configuration));
        return configuration;
    } catch (error) {
        yield put(adminActions.deletePrismHRConfiguration.error(error));
        return error;
    }
}

/**
 * Fetch a company.
 *
 * @method fetchImportHistoryListSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Array} data
 */
export function* fetchImportHistoryListSaga(action) {
    try {
        const { payload: companyInfo } = action;
        const resp = yield call(fetchImportHistoryListAPI, companyInfo);
        const data = get(resp, 'data', []);
        yield put(adminActions.fetchImportHistoryList.success(data));
        return data;
    } catch (error) {
        yield put(adminActions.fetchImportHistoryList.error(error));
        return error;
    }
}

/**
 * Fetch an import details.
 *
 * @method fetchImportDetailsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Array} data
 */
export function* fetchImportDetailsSaga(action) {
    try {
        const { payload: importHistoryId } = action;
        const resp = yield call(fetchImportDetailsAPI, importHistoryId);
        const data = get(resp, 'data', []);
        yield put(adminActions.fetchImportDetails.success(data));
        return data;
    } catch (error) {
        yield put(adminActions.fetchImportDetails.error(error));
        return error;
    }
}

/**
 * Fetch an import details.
 *
 * @method fetchAdminOptionsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Array} data
 */
export function* fetchAdminOptionsSaga(action) {
    try {
        const { payload: partnerId } = action;
        const resp = yield call(fetchAdminOptionsAPI, partnerId);
        const data = get(resp, 'data', {});
        yield put(adminActions.fetchAdminOptions.success(data));
        return data;
    } catch (error) {
        yield put(adminActions.fetchAdminOptions.error(error));
        return error;
    }
}

/**
 * Fetch an import details.
 *
 * @method updateAdminOptionsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Array} data
 */
export function* updateAdminOptionsSaga(action) {
    try {
        const { payload: partnerInfo } = action;
        const resp = yield call(updateAdminOptionsAPI, partnerInfo);
        const data = get(resp, 'partner-administration-options', []);
        yield put(adminActions.updateAdminOptions.success(data));
        return data;
    } catch (error) {
        yield put(adminActions.updateAdminOptions.error(error));
        return error;
    }
}
